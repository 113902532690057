import React, { useState, useEffect } from 'react'

import API, { getSPUDAPIUrl } from '../helpers/api'
import { SPUDOnSaveResponseDetails } from '../../@types/SPUDRecord.type'
import { transactionParams } from './transactions.service'
import { ServiceTypeType } from '../components/General/SPUDServiceTypeClassification'
import _ from 'lodash'
import { PostProcessRowType } from '../pages/RecordsPage/ListView/ImportRecords/DuplicateRows'

export type AgeGroupType = {
  id: number | string,
  name: string,
  description: string,
  // eslint-disable-next-line camelcase
  lower_value: number,
  // eslint-disable-next-line camelcase
  upper_value: number,
}

export type DatasetsType = {
  id: number | string,
  name: string,
}

export type FailedUpdateType = {
    record_id: number,
    name: string,
    field: string,
    reason: {[x: string]: string} | string | Array<{[x: string]: string} | string>
}

export type DuplicateRowType = {
    rowNo: number,
    recordId: number | string,
    spud_id: number | string,
    name: unknown,
    site?: number
    address?: string
    field: string,
    record_type: string,
    duplicate_type: string,
    matchingRow: Array<string> | string
    matchingRowElm: Array<string> | string | React.ReactElement
    row_data: {[x: string]: unknown}
  }

export type DuplicateType = {
  [x: string]: Array<DuplicateRowType>
}

export type SuccessfulImportType = {
    record_id: number,
    name: string,
    message: string,
}

export type BulkServiceProviderUpdateType = {
  update_data: Array<{
    data: {
      [x: string]: unknown
    },
    author: {
      contact_name: string,
      contact_number: string,
      contact_email: string,
    },
    date_submitted: string
  }>
  bulk_update_record: {
    contact_name: string,
    contact_number: string,
    contact_email: string,
    date_submitted: string,
  }
  comment: string
}

const fetch = (
  initialParams: Record<string, unknown>,
): [
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { isLoading: boolean; isError: boolean; data: any },
  React.Dispatch<React.SetStateAction<Record<string, unknown>>>
] => {
  const [data, setData] = useState([])
  const [params, setParams] = useState(initialParams)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setIsError(false)
      setIsLoading(true)
      API.get(`${getSPUDAPIUrl()}${params.url}/`, { params: params.params }).then((response) => {
        setData(response.data)
        setIsLoading(false)
      })
        .catch(error => {
          setIsError(error.message)
          setIsLoading(false)
        })
    }

    fetchData()
  }, [params])

  return [{ data, isLoading, isError }, setParams]
}

const create = async (
  recordType: string|undefined,
  data: Record<string, unknown>,
): Promise<{
  data: SPUDOnSaveResponseDetails
}> => {
  return await API.post(`${getSPUDAPIUrl()}update/${recordType}/`, data)
}

const bulkUpdateSave = async (
  accessCode: string|null,
  recordId: number|null,
  data: Record<string, unknown>,
): Promise<{
  data: SPUDOnSaveResponseDetails
}> => {
  return await API.post(`${getSPUDAPIUrl()}bulk-update/update/${accessCode}/${recordId}/`, data)
}

const bulkUpdateChanges = async (
  recordType: string|undefined,
  recordId: string|number,
): Promise<{
  data: BulkServiceProviderUpdateType
}> => {
  return await API.get(`${getSPUDAPIUrl()}bulk-update/changes/${recordType}/${recordId}/`)
}

const copy = async (
  recordId: number | string | null | undefined,
  parentId: string,
  recordType: string,
): Promise<{
  data: {
    id: number,
    /* eslint-disable camelcase */
    iss_id: number | null,
    record_type: string,
    /* eslint-enable camelcase */
  }
}> => {
  return await API.post(
    `${getSPUDAPIUrl()}record/${recordType}/${recordId}/copy/`,
    { parent_id: parentId, record_type: recordType },
  )
}

const update = async (
  recordId: number|string,
  recordType: string|undefined,
  data: Record<string, unknown>,
): Promise<{
  data: SPUDOnSaveResponseDetails
}> => {
  return await API.post(`${getSPUDAPIUrl()}update/${recordType}/${recordId}/`, data)
}

const fetchServiceTypes = async (): Promise<{
  results: Array<ServiceTypeType>
}> => {
  const response = await API.get(`${getSPUDAPIUrl()}directory/service-types?limit=1000`)
  return response.data
}

const fetchLanguages = async (params: transactionParams): Promise<{
  data: {
    results: Array<{ name: string }>
    next: string | null
  }
}> => {
  let url = `${getSPUDAPIUrl()}directory/languages`
  if (params?.details?.name) {
    url = `${url}?startswith=${params?.details?.name}`
  } else {
    url = `${url}?limit=${params.limit}&offset=${params.offset}`
  }
  if (params.accessId) {
    url = `${url}&access_id=${params.accessId}`
  }
  return await API.get(url)
}

const fetchAgeGroups = async (params: transactionParams): Promise<{
  data: {
    results: Array<AgeGroupType>
    next: string | null
  }
}> => {
  let url = `${getSPUDAPIUrl()}directory/age-groups/`
  if (params?.details?.name) {
    url = `${url}?startswith=${params?.details?.name}`
  } else {
    url = `${url}?limit=${params.limit}&offset=${params.offset}`
  }
  if (params.accessId) {
    url = `${url}&access_id=${params.accessId}`
  }
  const response = await API.get<{
    results: Array<AgeGroupType>,
    next: string | null
  }>(url)
  response.data.results = _.orderBy(response.data.results, 'lower_value')
  return response
}

const fetchDatasets = async (params: transactionParams): Promise<{
  data: {
    results: Array<DatasetsType>
    next: string | null
  }
}> => {
  let url = `${getSPUDAPIUrl()}directory/datasets/`
  if (params?.details?.name) {
    url = `${url}?startswith=${params?.details?.name}`
  } else {
    url = `${url}?limit=${params.limit}&offset=${params.offset}`
  }

  // In the case of services we only want
  // datasets that linked to the parent site to show
  if (params.recordType === 'service' && !params?.isBulkUpdate) {
    // If there is a parent site then fetch
    // the site and pull in its datasets
    // to populate the dropdown
    if (params.parentId) {
      const site: {
        data: {
          update: {
            data: {
              datasets: Array<string>
            }
          }
        }
      } = await API.get(`${getSPUDAPIUrl()}record/${params.parentId}/`)
      const datasets = site.data.update.data.datasets.map(dataset => ({
        id: dataset,
        name: dataset,
      }))
      return {
        data: {
          results: datasets,
          next: null,
        },
      }
    }
    return {
      data: {
        results: [],
        next: null,
      },
    }
  } else {
    return await API.get<{
      results: Array<DatasetsType>,
      next: string | null
    }>(url)
  }
}

const delete_ = async (
  recordId: number | string,
  recordType: string|undefined,
): Promise<{
  data: SPUDOnSaveResponseDetails
}> => {
  return await API.delete(`${getSPUDAPIUrl()}update/${recordType}/${recordId}/`)
}

export const bulkUpdate = async (
  data: {
    record_ids: Array<string | number>,
    find: {[x: string]: unknown},
    replace: {[x: string]: unknown},
    record_type: string,
    bulk_action: string,
  },
): Promise<null> => {
  return await API.patch(`${getSPUDAPIUrl()}records/bulk_update/`, data)
}

const restore = async (
  recordId: number,
  recordType: string|undefined,
): Promise<{
  data: SPUDOnSaveResponseDetails
}> => {
  return await API.patch(`${getSPUDAPIUrl()}update/${recordType}/${recordId}/`, {})
}

export const downloadCSV = async (
  recordType: string|undefined,
  query: string|undefined,
  data: {
    record_ids: Array<number>,
    selected_headers: Array<string>,
    export_email: string,
  },
):Promise<{
  data: string
}> => {
  return await API.post(`${getSPUDAPIUrl()}records/export/${recordType}/${query}`, data)
}

export const uploadCSV = async (
  csvFile: File,
  recordType: string,
  csvHeaders: Array<string>,
  duplicateOverride: Array<number>,
) => {
  let headers = ''
  let duplicateOverrides = ''
  if (csvHeaders) {
    headers = `headers=${csvHeaders.join(',')}`
  }
  if (duplicateOverride) {
    duplicateOverrides = `duplicate_override=${duplicateOverride.join(',')}`
  }
  return await API.post(`${getSPUDAPIUrl()}records/import/${recordType}/?${headers}&${duplicateOverrides}`,
    csvFile,
    {
      headers: {
        'content-type': csvFile.type,
        'content-length': `${csvFile.size}`,
      },
    },
  )
}

export const reImportRows = async (recordType: string, duplicateOverride: Array<PostProcessRowType>) => {
  return await API.post(`${getSPUDAPIUrl()}records/import/${recordType}/?force=true`, {
    csv_rows: duplicateOverride,
  })
}

export const saveBulkUpdateConfig = async (data:unknown) => {
  return await API.post(`${getSPUDAPIUrl()}bulk-update-config/`, data)
}

export const checkFilters = async (config: unknown) => {
  return await API.post(`${getSPUDAPIUrl()}bulk-update-filter/check-filters/`, config)
}

export const spudAPI = {
  fetch,
  create,
  bulkUpdateSave,
  bulkUpdateChanges,
  update,
  copy,
  fetchServiceTypes,
  fetchLanguages,
  fetchAgeGroups,
  fetchDatasets,
  delete: delete_,
  restore: restore,
  downloadCSV: downloadCSV,
  uploadCSV: uploadCSV,
  reImportRows: reImportRows,
  saveBulkUpdateConfig,
  checkFilters,
}
