import { AxiosPromise } from 'axios'
import Api, { getSPUDAPIUrl } from '../helpers/api'

export type AllocateRecordType = {
  record_id: number | string | null,
  allocated_user_id: number | string | null,
}

export const allocateUsers = async (
  recordAllocationArr: Array<AllocateRecordType>,
): Promise<AxiosPromise<[{
  id: number,
  allocated_user: number
}]
  >> => {
  return await Api.patch(`${getSPUDAPIUrl()}records/allocate_user/`, recordAllocationArr)
}
