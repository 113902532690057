import React, { useState } from 'react'
import styled from 'styled-components'
import ToolTip from './ToolTip'

const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  margin-left: 0.2em;
  position: relative;
  text-align: center;
`

interface IconContainerProps {
  icon: React.ReactNode;
  text: string;
}

const IconContainerWrapper: React.FC<IconContainerProps> = ({ icon, text }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <IconContainer
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {icon}
      {showTooltip && <ToolTip visible={showTooltip}>{text}</ToolTip>}
    </IconContainer>
  )
}

export default IconContainerWrapper
