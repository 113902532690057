import React from 'react'
import {
  Row,
  Col,
  Title,
  Alert,
} from '@ix/ix-ui'
import styled from 'styled-components'
import { formatDatetimeToLocal } from '../../helpers/datetime'

const BulkUpdateRecordDetailsContainer = styled.div<{isMarkedForDeletion: boolean}>`
  width: 100%;
  padding: 1em;
  margin: 1em 0;
  background-color: #fff;
  ${props => props.isMarkedForDeletion && `
    border:2px solid #d90e0e;
  `}
`

type BulkUpdateRecordDetailsProps = {
  bulkUpdateRecord: {
    contact_name: string,
    contact_number: string,
    contact_email: string,
    date_submitted: string,
  },
  isMarkedForDeletion: boolean,
}

function BulkUpdateRecordDetails ({ bulkUpdateRecord, isMarkedForDeletion }: BulkUpdateRecordDetailsProps) {
  return <BulkUpdateRecordDetailsContainer isMarkedForDeletion={isMarkedForDeletion}>
    {isMarkedForDeletion && <Row>
      <Col>
        <Alert type="error">
          This record has been marked for deletion by the service provider.
        </Alert>
      </Col>
    </Row>}
    <Row>
      <Col>
        <Title level={3}>
          Service provider updates
        </Title>
        Date submitted: {formatDatetimeToLocal(bulkUpdateRecord.date_submitted)}
      </Col>
    </Row>
    <Row>
      <Col>
        <Title level={4}>Contact name: </Title>
        {bulkUpdateRecord.contact_name}
      </Col>
      <Col>
        <Title level={4}>Contact number: </Title>
        {bulkUpdateRecord.contact_number}
      </Col>
    </Row>
    <Row>
      <Col>
        <Title level={4}>Contact email:</Title>
        {bulkUpdateRecord.contact_email}
      </Col>
    </Row>
  </BulkUpdateRecordDetailsContainer>
}

export default BulkUpdateRecordDetails
