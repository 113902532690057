import React from 'react'

function SiteIcon () {
  return <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.63 124.63">
    <title>Site [Converted]</title>
    <path
      className="cls-1"
      fill='#231f20'
      fillRule='evenodd'
      // eslint-disable-next-line max-len
      d="M70.85,8.94a62.21,62.21,0,0,1,0,124.42v-7.13a55.08,55.08,0,0,0,0-110.16Zm0,124.42a62.21,62.21,0,1,1,0-124.42v7.13a55.08,55.08,0,1,0,0,110.16Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      className="cls-2"
      fill='none'
      fillRule='evenodd'
      stroke='#231f20'
      strokeMiterlimit={10}
      strokeWidth='0.22px'
      // eslint-disable-next-line max-len
      d="M70.85,8.94A62.21,62.21,0,1,1,8.64,71.15,62.32,62.32,0,0,1,70.85,8.94Zm0,7.13A55.08,55.08,0,1,1,15.77,71.15,55.22,55.22,0,0,1,70.85,16.07Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      className="cls-3"
      fill='#231f20'
      fillRule='evenodd'
      stroke='#231f20'
      strokeMiterlimit={10}
      strokeWidth='0.22px'
      // eslint-disable-next-line max-len
      d="M22.46,59.34,32,59.06,71,29.46c13.61,10.8,27.86,20.74,41.4,31.61l9.43.07-17.06-13V33.64c-4.75.07-9.58.07-14.33,0l-.14,3.46-18.5-14a1.23,1.23,0,0,0-1.87,0Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      className="cls-1"
      fill='#231f20'
      fillRule='evenodd'
      // eslint-disable-next-line max-len
      d="M71,33.28,104,58l-.14,51H70.7v-5.11H98.14V60.42L71.21,40l-.5.43v-7ZM70.7,109H37.8l-.14-50.47,33-25.06v7l-27.36,21-.14,42.48H70.7Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      className="cls-2"
      fill='none'
      fillRule='evenodd'
      stroke='#231f20'
      strokeMiterlimit={10}
      strokeWidth='0.22px'
      d="M71,33.28,104,58l-.14,51H37.8l-.14-50.47L71,33.28ZM71.21,40,98.14,60.42v43.49H43.2l.14-42.48Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      className="cls-3"
      fill='#231f20'
      fillRule='evenodd'
      stroke='#231f20'
      strokeMiterlimit={10}
      strokeWidth='0.22px'
      d="M56.3,67.19c1.73,0,3.1-1.73,3.1-3.89s-1.37-4-3.1-4-3,1.8-3,4S54.65,67.19,56.3,67.19Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      className="cls-3"
      fill='#231f20'
      fillRule='evenodd'
      stroke='#231f20'
      strokeMiterlimit={10}
      strokeWidth='0.22px'
      d="M83.23,65.68c1.66,0,3-1.73,3-3.89s-1.37-3.89-3-3.89-3.1,1.73-3.1,3.89S81.5,65.68,83.23,65.68Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      className="cls-3"
      fill='#231f20'
      fillRule='evenodd'
      stroke='#231f20'
      strokeMiterlimit={10}
      strokeWidth='0.22px'
      d="M69.84,63.38c2.09,0,3.82-2.23,3.82-5s-1.73-5-3.82-5S66,55.6,66,58.41,67.68,63.38,69.84,63.38Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      className="cls-3"
      fill='#231f20'
      fillRule='evenodd'
      stroke='#231f20'
      strokeMiterlimit={10}
      strokeWidth='0.22px'
      // eslint-disable-next-line max-len
      d="M48.89,69.93c0-1.51,2.3-1.8,3.89-.22.72,1.15,2.3.43,3.17-.22.43,0,1.15.86,1.66.86l1.8-.72V81.09a5.08,5.08,0,0,0,1.51,2.81V97.58a2.92,2.92,0,0,1-3.53,0V82.46c0-1-2.81-1-2.81,0v15a3.44,3.44,0,0,1-3.53,0V82.24c0-1.15-1.73-1.22-2.16-1.94Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      className="cls-3"
      fill='#231f20'
      fillRule='evenodd'
      stroke='#231f20'
      strokeMiterlimit={10}
      strokeWidth='0.22px'
      // eslint-disable-next-line max-len
      d="M90.36,68.42c0-1.44-2.3-1.8-3.89-.22-.72,1.15-2.3.43-3.1-.14-.5-.07-1.22.86-1.73.79l-1.8-.72V79.58a5.57,5.57,0,0,1-1.51,2.88V96.06a2.92,2.92,0,0,0,3.53,0V80.94c0-1,2.81-1,2.81,0v15a3.44,3.44,0,0,0,3.53,0V80.8c0-1.22,1.73-1.22,2.16-2Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      className="cls-3"
      fill='#231f20'
      fillRule='evenodd'
      stroke='#231f20'
      strokeMiterlimit={10}
      strokeWidth='0.22px'
      // eslint-disable-next-line max-len
      d="M78,66.69c0-1.94-2.66-2.38-4.46-.36-.94,1.51-2.74.58-3.6-.22-.58,0-1.44,1.15-2,1.08l-2.09-.94c-1.8-1.44-2.88-1.51-4.46-.07v14c.07,1.22,2.38,1.22,2.74,2.66v19a3.16,3.16,0,0,0,4,.22V82.74c0-1.3,3.24-1.3,3.24,0V102a3.57,3.57,0,0,0,4.1,0V82.53c0-1.51,2.09-1.44,2.52-2.45Z"
      transform="translate(-8.53 -8.84)"
    />
  </svg>
}

export default SiteIcon
