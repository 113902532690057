import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
const SPUDToastContainer = styled.div<{success?: boolean, error?: boolean}>`
  padding: 30px;
  width: auto;
  max-height: 120px;
  display: flex;
  border-radius: 4px;
  color: #fff;
  background-color: ${props => {
    if (props.success) return '#5cb85c'
    if (props.error) return '#e10606'
    return '#3A8AE8'
  }};
  box-shadow: 0 0 3px #999;
`

const SPUDToastTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  text-align: left;
`

const SPUDToastMessage = styled.div`
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
`

const SPUDToastContent = styled.div`
  display: flex;
  align-content: flex-start;
`

const SPUDToastIcon = styled.div`
  margin: 0 1em;
  display: flex;
  align-items: center;
`

type SPUDToastNotificationProps = {
  title: string,
  message: string | React.ReactElement,
  success?: boolean,
  error?: boolean,
  icon?: IconDefinition
}

function SPUDToastNotification (
  {
    title,
    message,
    success,
    error,
    icon,
  } :SPUDToastNotificationProps): React.ReactElement {
  const fetchCorrectIcon = () => {
    if (success) return faCheck
    else if (error) return faTimes
    return icon
  }

  return (
    <SPUDToastContainer success={success} error={error}>
      <SPUDToastContent>
        <SPUDToastIcon>
          <FontAwesomeIcon size='2x' icon={fetchCorrectIcon() as IconProp || faCheck as IconProp } />
        </SPUDToastIcon>
        <div>
          <SPUDToastTitle>{title}</SPUDToastTitle>
          <SPUDToastMessage>{message}</SPUDToastMessage>
        </div>
      </SPUDToastContent>
    </SPUDToastContainer>
  )
}

export default SPUDToastNotification
