import React, { useEffect, useRef, useState } from 'react'
import pluralize from 'pluralize'
import {
  Button,
  Row,
  Col,
} from '@ix/ix-ui'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { flattenSelectedRows, PopUpContainer } from './ListView.service'
import { bulkUpdate as bulkDelete } from '../../../services/spud.service'
import { SendMessage } from 'react-use-websocket'

type BulkDeleteRecordType = {
  selectedRows: {[x: number]: {record_id: string | number, name: string}},
  recordType: string
  dismissPopup: () => void,
  sendMessage: SendMessage,
}

const BulkDeleteCard = styled.div`
  z-index: 5;
  position: relative;
  background-color: #fff;
  min-width: 100px;
  min-height: 100px;
  border-radius: 3px;
  box-shadow: 0 3px 6px 3px rgb(0 0 0 / 15%);
  height:100%;
  width:100%;
  max-height: 250px;
  padding-bottom: 80px;
  padding-top: 100px;
  word-break: break-all;
`

const BulkDeleteHeader = styled.div`
  background-color: ${props => props.theme.dialogHeaderBackgroundColour};
  padding: 20px;
  border-bottom: 3px solid ${props => props.theme.dialogHeaderHighlightColour};
  font-size: 1.4em;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position:absolute;
  width: 100%;
  top: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 1.2em;
  }
`
const BulkDeleteTitle = styled.h4`
  font-size: 1.4em;
  font-weight: bold;
  margin: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 1.2em;
  }
`

const BulkDeleteCloseButtonContainer = styled.div`
  padding: 5px;
`
const BulkDeleteCloseButton = styled.button`
  border: 0;
  background: none;
  color: #fff;
  padding: 0;
  font-size: 1em;
  cursor: pointer;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  &:hover {
    background-color: rgb(255 255 255 / 22%);
  }
`

const BulkDeleteContentContainer = styled.div`
  padding: 1em;
  display: flex;
  flex: 3;
`

const BulkDeleteContent = styled.div`
  height: 100%;
  width: 100%;
`

const BulkDeleteControlBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #c3c3c3;
  padding: 1em;
  position:absolute;
  width: 100%;
  bottom: 0;
`

const BulkDeleteConfirmButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

function BulkDeleteRecord (
  {
    selectedRows,
    recordType,
    dismissPopup,
    sendMessage,
  }: BulkDeleteRecordType) {
  const [numberOfRows, setNumberOfRows] = useState(0)
  const bulkDeleteCardRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    setNumberOfRows(Object.keys(selectedRows).length)
  }, [selectedRows])

  const bulkDeleteRecords = async () => {
    sendMessage(JSON.stringify({
      type: 'bulk_update',
      message: 'update',
    }))
    /* eslint-disable camelcase */
    const record_ids: Array<string | number> = flattenSelectedRows(
      selectedRows).map(record => record.record_id)
    const bulkDeletePayload = {
      bulk_action: 'delete',
      find: { is_active: true },
      replace: { is_active: false },
      record_type: recordType,
      record_ids,
    }
    /* eslint-enable camelcase */
    await bulkDelete(bulkDeletePayload)
    dismissPopup()
  }

  return <PopUpContainer minWidth='350px' maxWidth='600px'>
    <BulkDeleteCard aria-label='delete-records' ref={bulkDeleteCardRef}>
      <BulkDeleteHeader>
        <BulkDeleteTitle>
          <div>
            Bulk delete {pluralize(recordType)}
          </div>
          <div>
            {pluralize(recordType, numberOfRows, true)} selected
          </div>
        </BulkDeleteTitle>
        <BulkDeleteCloseButtonContainer>
          <BulkDeleteCloseButton
            onClick={() => {
              dismissPopup()
            }}
          >
            <FontAwesomeIcon icon={faTimes as IconProp}/>
          </BulkDeleteCloseButton>
        </BulkDeleteCloseButtonContainer>
      </BulkDeleteHeader>
      <BulkDeleteContentContainer>
        <BulkDeleteContent>
          <Row>
            <Col>
              <strong>
                Are you sure you want to delete the selected records?
              </strong>
            </Col>
          </Row>
        </BulkDeleteContent>
      </BulkDeleteContentContainer>
      <BulkDeleteControlBar>
        <BulkDeleteConfirmButtonGroup>
          <Button
            danger={true}
            onClick={() => {
              bulkDeleteRecords()
            }}
          >
            Confirm delete
          </Button>
        </BulkDeleteConfirmButtonGroup>
      </BulkDeleteControlBar>
    </BulkDeleteCard>
  </PopUpContainer>
}

export default BulkDeleteRecord
