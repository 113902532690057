import React from 'react'
import { RouteComponentProps, Redirect } from 'react-router-dom'

import { Page } from '@ix/ix-ui'
import { useKeycloak } from '@react-keycloak/web'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LoginPage = (props: RouteComponentProps): React.ReactElement => {
  const { keycloak } = useKeycloak()

  if (keycloak?.authenticated) {
    const nextPath = sessionStorage.getItem('redirectTo')
    if (nextPath) {
      sessionStorage.removeItem('redirectTo')
      const filterHash = sessionStorage.getItem('filterHash')
      if (filterHash) {
        sessionStorage.removeItem('filterHash')
        return <Redirect to={{ pathname: nextPath, hash: filterHash }} />
      } else {
        return <Redirect to={nextPath}/>
      }
    }

    return <Redirect to='/dashboard' />
  }

  return (
    <Page />
  )
}

export default LoginPage
