import { AdvancedFilterType } from '../RecordFilter.service'
import { spudAPI } from '../../../services/spud.service'

export const EMAIL_CAMPAIGN_FILTER_OPTIONS: Array<AdvancedFilterType> = [
  {
    name: 'iss_id',
    label: 'Record ID',
    type: 'text',
    defaultValue: '',
    value: '',
    role: 'any',
    validation: (value) => {
      return /^[0-9,]*$/.test(value as string)
    },
    errorMessage: 'Please enter only numbers and commas.',
  },
  {
    name: 'organisation.name',
    label: 'Organisation name',
    type: 'text',
    defaultValue: '',
    value: '',
    additionalControls: [
      'contains', 'doesnotcontain', 'endswith', 'equals', 'notequals', 'startswith',
    ],
    role: 'any',
  },
  {
    name: 'service.is_cald_specific',
    label: 'CALD services',
    type: 'toggle',
    defaultValue: false,
    value: false,
    role: 'any',
  },
  {
    name: 'service.is_lgbtiqa_plus_specific',
    label: 'LGBTIQA+ Services',
    type: 'toggle',
    defaultValue: false,
    value: false,
    role: 'any',
  },
  {
    name: 'service.sa_priority_service',
    label: 'SA Priority Services',
    type: 'toggle',
    defaultValue: false,
    value: false,
    role: 'any',
  },
  {
    name: 'site.datasets',
    label: 'Datasets',
    type: 'multiselect',
    defaultValue: '',
    value: [],
    optionFunction: spudAPI.fetchDatasets,
    role: 'Administrator',
  },
  {
    name: 'service.indigenous_classification',
    label: 'Indigenous Classification',
    type: 'dropdown',
    defaultValue: '',
    value: '',
    options: [
      {
        name: 'Aboriginal (indigenous) specific',
        id: 'specific',
      },
      {
        name: 'Culturally safe for Aboriginal (indigenous)',
        id: 'culturally_safe',
      },
      {
        name: 'Mainstream who cater for Aboriginal (indigenous)',
        id: 'mainstream_who_cater',
      },
      {
        name: 'No Indigenous classification selected',
        id: 'mainstream',
      },
    ],
    role: 'any',
  },
]
