import React from 'react'
import { ArrayChange, diffWords } from 'diff'
import { markDifferences } from './BulkAutoUpdateService'
import _ from 'lodash'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import { SPUDServiceRecordDetails } from '../../../@types/Service.type'

const SORT_OBJ_MAPPING: {[x: string]: Array<string>} = {
  phones: ['phone_type', 'number', 'comment', 'confidential'],
  emails: ['email', 'confidential'],
  social_media: ['type', 'url'],
  languages: ['name'],
  accessibility: ['name'],
}

export const indicateChanges = (original: string, updated: string, reviewForm = false) => {
  if (reviewForm) {
    const originalArray = original.split(' ')
    const updatedArray = updated.split(' ')
    return markDifferences(originalArray, updatedArray, false) as Array<React.ReactElement>
  }

  const difference = diffWords(
    original,
    updated,
    {
      // Ignoring this, as wile the diffWords does not specify the type, it is required to work properly
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      intlSegmenter: new Intl.Segmenter(undefined, { granularity: 'word' }),
    },
  )
  return difference.map((part, index) => {
    if (part.added) {
      return reviewForm ? <mark>{part.value}</mark> : <span key={index} style={{ color: 'green' }}>{part.value}</span>
    } else if (part.removed) {
      return <span key={index} style={{ color: 'red', textDecoration: 'line-through' }}>{part.value}</span>
    } else {
      return <span key={index}>{part.value}</span>
    }
  })
}

export const renderArrayDifferences = (differences: Array<ArrayChange<string>>, reviewForm = false) => {
  return differences.map((part, index) => {
    let style = {}
    if (part.added) {
      style = reviewForm ? { backgroundColor: '#FFFF00' } : { color: 'green' }
    } else if (part.removed) {
      style = reviewForm
        ? { textDecoration: 'line-through' }
        : { color: 'red', textDecoration: 'line-through' }
    }
    if (part.value.length > 1) {
      return (
        <span key={index}>
          {part.value.map((val, index) =>
            <div key={index}>
              <span style={style} >{val} {Object.keys(style).length === 0 && <strong>(Unchanged)</strong>}</span>
            </div>)}
        </span>
      )
    }
    return (
      <ul style={{ listStyle: 'none', margin: 0, padding: 0 }} key={index}>
        <li style={style}>{part.value} {Object.keys(style).length === 0 && <strong>(Unchanged)</strong>}</li>
      </ul>
    )
  })
}

export const sortObject = (
  obj: {[x: string]: unknown},
  field: keyof SPUDSiteRecordDetails & keyof SPUDServiceRecordDetails & keyof Location,
) => {
  return _.pick(obj, SORT_OBJ_MAPPING[field])
}
