import React from 'react'
import { Row, Col, Title, Button, Alert } from '@ix/ix-ui'
import styled from 'styled-components'
import { BulkUpdateFlowStateType } from './BulkAutoUpdateService'

const BulkUpdateWelcomeComponentContainer = styled.div`
  width: 40rem;
`
const BulkUpdateWelcomeDivider = styled.hr`
  width: 100%
`

type Props = {
  setBulkAutoUpdateFlowState: (flowState: BulkUpdateFlowStateType) => void
  disableStart: boolean
  expiredLink: boolean
}

function BulkUpdateWelcomeComponent ({ setBulkAutoUpdateFlowState, disableStart, expiredLink }: Props) {
  return <BulkUpdateWelcomeComponentContainer>
    <Row>
      <Col>
        {!disableStart && <Row>
          <Col>
            <Alert type='error'>Invalid link, no record id supplied</Alert>
          </Col>
        </Row>}
        {expiredLink && <Row>
          <Col>
            <Alert type='error'>
              Link has expired. Contact Infoxchange at{' '}
              <a href="mailto:database@infoxchange.org">database@infoxchange.org</a>
              {' '}for a new link.
            </Alert>
          </Col>
        </Row>}
        <Row>
          <Col align='center'>
            <Title>
              Update Service Provider
            </Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <BulkUpdateWelcomeDivider/>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Infoxchange maintains the details of your organisation in an
              online directory of community support services.
            </p>
            <p>
              To ensure that we accurately describe all the services your organisation provides,
              please read the details in the forms displayed and update where necessary.
            </p>
            <p>
              You can leave the web forms at anytime and it will automatically save your progress.
              Just click on the link again to continue where you left off.
            </p>
            <p>
              Edits made to this form on other computers or browsers will not
              include the changes you make on this computer using your current browser.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <BulkUpdateWelcomeDivider/>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              style={{ margin: 0, height: '40px', width: '100%' }}
              onClick={() => setBulkAutoUpdateFlowState('site')}
              disabled={!disableStart || expiredLink}
              active={disableStart && !expiredLink}
            >
              Start
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  </BulkUpdateWelcomeComponentContainer>
}

export default BulkUpdateWelcomeComponent
