import { useEffect, useState } from 'react'

const MOBILE_BREAKPOINT = 1300

export function IsMobile (breakpointOverride?: number): boolean {
  const [width, setWidth] = useState(0)
  useEffect(() => {
    function handleResize () {
      setWidth(window.innerWidth || 100)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return width <= (breakpointOverride || MOBILE_BREAKPOINT)
}
