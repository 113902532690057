import React from 'react'
import { Button } from '@ix/ix-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface TrashIconProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  active: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const TrashButtonWrapper = styled(Button)`
  color: #8c9bad;
  background-color: transparent;
  transform: scale(1.5);
  margin-top: 10px;

  :hover {
    color: #8c9bad;
    background-color: transparent;
  }

  :focus {
    outline: none;
    box-shadow: none;
  }
`

const TrashIcon: React.FC<TrashIconProps> = ({ onClick, active, disabled, style }) => (
  <TrashButtonWrapper
    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation() // this is to fix the dragging when trying to click the trash button
      onClick(event)
    }}
    active={active}
    disabled={disabled}
    style={style}
  >
    <FontAwesomeIcon icon={faTrash as IconProp} />
  </TrashButtonWrapper>
)

export default TrashIcon
