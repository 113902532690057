import styled from 'styled-components'
import {
  AvatarIconWithStatusContent,
  AvatarIconWithStatusWrapper,
  Button,
  Title,
  SpudTheme,
} from '@ix/ix-ui'
import React, { useState } from 'react'
import { faCopy, faNetworkWired } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fetchRecordIcon, getPageType } from '../../helpers/record'
import toast from 'react-hot-toast'
import SPUDToastNotification from './SPUDToastNotification'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useAuth } from '../../helpers/auth'

const SimpleSPUDRecordCardContainer = styled.div`
   display: flex;
   flex-direction: row;
   color: #4F4F4F;
   align-items: center;
   flex-grow: 0;
   flex-shrink: 1;
   max-width: 100%;
   min-width: 70%;
`

const SimpleSPUDRecordCardDetails = styled.div`
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 1.2em;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
      font-size: 0.9em;
  }
`

const SimpleSPUDRecordCardDetailsSupplementaryInfo = styled.h4`
  margin: 0;
  font-size: 0.9em;
  font-weight: normal;
  color: ${props => props.theme.colors.secondary};
`

const RecordDetailsHeading = styled(Title)`
  font-size: 1.2rem;
  color: #4F4F4F;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
    font-size: 0.9rem;
  }
`

const RecordDetailsHeader = styled.div`
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
  flex-shrink: 0.9;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
    font-size: 1rem;
  }
`

const ResponsiveAvatarIconWithStatusWrapper = styled(AvatarIconWithStatusWrapper)`
  width: 4em;
  height: 4em;
  flex-shrink: 0;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    display: none;
  }
`

type Props = {
  recordType: string | undefined,
  status?: string | undefined,
  recordId?: string | undefined | number | null,
  title: string | React.ReactElement,
  color?: string | undefined,
  issId?: number | null,
  referralEnabled?: boolean,
  active: boolean | undefined,
}

interface SPUDRecordStatusIndicatorProps {
  status: string | undefined
}

const SPUDRecordStatusIndicator = styled.div<SPUDRecordStatusIndicatorProps>`
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  min-width: 1.5em;
  min-height: 1.5em;
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: ${props => {
    if (props.status === 'draft') return '#478fe5'
    if (props.status === 'published') return '#25af06'
    if (props.status === 'deleted') return '#d90e0e'
    return 'orange'
  }};
`

const SPUDRecordStatusTooltip = styled.div`
  position: absolute;
  z-index: 2;
  font-size: 1.2em;
  right: -7em;
  padding: 0.1em;
  top: 2.5em;
  border: 1px solid #6e6e6e;
  background-color: ${SpudTheme.colors.grayDarker || '#4f4f4f'};
  color: #fff;
`

function SimpleSPUDRecordCard (
  {
    recordType,
    title,
    recordId,
    color,
    status,
    issId,
    active,
    referralEnabled,
  }: Props): React.ReactElement {
  const [showTooltip, setShowTooltip] = useState(false)

  const { userRole } = useAuth()

  const saveIdToClipboard = (idToCopy: string | number | undefined) => async () => {
    if (typeof idToCopy !== 'undefined' && idToCopy) {
      await navigator.clipboard.writeText(idToCopy.toString())
      toast.custom(
        <SPUDToastNotification
          title="Copied"
          message={`${getPageType(recordType)} ID ${idToCopy} has been saved to the clipboard`}
          icon={faCopy}
        />,
        {
          position: 'bottom-center',
        },
      )
    }
  }

  const currentStatus = recordId ? (active ? status : 'deleted') : 'draft'

  const capitaliseRecordType = (): string | undefined => {
    if (recordType) {
      return recordType?.charAt(0).toUpperCase() + recordType?.slice(1)
    }
    return recordType
  }

  const SpudID = () => (
    <span>ID: SP_{recordId}
      <Button
        style={{ margin: 0, padding: 0 }}
        title='Copy to clipboard'
        onClick={saveIdToClipboard(`SP_${recordId}`)}
        icon
      >
        <FontAwesomeIcon size='lg' icon={faCopy as IconProp}/>
      </Button>
    </span>
  )

  const IssId = () => (
    <span> ISS: {issId}
      <Button
        style={{ margin: 0, padding: 0 }}
        title='Copy to clipboard'
        onClick={saveIdToClipboard(issId?.toString())}
        icon
      >
        <FontAwesomeIcon size='lg' icon={faCopy as IconProp}/>
      </Button>
    </span>
  )

  const renderSupplementaryInfo = () => (
    <SimpleSPUDRecordCardDetailsSupplementaryInfo>
      {(recordId && capitaliseRecordType()) || recordType}
      {recordId && ' ('}
      {recordId && ((recordId && !issId) || userRole === 'Administrator') && <SpudID />}
      {issId && <IssId />}
      {recordId && ')'}
      {referralEnabled && <div>
         Referral enabled <FontAwesomeIcon icon={faNetworkWired as IconProp} color='#4F4F4F'/>
      </div>}
    </SimpleSPUDRecordCardDetailsSupplementaryInfo>
  )

  return (
    <SimpleSPUDRecordCardContainer>
      <ResponsiveAvatarIconWithStatusWrapper
        md backgroundColour='#E3EDFA'
        onMouseOver={() => setShowTooltip(true)}
        onMouseOut={() => setShowTooltip(false)}
      >
        {status && (
          <SPUDRecordStatusIndicator
            status={currentStatus}
            aria-label={`${recordType} status is ${currentStatus} ${!issId ? '(new)' : ''}`}
          />)}
        {showTooltip && status &&
          <SPUDRecordStatusTooltip >
            {recordType} status is {currentStatus} {!issId ? '(new)' : ''}
          </SPUDRecordStatusTooltip>}
        <AvatarIconWithStatusContent>
          {fetchRecordIcon(recordType, '2x', color)}
        </AvatarIconWithStatusContent>
      </ResponsiveAvatarIconWithStatusWrapper>
      <SimpleSPUDRecordCardDetails>
        <RecordDetailsHeader>
          <RecordDetailsHeading level={4} fontsize={status ? '1.4rem' : '1.3em'} marginTop={!status ? '0.5em' : '0'}>
            {title}
          </RecordDetailsHeading>
        </RecordDetailsHeader>
        {renderSupplementaryInfo()}
      </SimpleSPUDRecordCardDetails>

    </SimpleSPUDRecordCardContainer>
  )
}

export default SimpleSPUDRecordCard
