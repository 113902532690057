import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  Col,
  Row,
  Title,
} from '@ix/ix-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

/**
 * SPUDFieldCollapseProps interface for SPUDFieldCollapse component
 * @interface
 * @property {React.ReactNode} children - The child components to be rendered within the collapsible section.
 * @property {boolean} [defaultCollapsed=false] - Determines whether the collapsible section is collapsed or expanded by default.
 * @property {string} title - The title to be displayed on the collapsible section.
 */
interface SPUDFieldCollapseProps {
  children: React.ReactNode;
  defaultCollapsed?: boolean;
  title: string;
}

// Styled component for the collapsible container
const SPUDFieldCollapseContainer = styled.div`
  border: 1px solid #848484;
  padding: 0.5em;
  border-radius: 5px;
  margin: 1em 0;
  cursor: pointer;
  transition: all 0.3s;`

/**
 * SPUDFieldCollapse is a collapsible component that can toggle the visibility of its child components.
 * @param {React.ReactNode} children - The child components to be rendered within the collapsible section.
 * @param {boolean} [defaultCollapsed=false] - Determines whether the collapsible section is collapsed or expanded by default.
 * @param {string} title - The title to be displayed on the collapsible section.
 * @returns {JSX.Element} A collapsible component that can toggle the visibility of its child components.
 */
function SPUDFieldCollapse ({ children, defaultCollapsed = false, title }: SPUDFieldCollapseProps) {
  // State variable to track whether the collapsible section is collapsed or not
  const [isCollapsed, setIsCollapsed] = useState(false)

  // Effect hook to set the initial state of isCollapsed based on the defaultCollapsed prop
  useEffect(() => {
    setIsCollapsed(defaultCollapsed)
  }, [defaultCollapsed])

  // Function to toggle the value of isCollapsed when the button is clicked
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <SPUDFieldCollapseContainer aria-label={`${title}-collapser`}>
      <Row onClick={toggleCollapse}>
        <Col direction='row' justify='space-between' align='center'>
          <Title level={4} marginTop='0'>
            {isCollapsed ? 'Show' : 'Hide'} {title}
          </Title>
          <div>
            <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {!isCollapsed && children}
        </Col>
      </Row>
    </SPUDFieldCollapseContainer>
  )
}

export default SPUDFieldCollapse
