import React, { useState, useEffect } from 'react'
import { withContext } from '../../context/AppContext'
import { Col, Container, Content, Page, Section, Row, Title, Text } from '@ix/ix-ui'
import styled from 'styled-components'
import SpudAvatar from '../../components/SPUDAvatar'
import { userService } from '../../services/user.service'

interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  role: string;
}

const UserPageStyledRow = styled(Row)`
  padding: 0.5em 0;
`

const UserPageSubheading = styled(Text)`
  font-weight: bold;
  font-size: 1.2rem;
`

const UserPageDivider = styled.div`
  background-color: #e0e0e0;
  height: 1px;
  margin: 1em 0;
`

const UserPageContainer = styled(Container)`
  background-color: #fff;
  padding: 1em 2em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
`

const UserPageHeader = styled(Title)`
  padding-bottom: 0.7em;
  padding-left: 0.5em;
  line-height: 1.5;
  font-size: 2rem;
`

const UserPageAvatar = styled(SpudAvatar)`
  width: 100px !important;
  height: 100px;
  display: block;
`

const UserAvatarWrapper = styled.div`
  width: 50px;
  height: 50px;
`

const MainText = styled(Text)`
  color: ${props => props.theme.colors.grayDark};
`

const UserPage = () => {
  const [user, setUser] = useState<User>({
    id: 0,
    first_name: 'Loading',
    last_name: 'User',
    email: 'loading@infoxchange.org',
    username: 'Loading',
    role: 'User',
  })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await userService.getCurrentUser()
        setUser(userData)
      } catch (err) {
        setError('An error occurred while fetching user data')
      } finally {
        setLoading(false)
      }
    }

    fetchUser()
  }, [])

  if (loading) return <p>Loading user details...</p>
  if (error) return <p>{error}</p>

  return (
    <Page>
      <Content>
        <UserPageContainer>
          <UserAvatarWrapper>
            <UserPageAvatar
              user={{
                first_name: user.first_name,
                last_name: user.last_name,
                id: user.id !== 0 ? user.id.toString() : '0',
              }}
            />
          </UserAvatarWrapper>
          <UserPageHeader level={1}>User Details</UserPageHeader>
        </UserPageContainer>

        <Section style={{ background: '#fff', padding: '2em' }}>
          <Container style={{ background: '#fff' }}>
            <UserPageStyledRow>
              <Col>
                <p>
                  <UserPageSubheading>ID: &nbsp;</UserPageSubheading>
                  <MainText>{user.id}</MainText>
                </p>
              </Col>
            </UserPageStyledRow>
            <UserPageDivider />
            <UserPageStyledRow>
              <Col>
                <p>
                  <UserPageSubheading>First Name: &nbsp;</UserPageSubheading>
                  <MainText>{user.first_name}</MainText>
                </p>
              </Col>
            </UserPageStyledRow>
            <UserPageDivider />
            <UserPageStyledRow>
              <Col>
                <p>
                  <UserPageSubheading>Last Name: &nbsp;</UserPageSubheading>
                  <MainText>{user.last_name}</MainText>
                </p>
              </Col>
            </UserPageStyledRow>
            <UserPageDivider />
            <UserPageStyledRow>
              <Col>
                <p>
                  <UserPageSubheading>Username: &nbsp;</UserPageSubheading>
                  <MainText>{user.username}</MainText>
                </p>
              </Col>
            </UserPageStyledRow>
            <UserPageDivider />
            <UserPageStyledRow>
              <Col>
                <p>
                  <UserPageSubheading>Email: &nbsp;</UserPageSubheading>
                  <MainText>{user.email}</MainText>
                </p>
              </Col>
            </UserPageStyledRow>
            <UserPageDivider />
            <UserPageStyledRow>
              <Col>
                <p>
                  <UserPageSubheading>Role: &nbsp;</UserPageSubheading>
                  <MainText>{user.role}</MainText>
                </p>
              </Col>
            </UserPageStyledRow>
          </Container>
        </Section>
      </Content>
    </Page>
  )
}

export default withContext(UserPage)
