import React, { Dispatch, SetStateAction, useState } from 'react'
import { CommentIconButton } from './SPUDCommentHelperComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCheckCircle, faCircleNotch, faEdit, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { CommentType, deleteComment, saveComment } from '../../../../services/comment.service'
import toast from 'react-hot-toast'
import SPUDToastNotification from '../../../../components/General/SPUDToastNotification'
import { useAuth } from '../../../../helpers/auth'
import styled from 'styled-components'
import { SubmitHandler } from 'react-hook-form'
import { AxiosErrorWithObjectResponse } from '../../../../helpers/api'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const CommentTableActionContainer = styled.div`
  display: flex;
`

type SPUDTableActionsProps = {
  comments: Array<CommentType>,
  comment: CommentType,
  setComments: (comments: Array<CommentType>) => void,
  processError: (error: AxiosErrorWithObjectResponse | Error) => void,
  recordId: string | undefined,
  editingComments: {[id: number]: CommentType}
  setEditingComments: (editingComments: {[id: number]: CommentType}) => void
  disabled: boolean
  externalComment: boolean
  externalLoading: boolean
  setHasExternalComments?: Dispatch<SetStateAction<boolean>>
}

function SPUDCommentTableActions (
  {
    editingComments,
    comment,
    setEditingComments,
    processError,
    setComments,
    comments,
    recordId,
    disabled,
    externalComment,
    externalLoading,
    setHasExternalComments,
  }: SPUDTableActionsProps): React.ReactElement {
  const { user, userRole } = useAuth()
  const [currentlyDeletingComment, setCurrentlyDeletingComment] = useState<{[id: number]: boolean}>({})
  const [savingEditLoading, setSavingEditLoading] = useState(false)

  const onSubmit: SubmitHandler<CommentType> = async (data: CommentType) => {
    let newComment
    if (data.id) {
      try {
        setSavingEditLoading(true)
        const newComments = [...comments]
        newComment = await saveComment(recordId, data, data.id)
        const oldCommentIndex = comments.findIndex(comment => comment.id === data.id)
        newComments[oldCommentIndex] = newComment.data
        setComments(newComments)
        delete editingComments[data.id]
        setEditingComments({ ...editingComments })
        toast.custom(
          <SPUDToastNotification
            title="Success"
            message={
              <span>
                Comment has been updated
              </span>
            }
            success
          />,
          {
            position: 'bottom-right',
          },
        )
        setSavingEditLoading(false)
      } catch (error) {
        if (error instanceof Error) processError(error)
        setSavingEditLoading(false)
      }
    }
  }

  const onAcknowledgeComment = async () => {
    setSavingEditLoading(true)
    const newComments = [...comments]
    comment = {
      ...comment,
      acknowledge_external_comment: true,
    }
    const newComment = await saveComment(recordId, comment, comment.id)
    const oldCommentIndex = comments.findIndex(currentComment => currentComment.id === comment.id)
    newComments[oldCommentIndex] = newComment.data
    setComments(newComments)
    toast.custom(
      <SPUDToastNotification
        title="Success"
        message={
          <span>
            Comment has been acknowledged
          </span>
        }
        success
      />,
      {
        position: 'bottom-right',
      },
    )
    setSavingEditLoading(false)
    if (newComments.filter(updatedComment => !updatedComment.acknowledge_external_comment).length > 0) {
      setHasExternalComments?.(true)
    } else {
      setHasExternalComments?.(false)
    }
  }

  const setCommentEditStatus = (status: boolean, save: boolean) => {
    if (status) {
      setEditingComments({ ...editingComments, [comment.id]: { ...comment } })
    } else {
      if (editingComments?.[comment.id]) {
        if (save) {
          onSubmit(editingComments[comment.id])
        } else {
          delete editingComments[comment.id]
          setEditingComments({ ...editingComments })
        }
      }
    }
  }

  const showEditCommentButton = (comment: CommentType): boolean => {
    // Hide if an Admin is deleting the comment.
    // Show if the comment user and logged-in users are the same or the user in an admin
    if (currentlyDeletingComment[comment.id]) {
      return false
    } else if (comment.commenting_user?.username === user.username || userRole === 'Administrator') {
      return true
    }
    return false
  }

  if (externalComment) {
    return <CommentTableActionContainer>
      <CommentIconButton
        title="Acknowledge comment"
        aria-label='Acknowledge comment'
        onClick={onAcknowledgeComment}
        disabled={false}
      >
        {savingEditLoading || externalLoading
          ? <FontAwesomeIcon icon={faCircleNotch as IconProp} fixedWidth spin/>
          : <FontAwesomeIcon icon={faCheck as IconProp}/>}
      </CommentIconButton>
    </CommentTableActionContainer>
  }
  if (editingComments[comment.id]) {
    return <CommentTableActionContainer>
      <CommentIconButton
        title="Save changes"
        aria-label='Save comment'
        onClick={() => setCommentEditStatus(false, true)}
        disabled={disabled}
      >
        {savingEditLoading
          ? <FontAwesomeIcon icon={faCircleNotch as IconProp} fixedWidth spin/>
          : <FontAwesomeIcon icon={faCheck as IconProp}/>}
      </CommentIconButton>
      <CommentIconButton
        title="Cancel changes"
        aria-label='Clear comment'
        onClick={() => setCommentEditStatus(false, false)}
      >
        <FontAwesomeIcon icon={faTimes as IconProp}/>
      </CommentIconButton>
    </CommentTableActionContainer>
  } else {
    return <CommentTableActionContainer>
      {showEditCommentButton(comment) && (
        <CommentIconButton
          title="Edit comment"
          aria-label='Edit comment'
          onClick={() => {
            setCommentEditStatus(true, false)
          }}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faEdit as IconProp}/>
        </CommentIconButton>
      )}
      {userRole === 'Administrator' && currentlyDeletingComment[comment.id] && (
        <CommentIconButton
          textOnly={true}
          title="Confirm delete comment"
          aria-label="Confirm delete comment"
          disabled={disabled}
          onClick={async () => {
            try {
              await deleteComment(comment.id)
              const newComments = [...comments]
              const commentIndex = newComments.findIndex(com => com.id === comment.id)
              newComments.splice(commentIndex, 1)
              setComments(newComments)
              setCurrentlyDeletingComment({ ...currentlyDeletingComment, [comment.id]: false })
              toast.custom(
                <SPUDToastNotification
                  title="Success"
                  message={<span>Comment deleted</span>}
                  success
                />,
                {
                  position: 'bottom-right',
                },
              )
            } catch (error) {
              setCurrentlyDeletingComment({ ...currentlyDeletingComment, [comment.id]: false })
              if (error instanceof Error) processError(error)
            }
          }}
        >
          <FontAwesomeIcon icon={faCheckCircle as IconProp} />
        </CommentIconButton>
      )}
      {userRole === 'Administrator' && !currentlyDeletingComment[comment.id] &&
        <CommentIconButton
          title="Delete comment"
          aria-label="Delete comment"
          onClick={() => {
            setCurrentlyDeletingComment({ ...currentlyDeletingComment, [comment.id]: true })
          }}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faTrash as IconProp}/>
        </CommentIconButton>}
      {userRole === 'Administrator' && currentlyDeletingComment[comment.id] &&
        <CommentIconButton
          title="Cancel delete comment"
          aria-label="Cancel Delete comment"
          onClick={() => {
            setCurrentlyDeletingComment({ ...currentlyDeletingComment, [comment.id]: false })
          }}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faTimes as IconProp}/>
        </CommentIconButton>
      }
    </CommentTableActionContainer>
  }
}

export default SPUDCommentTableActions
