import React, { useEffect, useState } from 'react'
import { withContext } from '../../../context/AppContext'
import {
  LoadingProgressBar,
} from '@ix/ix-ui'
import { SPUDWebSocketMessage } from '../../../context/AppContext.type'
function BackgroundTaskProgress (
  {
    lastMessage,
  }: {
    lastMessage: SPUDWebSocketMessage<{
        total_records: 0,
        processed: 0,
      }>
  },
) {
  const [backgroundTaskCurrentStatus, setBackgroundTaskCurrentStatus] = useState({
    total_records: 0,
    processed: 0,
  })

  useEffect(() => {
    if (lastMessage !== null) {
      if (lastMessage.type === 'update_in_progress') {
        setBackgroundTaskCurrentStatus(lastMessage.message)
      } else if (lastMessage?.type === 'completed') {
        setBackgroundTaskCurrentStatus({
          total_records: 0,
          processed: 0,
        })
      }
    }
  }, [lastMessage])

  if (backgroundTaskCurrentStatus.processed && lastMessage?.type !== 'completed') {
    return <div>
      <LoadingProgressBar
        currentProgress={backgroundTaskCurrentStatus.processed}
        totalCompleted={backgroundTaskCurrentStatus.total_records}
      />
    </div>
  }
  return null
}

export default withContext(BackgroundTaskProgress)
