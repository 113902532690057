import React, { useState } from 'react'
import {
  Button,
  Col,
  Container,
  Content,
  Header,
  Page,
  Section,
  Row,
  Title,
} from '@ix/ix-ui'
import { withContext } from '../../../context/AppContext'
import { useAuth } from '../../../helpers/auth'
import EmailCampaignForm from './EmailCampaignForm'
import { AdvancedFilterType } from '../../RecordFilter/RecordFilter.service'
import AdvancedFilters from '../../RecordFilter/AdvancedFilters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import styled from 'styled-components'

const FilterSidebarWrapper = styled.div`
  width: 40%;
  margin-top: 8em;
`

const EmailCampaignPage = () => {
  const { userRole } = useAuth()
  const [editingFilter, setEditingFilter] = useState('')
  const [pageFilters, setPageFilters] = useState<{[x: string]: AdvancedFilterType}>()
  const [openAdvancedFilters, setOpenAdvancedFilters] = useState(false)

  const toggleFilterSidebar = () => {
    setOpenAdvancedFilters(!openAdvancedFilters)
  }

  const handleClearFilter = () => {
    setOpenAdvancedFilters(false)
    setPageFilters({})
  }

  const filterAndFetchRecords = (filters: {[x: string]: AdvancedFilterType}) => {
    if (Object.keys(filters).length) {
      setPageFilters(filters)
    } else {
      setPageFilters({})
    }
  }

  if (userRole !== 'Administrator') {
    return (
      <>
        <Header>
          <Title level={1}>Unauthorized Access</Title>
        </Header>
        <Section>You are unauthorized to access this page.</Section>
      </>
    )
  }

  return (
    <Page>
      <Content>
        <Row style={{ padding: '1em 0 0 2em' }}>
          <Col>
            <Title level={1}>Email campaign</Title>
          </Col>
          <Col direction='row' justify='flex-end'>
            <Button primary onClick={toggleFilterSidebar} style={{ margin: '1.5em 3em 0 0' }}>
              Filter <FontAwesomeIcon icon={faPlus as IconProp}/>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Section>
              <Container style={{ background: '#fff', padding: '1.5em' }}>
                <Row>
                  <Col>
                    <EmailCampaignForm
                      filterValue={pageFilters as {[x: string]: AdvancedFilterType}}
                      onClearFilter={handleClearFilter}
                      openAdvancedFilters={openAdvancedFilters}
                      onOpenAdvancedFilters={(openFilter, chip) => {
                        setOpenAdvancedFilters(openFilter)
                        if (chip) {
                          setEditingFilter(chip)
                        } else {
                          setEditingFilter('')
                        }
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Section>
          </Col>
        </Row>
      </Content>
      {openAdvancedFilters && <FilterSidebarWrapper>
        <AdvancedFilters
          onDismissAdvancedFilters={() => {
            setOpenAdvancedFilters(false)
            setEditingFilter('')
          }}
          recordType='email-campaign'
          currentPageFilters={pageFilters}
          userRole={userRole}
          onUpdateFilters={(filters) => filterAndFetchRecords(filters)}
          pageType={'email-campaign'}
          editingFilter={editingFilter}
        />
      </FilterSidebarWrapper>}
    </Page>
  )
}

export default withContext(EmailCampaignPage)
