import React, { useEffect, useState } from 'react'
import { AdvancedFilterType, formatFilterFieldSummaryValue } from './RecordFilter.service'
import styled from 'styled-components'
import { Row, Col, Button } from '@ix/ix-ui'
import { faPen, faTrash, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import AdvancedFilterAddEditCard from './AdvancedFilterAddEditCard'

type AdvancedFilterSummaryCardProps = {
  filterField: AdvancedFilterType,
  filterFields: Array<AdvancedFilterType>,
  onAddNewFilter: (filter: AdvancedFilterType, filterKey?: string) => void,
  onDeleteFilter: (filter: AdvancedFilterType, filterKey?: string) => void,
  recordType: string,
  filterKey: string,
  editingFilter: string,
}

const AdvancedFilterSummaryCardContainer = styled.div`
  background-color: #CACACA;
  margin: 0.5em;
  padding: 1em;
  border-radius: 3px;
  font-size: 14px;
  font-color: #222;
`

const FilterValue = styled.strong`
  color: #373E42;
  margin-left: 5px;
`

export const AdvancedFilterSummaryCardButton = styled(Button)`
  border: none;
  background: none;
  margin: 0;
  :hover {
    background: none;
    text-decoration: underline;
  }
  :focus {
    box-shadow: none;
  }
`

const AdvancedFilterEditCardContainer = styled.div`
  border: 1px solid grey;
  padding: 1em;
  margin-top: 1em;
`

function AdvancedFilterSummaryCard (
  {
    filterField,
    filterFields,
    onAddNewFilter,
    onDeleteFilter,
    recordType,
    filterKey,
    editingFilter,
  }: AdvancedFilterSummaryCardProps) {
  const [editFilter, setEditFilter] = useState(false)

  useEffect(() => {
    if (filterKey === editingFilter) {
      setEditFilter(true)
    } else {
      setEditFilter(false)
    }
  }, [editingFilter])

  return (<AdvancedFilterSummaryCardContainer>
    <Row align='center'>
      <Col direction='row' align='center' justify='space-between' style={{ color: '#7D7D7D' }} sm={12} md={12}>
        <div>
          {filterField.label}:
          <FilterValue>{formatFilterFieldSummaryValue(filterField)}</FilterValue>
        </div>
        <div>
          <AdvancedFilterSummaryCardButton
            onClick={() => setEditFilter(!editFilter)}
          >
            {!editFilter
              ? <FontAwesomeIcon icon={faPen as IconProp}/>
              : <FontAwesomeIcon icon={faChevronDown as IconProp} />
            }
          </AdvancedFilterSummaryCardButton>
          <AdvancedFilterSummaryCardButton
            onClick={() => onDeleteFilter(filterField, filterKey)}
          >
            <FontAwesomeIcon icon={faTrash as IconProp}/>
          </AdvancedFilterSummaryCardButton>
        </div>
      </Col>
    </Row>
    {editFilter && <Row>
      <Col style={{ padding: 0 }}>
        <AdvancedFilterEditCardContainer>
          <AdvancedFilterAddEditCard
            filterFields={filterFields}
            dismissAddEditCard={() => setEditFilter(false)}
            recordType={recordType}
            onAddNewFilter={onAddNewFilter}
            editingActiveFilter={filterField}
            filterKey={filterKey}
          />
        </AdvancedFilterEditCardContainer>
      </Col>
    </Row>
    }
  </AdvancedFilterSummaryCardContainer>)
}

export default AdvancedFilterSummaryCard
