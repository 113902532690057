import React, { useState } from 'react'
import { listCrisisKeywords, createNewCrisisKeyword } from '../../services/crisisKeywords.service'
import { Controller, useFormContext } from 'react-hook-form'
import { components, GroupBase, InputProps, OptionsOrGroups } from 'react-select'
import { Option } from '../forms/AddEditRecord.type'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { Title } from '@ix/ix-ui'

export type SPUDCrisisKeywordsAutocompleteProps = {
  title: string,
}

const Input = (props: InputProps<Option, true, GroupBase<Option>>) => <components.Input {...props} isHidden={false} />

/**
 * SPUDCrisisKeywordsAutocomplete component
 * This component is used to create a dropdown list of crisis keywords with autocomplete functionality.
 * It also allows the creation of new crisis keywords directly from the dropdown.
 *
 * @param {SPUDCrisisKeywordsAutocompleteProps} props - The properties that define the component's behavior and display.
 */
const SPUDCrisisKeywordsAutocomplete = (
  { title }: SPUDCrisisKeywordsAutocompleteProps,
) => {
  // useFormContext is a custom React Hook that returns the context of the form it's used in.
  const { control, getValues, setValue } = useFormContext()

  // State variable for the input value of the dropdown.
  const [inputValue, setInputValue] = useState('')

  // Custom styles for the dropdown.
  const customStyle = {
    control: () => ({
      border: '1px solid #3a8ae8',
      borderRadius: 3,
      display: 'flex',
      padding: 1,
      marginTop: 1,
    }),
  }

  /**
   * Function to load options for the dropdown.
   * It fetches the crisis keywords from the API and maps them to the format required by the dropdown.
   *
   * @param {Function} loadedOptions - The function to call with the loaded options.
   * @param {string} inputValue - The current input value of the dropdown.
   * @param {Array<Option>} newOptions - The new options to add to the dropdown.
   * @returns {Promise<Array<Option>>} The new options to add to the dropdown.
   */
  const loadOptions = async (
    loadedOptions: (options: OptionsOrGroups<Option, GroupBase<Option>>) => void,
    inputValue: string,
    newOptions: Array<Option> = [],
  ): Promise<Array<Option>> => {
    const abortController = new AbortController()

    const response = await listCrisisKeywords(
      {
        name: inputValue,
        offset: loadedOptions.length,
      },
      abortController,
    )

    newOptions = (response as { data: { results: Array<{ name: string }> } }).data.results.map((option) => ({
      id: option.name,
      name: option.name,
    }))

    return newOptions
  }

  /**
   * Function to fetch options for the dropdown.
   * It calls the loadOptions function and returns the new options.
   *
   * @param {string} inputValue - The current input value of the dropdown.
   * @param {Function} loadedOptions - The function to call with the loaded options.
   * @returns {Promise<Array<Option>>} The new options to add to the dropdown.
   */
  const fetchDropdownOptions = async (
    inputValue: string,
    loadedOptions: (options: OptionsOrGroups<Option, GroupBase<Option>>) => void,
  ): Promise<Array<Option>> => {
    return await loadOptions(loadedOptions, inputValue, [])
  }

  /**
   * Function to handle input changes in the dropdown.
   * It updates the inputValue state variable when the input value of the dropdown changes.
   *
   * @param {string} inputValue - The new input value of the dropdown.
   * @param {Object} action - The action that caused the input change.
   */
  const onInputChange = (inputValue: string, { action }: { action: string }) => {
    if (action === 'input-change') {
      setInputValue(inputValue)
    }
  }

  /**
   * Function to handle updates and creations in the dropdown.
   * It updates the selected value in the form and creates new crisis keywords if necessary.
   *
   * @param {Option | null} selection - The selected option in the dropdown.
   */
  const onUpdateWithCreate = (
    selection: Option | null,
  ) => {
    if (selection) {
      if (selection.__isNew__) {
        const formData = { name: selection.name }
        createNewCrisisKeyword(formData).then(created => {
          const newId = created.data.id
          if (newId) {
            setValue('crisis_keywords', [
              ...getValues('crisis_keywords'),
              { id: newId, name: selection.name },
            ])
            setInputValue('')
          }
        })
      } else {
        setValue('crisis_keywords', selection)
      }
    }
  }

  /**
   * Function to map selected values to the format required by the dropdown.
   *
   * @param {Array<string> | Array<Option>} selectedValues - The selected values in the form.
   * @returns {Array<Option>} The selected values in the format required by the dropdown.
   */
  const mapValuesToReactSelectOptions = (
    selectedValues: Array<string> | Array<Option>,
  ): Array<Option> => {
    return selectedValues.map((val) =>
      (typeof val === 'string'
        ? { id: val, name: val }
        : val),
    )
  }

  // Render the SPUDCrisisKeywordsAutocomplete component.
  return (
    <div>
      <label htmlFor={'crisis_keywords_autocomplete'}>
        <Title level={4}>{title}</Title>
      </label>
      <div>
        <Controller
          name='crisis_keywords'
          control={control}
          rules={{ required: false }}
          render={({ field }) =>
            <AsyncCreatableSelect
              styles={customStyle}
              id='crisis_keywords_autocomplete'
              className='spud-autocomplete'
              classNamePrefix='spud-autocomplete'
              loadOptions={fetchDropdownOptions}
              debounceTimeout={1000}
              isMulti={true}
              defaultOptions
              isCreatable={true}
              value={field.value ? mapValuesToReactSelectOptions(field.value) : []}
              getOptionLabel={(option: Option) => option.name }
              getOptionValue={(option: Option) => !option?.id ? '' : option.id.toString()}
              isClearable={true}
              getNewOptionData={(inputValue: string) => ({
                id: null,
                name: `Create new crisis keyword '${inputValue}'`,
                __isNew__: true,
              })}
              /* Ignoring the multiValue and action meta Type due to a limitation of creatable async */
              /* select to create new options when isMulti is enabled as of 21/06/2024 */
              /* There is no github ticket for this issue. It was found during development */
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              // @ts-ignore
              onChange={onUpdateWithCreate}
              onCreateOption={(inputValue: string) => {
                onUpdateWithCreate({ id: null, name: inputValue, __isNew__: true })
              }}
              formatCreateLabel={ (inputValue: string) => (
                `Create ${inputValue}`
              ) }
              inputValue={inputValue}
              onInputChange={onInputChange}
              placeholder='Find or create a crisis keyword'
              components={{
                Input,
              }}
              onBlur={() => setInputValue('')}
            />
          }
        />
      </div>
    </div>
  )
}

export default SPUDCrisisKeywordsAutocomplete
