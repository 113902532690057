import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Title, Row, Col, Button, Text, Alert, Badge } from '@ix/ix-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFax,
  faLevelUpAlt,
  faMobile,
  faPhone,
  faTty,
  faExclamation,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { isValidServiceProviderPhoneNumber, SPUDInputField } from '../../helpers/record'
import styled from 'styled-components'
import { AngledArrowContainer } from './AngledArrowContainer'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import { BulkServiceProviderUpdateType } from '../../services/spud.service'
import BulkUpdateRecordChangeReview from '../forms/BulkUpdateRecordChangeReview'
import { bulkUpdateRecordChangeReviewType } from '../../externalPages/BulkAutoUpdate/BulkAutoUpdateService'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DroppableProvided,
  DraggableProvided,
} from 'react-beautiful-dnd'
import SPUDMultiEntryFieldSiteDetails from './SPUDMultiEntryFieldSiteDetails'
import TrashIcon from '../TrashIcon'
import ConfidentialToggle from '../ConfidentialToggle'

export type ContactType = {
  comment?: string,
  confidential: boolean
  phone_type: 'phone'|'freecall'|'mobile'|'email'|'fax'|'tty',
  number: string
}

export type SPUDContactProps = {
  title: string,
  recordType: string,
  siteValues: SPUDSiteRecordDetails,
  highlight?: boolean,
  disabled: boolean,
  bulkUpdateChanges: BulkServiceProviderUpdateType,
  loadingForm: boolean,
  resetBulkUpdateRecordChanges?: boolean,
  setResetBulkUpdateRecordChanges?: Dispatch<SetStateAction<boolean>>,
}

type ContactFieldTypes = {
  phone_type: 'phone'|'freecall'|'mobile'|'email'|'fax'|'tty',
  icon: IconDefinition,
  label: string,
  comment: boolean,
}

const CONTACT_TYPES: Array<ContactFieldTypes> = [
  { phone_type: 'phone', icon: faPhone, label: 'Telephone', comment: true },
  { phone_type: 'freecall', icon: faPhone, label: 'Free Call', comment: false },
  { phone_type: 'mobile', icon: faMobile, label: 'Mobile', comment: true },
  { phone_type: 'fax', icon: faFax, label: 'Fax', comment: false },
  { phone_type: 'tty', icon: faTty, label: 'TTY', comment: false },
]

const SPUDContactCard = styled.div`
  width: 100%;
  border-radius: 5px;
  margin: 10px 0;
  box-shadow: 0 0 6px 3px #eaeaea;
  padding: 1em;
`

const ContactCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const InputWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px; /* Space between inputs */
`

function SPUDContact ({
  title,
  siteValues,
  recordType,
  highlight,
  disabled,
  bulkUpdateChanges,
  loadingForm,
  resetBulkUpdateRecordChanges,
  setResetBulkUpdateRecordChanges,
}: SPUDContactProps): React.ReactElement {
  const { control, getValues, setValue, formState: { errors } } = useFormContext()
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'phones',
  })

  const [siteContacts, setSiteContacts] = useState<Array<ContactType>>([])
  const [originalContacts, setOriginalContacts] = useState<Array<ContactType>>([])
  const [originalContactsLoaded, setOriginalContactsLoaded] = useState(false)

  let disableAutoFocus = true

  const formErrors = errors as {
    phones?: Array<{
      number: {
        message: string
      }
    }>
  }

  useEffect(() => {
    if (recordType === 'service' && siteValues?.phones) {
      setSiteContacts(siteValues?.phones)
    } else {
      disableAutoFocus = false
      setSiteContacts([])
    }
  }, [siteValues?.phones, recordType])

  useEffect(() => {
    if (!loadingForm) {
      if (!originalContactsLoaded) {
        setOriginalContacts(getValues('phones'))
        setOriginalContactsLoaded(true)
      }
    }
  }, [loadingForm])

  const RequiredBadge = () => {
    if (!getValues('phones.0.number') && highlight) {
      return <Badge danger content={<FontAwesomeIcon icon={faExclamation as IconProp}/>}/>
    }
    return null
  }

  const showComment = (contact: string): boolean => {
    return CONTACT_TYPES.find(type => type.phone_type === contact)?.comment || false
  }

  const formatContactLabel = (contact: string) => {
    if (contact) {
      if (contact === 'tty') {
        return 'TTY'
      } else if (contact === 'freecall') {
        return 'Free Call'
      } else {
        return contact.charAt(0).toUpperCase() + contact.slice(1)
      }
    }
    return ''
  }

  const acceptChanges = (key: string, value: unknown) => {
    setValue(key, value)
  }

  const handleDrag = (result: DropResult) => {
    const { source, destination } = result

    if (destination) {
      move(source.index, destination.index)
    }
  }

  return (
    <div>
      <Row>
        <Col direction='row'>
          <Title level={4}>{title}</Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row align='center'>
            <Title level={5} marginTop='0'>Contact:</Title>
            <div style={{ margin: '0 10px', flexWrap: 'wrap' }}>
              {CONTACT_TYPES.map(contactType => (
                <Button
                  key={contactType.label}
                  id={contactType.label}
                  onClick={() => {
                    append({
                      confidential: false,
                      comment: '',
                      phone_type: contactType.phone_type,
                      number: '',
                    }, {
                      shouldFocus: false,
                    })
                  }}
                  disabled={disabled}
                  style={{ marginBottom: '10px' }} /* Added margin-bottom for spacing */
                >
                  <FontAwesomeIcon icon={contactType.icon as IconProp}/>
                  <Text margin='0 2em 0 4px'>{contactType.label}</Text>
                  {contactType.phone_type === 'phone' && <RequiredBadge />}
                </Button>
              ))}
            </div>
          </Row>
        </Col>
      </Row>
      {recordType === 'service' && (
        <Row>
          <Col>
            <SPUDMultiEntryFieldSiteDetails
              fieldType='Contact'
              items={siteContacts as Array<ContactType>}
              dataStructure={{
                number: {
                  dataType: 'string',
                  primary: true,
                  humanReadableLabel: 'number',
                },
                phone_type: {
                  dataType: 'string',
                  primary: false,
                  humanReadableLabel: 'phone type',
                },
                comment: {
                  dataType: 'string',
                  primary: false,
                  humanReadableLabel: 'comment',
                },
              }}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Title level={5}>
            Drag card to re-order. The first card will always be the primary number.
          </Title>
        </Col>
      </Row>
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="test-items">
          {(provided: DroppableProvided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {fields.map((field, index) => (
                <Draggable
                  key={`test[${index}]`}
                  draggableId={`item-${index}`}
                  index={index}
                >
                  {(provided: DraggableProvided) => (
                    <Row
                      key={field.id}
                      align='center'
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Col direction='row'>
                        <AngledArrowContainer>
                          <FontAwesomeIcon size='2x' rotation={90} icon={faLevelUpAlt as IconProp} color='#A3A3A3'/>
                        </AngledArrowContainer>
                        <SPUDContactCard>
                          {index === 0 && (
                            <Title level={2} marginTop="1.1em">Primary number</Title>
                          )}
                          <ContactCardHeader style={{ position: 'absolute', right: '1em', top: '0.6em' }}>
                            <TrashIcon onClick={() => remove(index)} active={!disabled} />
                          </ContactCardHeader>
                          <Row>
                            <Col>
                              <div style={{ display: 'flex', flexWrap: 'wrap' }}> {/* Added flexWrap */}
                                <Title level={4}>
                                  {formatContactLabel(getValues(`phones.${index}.phone_type`))}
                                </Title>
                                <Controller
                                  name={`phones.${index}.confidential`}
                                  control={control}
                                  render={({ field }) => (
                                    <ConfidentialToggle
                                      value={field.value || false}
                                      onChange={(checked) => {
                                        field.onChange(checked)
                                      }}
                                      disabled={disabled}
                                      style={{ marginTop: '-2px' }}
                                    />
                                  )}
                                />
                              </div>
                              <InputWrapper>
                                <Controller
                                  name={`phones.${index}.number`}
                                  control={control}
                                  rules={{ validate: isValidServiceProviderPhoneNumber }}
                                  render={({ field }) => (
                                    <SPUDInputField
                                      style={{ flex: '1 1 80%', marginRight: '20px' }}
                                      id='number'
                                      placeholder="Number"
                                      type='text'
                                      highlight={highlight && (index === 0 && !getValues('phones.0.number'))}
                                      fullwidth
                                      autoFocus={!disableAutoFocus}
                                      maxLength={16}
                                      {...field}
                                    />

                                  )}
                                />
                                {showComment(getValues(`phones.${index}.phone_type`)) && (
                                  <Controller
                                    name={`phones.${index}.comment`}
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                      <SPUDInputField
                                        {...field}
                                        placeholder="Extra notes"
                                        style={{ flex: '1 1 48%' }}
                                      />
                                    )}
                                  />
                                )}
                              </InputWrapper>
                              {formErrors?.phones?.[index]?.number && (
                                <Alert type="error" aria-label={getValues(`phones.${index}.phone_type`)}>
                                  {formErrors.phones[index].number.message}
                                </Alert>
                              )}
                            </Col>
                          </Row>
                        </SPUDContactCard>
                      </Col>
                    </Row>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {bulkUpdateChanges && (
        <Row>
          <Col>
            <BulkUpdateRecordChangeReview
              bulkUpdateChanges={bulkUpdateChanges}
              setValue={acceptChanges}
              fieldKey='phones'
              fieldLabel={title}
              currentFieldValue={originalContacts as bulkUpdateRecordChangeReviewType}
              resetBulkUpdateRecordChanges={resetBulkUpdateRecordChanges}
              setResetBulkUpdateRecordChanges={setResetBulkUpdateRecordChanges}
            />
          </Col>
        </Row>
      )}
    </div>
  )
}

export default SPUDContact
