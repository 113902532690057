import React, { useEffect, useState } from 'react'

import { Button, Col, Row } from '@ix/ix-ui'

import { AdvancedFilterType, QuickFilterPresetType } from './RecordFilter.service'
import styled from 'styled-components'

type FilterPresetsProps = {
  presets: Array<QuickFilterPresetType>,
  activeFilters: {[x: string]: AdvancedFilterType},
  onPresetChange: (preset: AdvancedFilterType) => void,
}

const Seperator = styled.div`
    padding: 1rem;
    margin-left: -0.5em;
    font-weight: bolder;
    font-size: 2em;
    color: ${props => props.theme.colors.accentColor};
`

function QuickFilterPresets ({
  presets,
  activeFilters,
  onPresetChange,
} : FilterPresetsProps) {
  const [activeQuickPreset, setActiveQuickPreset] = useState<{[x: string]: boolean}>({})

  useEffect(() => {
    if (presets) {
      let presetObj = {}

      presets.forEach(preset => {
        const currentActivePreset = activeFilters?.[preset.name + '-0']
        if (currentActivePreset && currentActivePreset.value) {
          presetObj = { ...presetObj, [preset.name]: true, default_order: preset.default_order }
        } else {
          presetObj = { ...presetObj, [preset.name]: false, default_order: preset.default_order }
        }
      })
      setActiveQuickPreset(presetObj)
    }
  }, [presets, activeFilters])

  return (
    <div>
      {presets.length > 0 &&
        <Row>
          <Col>
            <Row>
              {presets.filter(preset => preset.group === 'status')
                .map((button) => (
                  <Button
                    key={button.name}
                    active={activeQuickPreset[button.name]}
                    onClick={() => {
                      const newValue = !activeQuickPreset[button.name]
                      setActiveQuickPreset({ ...activeQuickPreset, [button.name]: newValue })
                      onPresetChange({
                        type: 'toggle',
                        label: button.label,
                        name: button.name,
                        value: newValue,
                        defaultValue: false,
                        default_order: button.default_order,
                        role: 'any',
                      })
                    }}
                  >
                    {button.label}
                  </Button>
                ))}
              {presets.filter(preset => preset.group === 'allocation').length
                ? <Seperator>|</Seperator>
                : null
              }
              {presets.filter(preset => preset.group === 'allocation')
                .map((button) => (
                  <Button
                    key={button.name}
                    active={activeQuickPreset[button.name]}
                    onClick={() => {
                      const newValue = !activeQuickPreset[button.name]
                      setActiveQuickPreset({ ...activeQuickPreset, [button.name]: newValue })
                      onPresetChange({
                        type: 'toggle',
                        label: button.label,
                        name: button.name,
                        value: newValue,
                        defaultValue: false,
                        default_order: button.default_order,
                        role: 'any',
                      })
                    }}
                  >
                    {button.label}
                  </Button>
                ))}
            </Row>
          </Col>
        </Row>
      }
    </div>
  )
}

export default QuickFilterPresets
