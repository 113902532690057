import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { fetchGeocodeFromAddress } from '../../services/googleAPI.service'
import {
  Alert,
  Row,
  Col,
  Title,
  Button,
  Text,
} from '@ix/ix-ui'
import { SPUDInputField } from '../../helpers/record'
import { Location } from './SPUDLocation'
import { Controller, useFormContext } from 'react-hook-form'

type SPUDGeocodeProps = {
  callback: (latLong: {
    lat: string,
    lon: string,
  }) => void,
  address: Location,
  disabled: boolean
  draftValidation: boolean
  locationValueChanged: boolean
  setLocationValueChanged: Dispatch<SetStateAction<boolean>>
}

function SPUDGeocode (
  {
    callback,
    address,
    disabled,
    draftValidation,
    locationValueChanged,
    setLocationValueChanged,
  }: SPUDGeocodeProps): React.ReactElement {
  const [geoCode, setGeoCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setLocalError] = useState('')
  const [updatedGeoCode, setUpdatedGeoCode] = useState('null')
  const [invalidGeoCode, setInvalidGeoCode] = useState(false)

  const { control, clearErrors } = useFormContext()

  useEffect(() => {
    if (address?.geo_point?.lat && address?.geo_point?.lon) {
      setGeoCode(`${address.geo_point.lat},${address.geo_point.lon}`)
    } else {
      setGeoCode('')
    }
  }, [address])

  useEffect(() => {
    if (locationValueChanged && (address?.geo_point?.lat && address?.geo_point?.lon)) {
      const defaultLatLng = { lat: '', lon: '' }
      callback?.(defaultLatLng)
      setGeoCode('')
    }
  }, [locationValueChanged])

  const formatAddress = (): string => {
    return `${address?.building_name || ''} ` +
    `${address?.floor_level || ''} ` +
    `${address?.street_suffix || ''} ` +
    `${address?.street_number || ''} ` +
    `${address?.street_name || ''} ` +
    `${address?.street_type || ''} ` +
    `${address?.suburb || ''} ` +
    `${address?.state || ''} ` +
    `${address?.postcode || ''} `
  }

  const fetchLatLng = async () => {
    setLoading(true)
    try {
      const latlng = await fetchGeocodeFromAddress(formatAddress())
      setLoading(false)
      const latlngString = `${latlng.lat},${latlng.lon}`
      setGeoCode(latlngString)
      setUpdatedGeoCode(latlngString)
      clearErrors('location.geo_point')
      callback?.(latlng)
    } catch (error) {
      if (error instanceof Error) setLocalError(error?.message)
    }
  }

  const validateGeoCode = (updatedGeoCode: string): boolean => {
    const pattern = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
    if ((updatedGeoCode && updatedGeoCode.match(pattern)) || !updatedGeoCode) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (updatedGeoCode && !invalidGeoCode) {
      // When a valid geocode is entered manually, convert geocode to geo_point
      const geoCodeArray = geoCode.split(',')
      const latlng = {
        lat: geoCodeArray[0],
        lon: geoCodeArray[1],
      }
      callback?.(latlng)
    } else {
      // When geocode is empty or invalid, set geo_point as empty
      const defaultLatLng = { lat: '', lon: '' }
      callback?.(defaultLatLng)
    }
  }, [updatedGeoCode])

  const canFetchGeoCode = !!(address?.street_number && address?.street_name && address?.street_type && address?.suburb)

  return (
    <div>
      <Row align='flex-end'>
        <Col flex={10}>
          <Controller
            name='location.geo_point'
            control={control}
            defaultValue={null}
            rules={{
              validate: (value) => {
                if (typeof value === 'object' && value?.lat && value?.lon) {
                  return true
                }
                return draftValidation
              },
            }}
            render={({ field }) =>
              <SPUDInputField
                id='geocode'
                label={<Title level={4}>Geocode</Title>}
                fullwidth
                hideAsterisk={true}
                value={geoCode}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLocationValueChanged(true)
                  setGeoCode(event.target.value)
                  clearErrors('location.geo_point')
                }}
                onBlur={() => {
                  setUpdatedGeoCode(geoCode)
                  setInvalidGeoCode(!validateGeoCode(geoCode))
                }}
                name={field.name}
                ref={field.ref}
              />
            }
          />
          <Text>{error}</Text>
        </Col>
        <Col style={{ padding: 0 }} flex={2}>
          <Button
            style={{ margin: 0, height: '40px', width: '100%' }}
            active={canFetchGeoCode && !disabled}
            disabled={!canFetchGeoCode}
            loading={loading}
            onClick={() => {
              fetchLatLng()
              setInvalidGeoCode(false)
            }}
          >
            Get Geocode
          </Button>
        </Col>
      </Row>
      <Row>
        <Col flex={12}>
          {invalidGeoCode && <Alert type='error'>
            That does not look like a valid geocode, please try again.
          </Alert>}
        </Col>
      </Row>
    </div>
  )
}

export default SPUDGeocode
