import toast from 'react-hot-toast'
import SPUDToastNotification from '../components/General/SPUDToastNotification'
import React from 'react'

export const notifyUserAboutFailedRequest = (message = 'Failed to fetch records') => {
  toast.custom(
    <SPUDToastNotification
      title="Error"
      message={message}
      error
    />,
    {
      position: 'bottom-center',
    },
  )
}
