import { isDate, parseISO, addMinutes } from 'date-fns'
import { formatInTimeZone as tsFormat } from 'date-fns-tz'

export const formatDatetimeToLocal = (
  datetime: string | null | undefined | Date,
  applyTSOffset = false,
): string | null | undefined => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  if (datetime) {
    try {
      if (typeof datetime === 'string') {
        // Apply the timezone offset
        if (applyTSOffset) {
          let parsedDate = parseISO(datetime)
          const date = new Date()
          const offset = date.getTimezoneOffset()
          parsedDate = addMinutes(parsedDate, offset)
          datetime = tsFormat(parsedDate, timeZone, 'dd/MM/yyyy hh:mm aaa')
        } else {
          datetime = tsFormat(parseISO(datetime), timeZone, 'dd/MM/yyyy hh:mm aaa')
        }
      } else {
        datetime = tsFormat(new Date(datetime), timeZone, 'dd/MM/yyyy hh:mm aaa')
      }
    } catch (e) {
      // if the datetime is a string but not in the iso format it will fail the parsing
      // So it will need to converted into a new date
      const newDate = new Date(datetime)
      if (isDate(newDate)) {
        return tsFormat(new Date(datetime), timeZone, 'dd/MM/yyyy hh:mm aaa')
      }
      return datetime.toString()
    }
  }
  return datetime
}

export const formatDatetimeToLocalShort = (
  datetime: string | null | undefined | Date,
  applyTSOffset = false,
): string | null | undefined => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  if (datetime) {
    try {
      if (typeof datetime === 'string') {
        // Apply the timezone offset
        if (applyTSOffset) {
          let parsedDate = parseISO(datetime)
          const date = new Date()
          const offset = date.getTimezoneOffset()
          parsedDate = addMinutes(parsedDate, offset)
          datetime = tsFormat(parsedDate, timeZone, 'dd/MM/yyyy')
        } else {
          datetime = tsFormat(parseISO(datetime), timeZone, 'dd/MM/yyyy')
        }
      } else {
        datetime = tsFormat(new Date(datetime), timeZone, 'dd/MM/yyyy')
      }
    } catch (e) {
      // if the datetime is a string but not in the iso format it will fail the parsing
      // So it will need to converted into a new date
      const newDate = new Date(datetime)
      if (isDate(newDate)) {
        return tsFormat(new Date(datetime), timeZone, 'dd/MM/yyyy')
      }
      return datetime.toString()
    }
  }
  return datetime
}
