import * as _ from 'lodash'
import React from 'react'
import { BulkAutoUpdateRecord } from './BulkUpdateContext.type'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import { SPUDServiceRecordDetails } from '../../../@types/Service.type'
import { SPUDOrganisationRecordDetails } from '../../../@types/Organisation.type'
import { OpenHoursType } from '../../components/General/OpeningHours/SPUDOpeningHours'
import { convert24hrTo12HrFormat } from '../../components/General/OpeningHours/OpeningHours.service'

export type BulkUpdateFlowStateType = 'welcome'|'site'|'service'|'review'|'final'

export const OMIT_FIELDS_FOR_CHECK = [
  'bulk_update_delete',
  'bulk_update_confirm_no_changes',
  'bulk_update_confirm_changes',
  'location.geo_point',
  'last_updated_date',
  'datasets',
  'indigenous_classification',
  'is_ndis_approved',
  'is_statewide',
  'is_free_or_low_cost',
  'is_bulk_billing',
  'accepts_healthcare_cards',
  'accreditation',
  'linked_external_records',
  'also_known_as',
  'organisation_data',
  'additional_attributes',
  'attachments',
  'id',
]

export type FieldArray = Array<
  { phone_type: string, number: string, comment: string } &
  { url: string, type: string } &
  { open: string, close: string, day: string, note: string } &
  { email: string, comment: string} &
  { address_line_1: string, address_line_2: string, suburb: string,
    state: string, postcode: string, confidential: boolean} &
  { name: string, id: string }> &
  string

export type bulkUpdateRecordChangeReviewType = string & Array<unknown> & {[x: string]: unknown} & {
        building_name: string,
        confidential: boolean | string,
        flat_unit: string,
        floor_level: string,
        postcode: string,
        state: string,
        street_number: string,
        street_name: string,
        street_suffix: string,
        street_type: string,
        suburb: string,
        geo_point: {
          lat: string,
          lon: string
        },
      }

export const hasContentInNewField = (object: {[x: string]: unknown }, base: {[x: string]: unknown }): string[] => {
  /*
    Will return any newly created fields that have a value set

    These new fields are fields that are added to the form in SPUD but
    may not exist in the data stored in the SPUD database as they may've been added
    prior to the field change.
   */
  const originalKeys = Object.keys(object)
  const currentKeys = Object.keys(base)
  const omitFields = [...OMIT_FIELDS_FOR_CHECK]
  return _.remove(_.difference(currentKeys, originalKeys), (item) => !omitFields.includes(item) && base[item])
}

export const objectDifference = (
  object: {[x: string]: unknown },
  base: {[x: string]: unknown },
): {[x: string]: unknown } => {
  const objectChanges = (object: {[x: string]: unknown }, base: {[x: string]: unknown }): {[x: string]: unknown } => {
    const contentInNewField = hasContentInNewField(object, base)
    if (contentInNewField && contentInNewField.length > 0) {
      return contentInNewField.reduce((obj: {[x: string]: unknown}, key) => {
        obj[key] = ''
        return obj
      }, {})
    }
    return _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key]))
          ? objectChanges(value as {[x: string]: unknown }, base[key] as {[x: string]: unknown })
          : value
      }
    })
  }
  return objectChanges(object, base)
}

export const appendNewFieldsToOriginalForProcessing = (
  record: BulkAutoUpdateRecord<SPUDSiteRecordDetails | SPUDServiceRecordDetails | SPUDOrganisationRecordDetails>,
) => {
  /*
    Appends any new fields to the existing original data object
   */
  const newFields = hasContentInNewField(
    record.originalData?.update?.data as {[x: string]: unknown } || record.originalData,
    record.currentData.update.data as {[x: string]: unknown },
  )
  const newFieldObject = newFields.reduce((obj: {[x: string]: unknown}, key) => {
    obj[key] = ''
    return obj
  }, {})
  return {
    ...record.originalData?.update?.data || record.originalData,
    ...newFieldObject,
  }
}

export const changes = (
  record: BulkAutoUpdateRecord<SPUDSiteRecordDetails | SPUDServiceRecordDetails | SPUDOrganisationRecordDetails>,
  additionalOmissions: Array<string> = [],
): Array<keyof SPUDSiteRecordDetails & keyof SPUDServiceRecordDetails & keyof Location> => {
  const currentData: {[x: string]: unknown} = _.omit(
    record.currentData.update.data,
    [...OMIT_FIELDS_FOR_CHECK, ...additionalOmissions],
  )
  const originalData: {[x: string]: unknown} = _.omit(
    record.originalData?.update?.data || record.originalData,
    [...OMIT_FIELDS_FOR_CHECK, ...additionalOmissions],
  )

  return _.reduce(currentData, (
    result: Array<keyof SPUDSiteRecordDetails & keyof SPUDServiceRecordDetails & keyof Location>,
    value,
    key: string,
  ) => {
    return _.isEqual(value, originalData[key])
      ? result
      : result.concat(key as keyof SPUDSiteRecordDetails & keyof SPUDServiceRecordDetails & keyof Location)
  }, [])
}

export const extractComments = (
  record: BulkAutoUpdateRecord<SPUDSiteRecordDetails | SPUDServiceRecordDetails | SPUDOrganisationRecordDetails>,
  contactInfo: { contact_name: string; contact_number: string; contact_email: string }) => {
  return {
    id: record.currentData?.id || 0,
    data: {
      changes: {},
      contactInfo: contactInfo,
      comment: record.currentData.update?.data?.bulk_update_comment,
      deleted: !!record.currentData.update?.data?.bulk_update_delete,
    },
    recordType: record.currentData.record_type,
  }
}

export const flattenLocationObj = (locationObj: {
  building_name: string,
  confidential: boolean,
  flat_unit: string,
  floor_level: string,
  postcode: string,
  state: string,
  street_name: string,
  street_number: string,
  street_suffix: string,
  street_type: string,
  suburb: string,
}) => {
  return `${locationObj.flat_unit || ''} ` +
        `${locationObj.floor_level || ''} ` +
        `${locationObj.building_name || ''} ` +
        `${locationObj.street_number || ''} ` +
        `${locationObj.street_name || ''} ` +
        `${locationObj.street_type || ''} ` +
        `${locationObj.street_suffix || ''} ` +
        `${locationObj.suburb || ''} ` +
        `${locationObj.state || ''} ` +
        `${locationObj.postcode || ''} ` +
        `${(locationObj.confidential && '(Is confidential)') || ''} `
}

export const flattenNewLocationObj = (locationObj: {
  building_name: string,
  confidential: boolean,
  flat_unit: string,
  floor_level: string,
  postcode: string,
  state: string,
  street_name: string,
  street_number: string,
  street_suffix: string,
  street_type: string,
  suburb: string,
}, oldValues?: {
  building_name: string,
  confidential: boolean,
  flat_unit: string,
  floor_level: string,
  postcode: string,
  state: string,
  street_name: string,
  street_number: string,
  street_suffix: string,
  street_type: string,
  suburb: string,
}): Array<string | React.ReactElement> => {
  /* eslint-disable max-len */
  return [
    locationObj.flat_unit !== oldValues?.flat_unit ? <mark>{locationObj.flat_unit}</mark> : oldValues?.flat_unit || '',
    locationObj.floor_level !== oldValues?.floor_level ? <mark>{locationObj.floor_level}</mark> : oldValues?.floor_level || '',
    locationObj.building_name !== oldValues?.building_name ? <mark>{locationObj.building_name}</mark> : oldValues?.building_name || '',
    locationObj.street_number !== oldValues?.street_number ? <mark>{locationObj.street_number}</mark> : oldValues?.street_number || '',
    locationObj.street_name !== oldValues?.street_name ? <mark>{locationObj.street_name}</mark> : oldValues?.street_name || '',
    locationObj.street_type !== oldValues?.street_type ? <mark>{locationObj.street_type}</mark> : oldValues?.street_type || '',
    locationObj.street_suffix !== oldValues?.street_suffix ? <mark>{locationObj.street_suffix}</mark> : oldValues?.street_suffix || '',
    locationObj.suburb !== oldValues?.suburb ? <mark>{locationObj.suburb}</mark> : oldValues?.suburb || '',
    locationObj.state !== oldValues?.state ? <mark>{locationObj.state}</mark> : oldValues?.state || '',
    locationObj.postcode !== oldValues?.postcode ? <mark>{locationObj.postcode}</mark> : oldValues?.postcode || '',
    (locationObj.confidential && '(Is confidential)') || '',
  ]
  /* eslint-enable max-len */
}

export const flattenOpeningHoursObj = (openingHoursArr: Array<OpenHoursType>): string => {
  return openingHoursArr?.map((openingHours) => (
    `${openingHours.day || ''} ` +
    `${convert24hrTo12HrFormat(openingHours.open) || ''} - ` +
    `${convert24hrTo12HrFormat(openingHours.close) || ''} ` +
    `${openingHours.note || ''} `
  )).join(', ') || ''
}

export const flattenPostalAddress = (arrayValue:{
  address_line_1: string,
  address_line_2: string,
  confidential: boolean,
  postcode: string,
  state: string,
  suburb: string,
}) => {
  return `${arrayValue.address_line_1 || ''}` +
        `${arrayValue.address_line_1 && !!(arrayValue.address_line_2 || arrayValue.suburb) ? ', ' : ''}` +
        `${arrayValue.address_line_2 || ''}` +
        `${arrayValue.address_line_2 && arrayValue.suburb ? ', ' : ''}` +
        `${arrayValue.suburb || ''} ` +
        `${arrayValue.state || ''} ` +
        `${arrayValue.postcode || ''} ` +
        `${arrayValue.confidential ? '(Is confidential)' : ''}`
}

export const markDifferences = (
  original: Array<string>,
  updated: Array<string>,
  returnAsString: boolean): string | Array<string | React.ReactElement> => {
  const markedWords = updated.map((word, index) => {
    if (original[index] !== word && !returnAsString) {
      return <mark key={`${word}_${index}`}>{word}</mark>
    } else if (original[index] !== word && returnAsString) {
      return `<mark>${word}</mark>`
    }
    return word
  })

  if (updated.length === 1 && updated[0] === '') {
    return [
      <span key={0} style={{ color: 'red', textDecoration: 'line-through' }}>{original.join(' ')}</span>,
    ]
  }

  return returnAsString ? markedWords.join('') : markedWords
}

export const flattenArray = (
  arrayOfValues: FieldArray,
  originalArrayOfValues: FieldArray | [],
  field: string,
  showFieldName = true,
): {
  element: React.ReactElement | null,
  hasDifference?: boolean,
} => {
  let oldValues = originalArrayOfValues?.flatMap(
    arrayValue =>
      arrayValue?.id || arrayValue,
  )
  let newValues = arrayOfValues.flatMap(
    arrayValue =>
      arrayValue?.id || arrayValue,
  )
  if (field === 'phones') {
    /* eslint-disable max-len */
    oldValues = originalArrayOfValues.flatMap(arrayValue => `
      ${arrayValue?.phone_type}: ${arrayValue.number}${arrayValue.comment ? `- ${arrayValue.comment} ${arrayValue.confidential && '(is confidential)'}` : ''}
    `)
    newValues = arrayOfValues.flatMap(arrayValue => `
      ${arrayValue?.phone_type}: ${arrayValue.number}${arrayValue.comment ? `- ${arrayValue.comment} ${arrayValue.confidential && '(is confidential)'}` : ''}
    `)
    /* eslint-enable max-len */
  } else if (field === 'emails') {
    newValues = arrayOfValues.flatMap(arrayValue => (
      `${arrayValue?.email} ${arrayValue.comment ? `- ${arrayValue.comment}` : ''}`),
    )
    oldValues = originalArrayOfValues
      ? originalArrayOfValues.flatMap(arrayValue => (
        `${arrayValue?.email} ${arrayValue.comment ? `- ${arrayValue.comment}` : ''}`),
      )
      : []
  } else if (field === 'social_media') {
    newValues = arrayOfValues.flatMap(arrayValue => (
      `${arrayValue?.type} ${arrayValue.url}`),
    )
    oldValues = originalArrayOfValues?.flatMap(arrayValue => (
      `${arrayValue?.type} ${arrayValue.url}`),
    )
  } else if (field === 'accessibility') {
    newValues = arrayOfValues?.flatMap(arrayValue => arrayValue?.name) || []
    oldValues = originalArrayOfValues?.flatMap(arrayValue => arrayValue?.name) || []
  } else if (field === 'postal_addresses') {
    newValues = arrayOfValues?.flatMap(flattenPostalAddress)
    oldValues = originalArrayOfValues
      ? originalArrayOfValues.flatMap(flattenPostalAddress)
      : []
  }
  const difference = _.difference(newValues, oldValues)
  if (!difference.length) {
    return {
      element: <span>{newValues as React.ReactNode}</span>,
      hasDifference: false,
    }
  }
  return {
    element: <>
      <div>
        {showFieldName && <strong>{field}</strong>}
        {' '}
        {oldValues && oldValues.join(' | ')}
        {oldValues.length > 0 && difference.length > 0 && ' | '}
        <mark>{difference.join(' | ')}</mark>
      </div>
    </>,
    hasDifference: difference.length > 0,
  }
}
