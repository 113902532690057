{
  "accreditation": [
    {"id": "Vocational Courses (VETAB)", "name": "Vocational Courses (VETAB)"},
    {"id": "National Community Housing Standards (NCHF)", "name": "National Community Housing Standards (NCHF)"},
    {"id": "Health Care Evaluation and Quality Improvement Program - EQuIP (ACHS)", "name": "Health Care Evaluation and Quality Improvement Program - EQuIP (ACHS)"},
    {"id": "General Practice Accreditation (RACGP )", "name": "General Practice Accreditation (RACGP )"},
    {"id": "Family Day Care Quality Assurance (NCAC)", "name": "Family Day Care Quality Assurance (NCAC)"},
    {"id": "Outside School Hours Care Quality Assurance (NCAC)", "name": "Outside School Hours Care Quality Assurance (NCAC)"},
    {"id": "Long Day Care Quality Improvement and Accreditation System (NCAC)", "name": "Long Day Care Quality Improvement and Accreditation System (NCAC)"},
    {"id": "Aged Care (Aged Care Standards and Accreditation Agency)", "name": "Aged Care (Aged Care Standards and Accreditation Agency)"},
    {"id": "NSW Out-of-Home Care Standards (OCG)", "name": "NSW Out-of-Home Care Standards (OCG)"},
    {"id": "Disability Employment Services Quality Assurance System (FaCS)", "name": "Disability Employment Services Quality Assurance System (FaCS)"},
    {"id": "ISO 9001 Halal Certified Kosher Certified", "name": "ISO 9001 Halal Certified Kosher Certified"}
  ]
}