import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, Col, Row, Title } from '@ix/ix-ui'
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faLevelUpAlt, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { SPUDInputField } from '../../helpers/record'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { AngledArrowContainer } from './AngledArrowContainer'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import { BulkServiceProviderUpdateType } from '../../services/spud.service'
import BulkUpdateRecordChangeReview from '../forms/BulkUpdateRecordChangeReview'
import { bulkUpdateRecordChangeReviewType } from '../../externalPages/BulkAutoUpdate/BulkAutoUpdateService'
import SPUDMultiEntryFieldSiteDetails from './SPUDMultiEntryFieldSiteDetails'
import TrashIcon from '../TrashIcon'

type SocialFieldTypes = {
  type: 'facebook'|'instagram'|'twitter'|'youtube'|'linkedin',
  icon: IconDefinition,
  label: string,
}

export type SocialType = {
  url: string,
  type: 'facebook'|'instagram'|'twitter'|'youtube'|'linkedin',
}

export type SPUDSocialProps = {
  title: string,
  recordType: string,
  siteValues: SPUDSiteRecordDetails,
  disabled: boolean,
  bulkUpdateChanges: BulkServiceProviderUpdateType,
  loadingForm: boolean,
  resetBulkUpdateRecordChanges?: boolean,
  setResetBulkUpdateRecordChanges?: Dispatch<SetStateAction<boolean>>,
}

const SOCIAL_TYPES: Array<SocialFieldTypes> = [
  { type: 'facebook', icon: faFacebookF, label: 'Facebook' },
  { type: 'linkedin', icon: faLinkedinIn, label: 'LinkedIn' },
  { type: 'twitter', icon: faTwitter, label: 'Twitter' },
  { type: 'instagram', icon: faInstagram, label: 'Instagram' },
  { type: 'youtube', icon: faYoutube, label: 'YouTube' },
]

const SPUDSocialCard = styled.div`
  width: 100%;
  border-radius: 5px;
  margin: 10px 0;
  box-shadow: 0 0 6px 3px #eaeaea;
  padding: 1em;
`

const SocialCardHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`

const TitleAndToggleWrapper = styled.div`
  display: flex;
  padding-top: -10px;
`

function SPUDSocials ({
  title,
  siteValues,
  recordType,
  disabled,
  bulkUpdateChanges,
  loadingForm,
  resetBulkUpdateRecordChanges,
  setResetBulkUpdateRecordChanges,
}: SPUDSocialProps): React.ReactElement {
  const { control, getValues, setValue } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'social_media',
  })

  const [siteSocials, setSiteSocials] = useState<Array<{
    url: string,
    type: string,
  }>>([])

  const [originalSocials, setOriginalSocials] = useState<Array<{
    url: string,
    type: string,
  }>>([])
  const [originalSocialsLoaded, setOriginalSocialsLoaded] = useState(false)

  let disableAutoFocus = true

  useEffect(() => {
    if (recordType === 'service' && siteValues?.social_media) {
      setSiteSocials(siteValues?.social_media)
    } else {
      disableAutoFocus = false
      setSiteSocials([])
    }
  }, [siteValues?.social_media?.length, recordType])

  useEffect(() => {
    if (!loadingForm) {
      !originalSocialsLoaded && setOriginalSocials(getValues('social_media'))
      setOriginalSocialsLoaded(true)
    }
  }, [loadingForm])

  const formatSocialLabel = (social: string): string => {
    if (social) {
      if (social === 'linkedin') {
        return 'LinkedIn'
      } else if (social === 'youtube') {
        return 'YouTube'
      } else {
        return social.charAt(0).toUpperCase() + social.slice(1)
      }
    }
    return ''
  }

  const acceptChanges = (key: string, value: unknown) => {
    setValue(key, value)
  }

  return (
    <div>
      <Row>
        <Col direction='row'>
          <Title level={4}>
            {title}
          </Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row align='center'>
            <Title level={5} marginTop='0'>Social:</Title>
            <div style={{ margin: '0 10px' }}>
              {SOCIAL_TYPES.map(socialType => (
                <Button
                  key={socialType.label}
                  onClick={() => {
                    append({
                      url: '',
                      type: socialType.type,
                    }, { shouldFocus: false })
                  }}
                  disabled={disabled}
                >
                  <FontAwesomeIcon icon={socialType.icon as IconProp}/> {socialType.label}
                </Button>
              ))}
            </div>
          </Row>
        </Col>
      </Row>
      {recordType === 'service' && <Row>
        <Col>
          <SPUDMultiEntryFieldSiteDetails
            fieldType='Social Media'
            items={siteSocials as Array<SocialType>}
            dataStructure={{
              type: {
                dataType: 'string',
                primary: true,
                humanReadableLabel: 'social media type',
              },
              url: {
                dataType: 'string',
                primary: false,
                humanReadableLabel: 'url',
              },
            }}
          />
        </Col>
      </Row>}
      {fields.map((field, index) => (
        <Row key={field.id} align='center'>
          <Col direction='row'>
            <AngledArrowContainer>
              <FontAwesomeIcon size='2x' rotation={90} icon={faLevelUpAlt as IconProp} color='#A3A3A3'/>
            </AngledArrowContainer>
            <SPUDSocialCard style={{ paddingTop: '0', marginTop: '0' }}>
              <SocialCardHeader style={{ paddingTop: '0', marginTop: '-10px' }}>
                <TrashIcon style={{ paddingTop: '20px' }} onClick={() => remove(index)} active={!disabled} />
              </SocialCardHeader>
              <Row style={{ paddingTop: '0', marginTop: '0' }}>
                <Col>
                  <TitleAndToggleWrapper>
                    <Title level={4}>
                      {formatSocialLabel(getValues(`social_media.${index}.type`))}
                    </Title>
                  </TitleAndToggleWrapper>
                  <Controller
                    name={`social_media.${index}.url`}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) =>
                      <SPUDInputField
                        id='url'
                        placeholder="Url"
                        type='text'
                        fullwidth
                        autoFocus={!disableAutoFocus}
                        {...field}
                      />
                    }
                  />
                </Col>
              </Row>
            </SPUDSocialCard>
          </Col>
        </Row>
      ))}
      {bulkUpdateChanges && <Row>
        <Col>
          <BulkUpdateRecordChangeReview
            bulkUpdateChanges={bulkUpdateChanges}
            setValue={acceptChanges}
            fieldKey='social_media'
            fieldLabel={title}
            currentFieldValue={originalSocials as bulkUpdateRecordChangeReviewType}
            setResetBulkUpdateRecordChanges={setResetBulkUpdateRecordChanges}
            resetBulkUpdateRecordChanges={resetBulkUpdateRecordChanges}
          />
        </Col>
      </Row>}
    </div>
  )
}

export default SPUDSocials
