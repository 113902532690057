import React from 'react'

function ReviewIcon () {
  return <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.63 124.63">
    <title>Review [Converted]</title>
    <path
      // eslint-disable-next-line max-len
      d="M70.85,8.94a62.21,62.21,0,0,1,0,124.42v-7.13a55.08,55.08,0,0,0,0-110.16Zm0,124.42a62.21,62.21,0,1,1,0-124.42v7.13a55.08,55.08,0,1,0,0,110.16Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      fill='none'
      stroke='#231f20'
      strokeMiterlimit={10}
      strokeWidth='0.22px'
      fillRule='evenodd'
      // eslint-disable-next-line max-len
      d="M70.85,8.94A62.21,62.21,0,1,1,8.64,71.15,62.32,62.32,0,0,1,70.85,8.94Zm0,7.13A55.08,55.08,0,1,1,15.77,71.15,55.22,55.22,0,0,1,70.85,16.07Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      fill='#231f20'
      fillRule='evenodd'
      // eslint-disable-next-line max-len
      d="M70.85,25.79H85.61l17,23v61.42a6.35,6.35,0,0,1-6.34,6.34H70.85v-6.26H96.12a.82.82,0,0,0,.79-.86V49.19c0-.07-12.53-17.14-13-17.14H70.85Zm-25.42,0H70.85v6.26H45.58a.82.82,0,0,0-.79.86v76.46a.82.82,0,0,0,.79.86H70.85v6.26H45.43a6.35,6.35,0,0,1-6.34-6.34v-78A6.35,6.35,0,0,1,45.43,25.79Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      fill='#231f20'
      fillRule='evenodd'
      d="M79.78,31.91V50.06c.94,2.3,2.38,3.53,4.18,3.67l13.46.14-.14-4.32H84.1l-.29-17.64Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      fill='#231f20'
      fillRule='evenodd'
      // eslint-disable-next-line max-len
      d="M51.34,41.42h25.2a.57.57,0,0,1,.58.58v2.3a.53.53,0,0,1-.58.58H51.34a.57.57,0,0,1-.58-.58V42A.62.62,0,0,1,51.34,41.42Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      fill='#231f20'
      fillRule='evenodd'
      // eslint-disable-next-line max-len
      d="M51.7,58.84H89.86c.5,0,.94.29.94.65v2.3c0,.29-.43.58-.94.58H51.7c-.5,0-.94-.29-.94-.58v-2.3C50.76,59.13,51.19,58.84,51.7,58.84Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      fill='#231f20'
      fillRule='evenodd'
      // eslint-disable-next-line max-len
      d="M51.7,76.77H89.86c.5,0,.94.29.94.65v2.23c0,.36-.43.65-.94.65H51.7c-.5,0-.94-.29-.94-.65V77.42C50.76,77.06,51.19,76.77,51.7,76.77Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      fill='#231f20'
      fillRule='evenodd'
      // eslint-disable-next-line max-len
      d="M51.7,94.55H89.86c.5,0,.94.29.94.65v2.23c0,.36-.43.65-.94.65H51.7c-.5,0-.94-.29-.94-.65V95.2C50.76,94.84,51.19,94.55,51.7,94.55Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      fill='#fff'
      fillRule='evenodd'
      // eslint-disable-next-line max-len
      d="M55.15,67.41C59.83,61.94,66.1,54.59,70.78,49c4.68,5.62,11.09,13,15.84,18.58H76.39l.07,22.32c.07,3.74-11.81,4.25-11.81.07V67.62Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      fill='#231f20'
      fillRule='evenodd'
      // eslint-disable-next-line max-len
      d="M58.46,66.33,70.78,51.86,83.23,66.47H75V87.86c0,3.31-8.86,3.67-8.86,0V66.47C63.58,66.4,61.06,66.33,58.46,66.33Z"
      transform="translate(-8.53 -8.84)"
    />
  </svg>
}

export default ReviewIcon
