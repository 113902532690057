import styled from 'styled-components'
import { Button, Textarea } from '@ix/ix-ui'

export const SPUDTextArea = styled(Textarea)`
  resize: vertical;
  height: 100px;
  border: 1px solid #3a8ae8;
  min-height: 100px;
  margin-bottom: 0;
`

export const CommentIconButton = styled(Button)<{textOnly?: boolean}>`
  border: none;
  background: none;
  margin-right: 0;
  :hover {
    background: none;
    text-decoration: ${props => props.textOnly ? 'underline' : 'none'};
  }
  :focus {
    box-shadow: none;
  }
`

export const CommentLinkButton = styled(Button)`
  margin: 2px 5px;
  border: none;
  background: none;
  padding: 0;
  line-height: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  :hover {
    text-decoration: none;
    border: none;
    background: none;
  }
  :focus {
    box-shadow: none;
  }
  svg {
    margin-left: 5px;
  }
  @media screen and (max-width: 1500px) {
    svg {
      margin-left: 2px;
      font-size: 0.9em;
    }
  }
`
