import { transactionService } from '../../services/transactions.service'
import { SPUDRecord } from '../../../@types/SPUDRecord.type'
import { SPUDOrganisationRecordDetails } from '../../../@types/Organisation.type'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import { SPUDServiceRecordDetails } from '../../../@types/Service.type'

export const fetchLinkedRecordTableTitle = (recordType: string | undefined): string => {
  switch (recordType) {
  case 'organisation':
    return 'Sites'
  case 'service':
    return 'Other services under the same site'
  case 'site':
    return 'Services under this site'
  default:
    return 'Sites'
  }
}

export const fetchLinkedRecordTableMethod = <
  T extends SPUDOrganisationRecordDetails | SPUDSiteRecordDetails | SPUDServiceRecordDetails>(
    recordType: string | undefined,
    record: SPUDRecord<T>,
  ): {
  method: typeof transactionService.linkedRecords | null;
  params: ({ recordId: number | null | undefined | string; linkedRecordType: string }) | null
} => {
  switch (recordType) {
  case 'organisation':
    return {
      method: transactionService.linkedRecords,
      params: { linkedRecordType: 'site', recordId: record.id },
    }
  case 'site':
    return {
      method: transactionService.linkedRecords,
      params: { linkedRecordType: 'service', recordId: record.id },
    }
  case 'service':
    return {
      method: transactionService.linkedRecords,
      params: {
        linkedRecordType: 'service',
        recordId: record?.site?.id,
      },
    }
  default:
    return {
      method: null,
      params: null,
    }
  }
}

export const generateAppendableValueObject = <
  T extends SPUDOrganisationRecordDetails | SPUDSiteRecordDetails | SPUDServiceRecordDetails>(
    recordDetails: SPUDRecord<T>,
    recordType: string | undefined,
  ): {
    site?: number | null | string,
    organisation?: number | null | string
  } | null => {
  switch (recordType) {
  case 'site':
    return {
      organisation: recordDetails.organisation?.id,
    }
  case 'service':
    return {
      site: recordDetails.site?.id || recordDetails.id,
    }
  default:
    return null
  }
}
