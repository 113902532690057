import { AdvancedFilterType, FilterValueType } from '../RecordFilter.service'
import { format } from 'date-fns'

const initialDate = format(new Date(), 'dd/MM/yyyy')

export const ORGANISATION_FILTER_OPTIONS: Array<AdvancedFilterType> = [
  {
    name: 'iss_id',
    label: 'ISS ID',
    type: 'text',
    defaultValue: '',
    value: '',
    role: 'any',
  },
  {
    name: 'last_modified_date',
    label: 'last modified date',
    type: 'customComponent',
    defaultValue: {
      date: initialDate,
      dateSearchOperator: 'before',
    } as unknown as FilterValueType,
    value: {
      date: initialDate,
      dateSearchOperator: 'before',
    } as unknown as FilterValueType,
    customComponent: {
      name: 'DateFilter',
      props: {
        recordType: 'organisation',
        filterFieldProperties: {
          dateSearchOperators: [
            'before', 'after', 'equals', 'range',
          ],
        },
      },
    },
    role: 'any',
  },
  {
    name: 'last_updated_date',
    label: 'last updated date',
    type: 'customComponent',
    defaultValue: {
      date: initialDate,
      dateSearchOperator: 'before',
    } as unknown as FilterValueType,
    value: {
      date: initialDate,
      dateSearchOperator: 'before',
    } as unknown as FilterValueType,
    customComponent: {
      name: 'DateFilter',
      props: {
        recordType: 'organisation',
        filterFieldProperties: {
          dateSearchOperators: [
            'before', 'after', 'equals', 'range',
          ],
        },
      },
    },
    role: 'any',
  },
  {
    name: 'has_bulk_updates',
    label: 'Has bulk updates',
    type: 'toggle',
    defaultValue: false,
    value: false,
    role: 'any',
  },
  {
    name: 'name',
    label: 'Organisation name',
    type: 'text',
    defaultValue: '',
    value: '',
    additionalControls: [
      'contains', 'doesnotcontain', 'endswith', 'equals', 'notequals', 'startswith',
    ],
    role: 'any',
  },
  {
    name: 'spud_id',
    label: 'SPUD ID',
    type: 'text',
    defaultValue: '',
    value: '',
    role: 'any',
  },
]
