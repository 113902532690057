import React from 'react'

interface IxAutoAwesomeIconProps {
  width?: string
  height?: string
}

function IxAutoAwesomeIcon ({ width = '1em', height = '1em' }: IxAutoAwesomeIconProps) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <path
        fillRule='evenodd'
        // eslint-disable-next-line max-len
        d="m19 9 1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25z"
        transform="translate(1,1)"
      />
    </svg>
  )
}

export default IxAutoAwesomeIcon
