import { AdvancedFilterType, FilterValueType } from '../RecordFilter.service'
import { userService } from '../../../services/user.service'
import { Option } from '../../../components/forms/AddEditRecord.type'
import { format } from 'date-fns'
import { spudAPI } from '../../../services/spud.service'

const initialDate = format(new Date(), 'dd/MM/yyyy')

export const SITE_FILTER_OPTIONS: Array<AdvancedFilterType> = [
  {
    name: 'allocated_user',
    label: 'Allocated to',
    type: 'multiselect',
    defaultValue: '',
    value: '',
    optionFunction: userService.list,
    formatDisplayName: <T extends Record<string, string>>(value : Option & T): string => {
      return `${value.first_name} ${value.last_name}`
    },
    formatValueName: (value: Option): number | string | null => {
      return value.id
    },
    role: 'any',
  },
  {
    name: 'site.datasets',
    label: 'Datasets',
    type: 'multiselect',
    defaultValue: '',
    value: [],
    optionFunction: spudAPI.fetchDatasets,
    role: 'Administrator',
  },
  {
    name: 'date_started',
    label: 'Date created',
    type: 'customComponent',
    defaultValue: {
      date: new Date(),
      dateSearchOperator: 'before',
    } as unknown as FilterValueType,
    value: {
      date: new Date(),
      dateSearchOperator: 'before',
    } as unknown as FilterValueType,
    customComponent: {
      name: 'DateFilter',
      props: {
        recordType: 'site',
        filterFieldProperties: {
          dateSearchOperators: [
            'after', 'before', 'equals', 'range',
          ],
        },
      },
    },
    role: 'any',
  },
  {
    name: 'due_for_update',
    label: 'Due for update',
    type: 'toggle',
    defaultValue: false,
    value: false,
    role: 'any',
  },
  {
    name: 'has_bulk_updates',
    label: 'Has bulk updates',
    type: 'toggle',
    defaultValue: false,
    value: false,
    role: 'any',
  },
  {
    name: 'has_linked_services',
    label: 'Has linked services',
    type: 'toggle',
    defaultValue: false,
    value: false,
    role: 'any',
  },
  {
    name: 'has_unique_name',
    label: 'Has unique name',
    type: 'toggle',
    defaultValue: false,
    value: false,
    role: 'any',
  },
  {
    name: 'iss_id',
    label: 'ISS ID',
    type: 'text',
    defaultValue: '',
    value: '',
    role: 'any',
  },
  {
    name: 'last_modified_date',
    label: 'last modified date',
    type: 'customComponent',
    defaultValue: {
      date: initialDate,
      dateSearchOperator: 'before',
    } as unknown as FilterValueType,
    value: {
      date: initialDate,
      dateSearchOperator: 'before',
    } as unknown as FilterValueType,
    customComponent: {
      name: 'DateFilter',
      props: {
        recordType: 'site',
        filterFieldProperties: {
          dateSearchOperators: [
            'after', 'before', 'equals', 'range',
          ],
        },
      },
    },
    role: 'any',
  },
  {
    name: 'last_updated_date',
    label: 'last updated date',
    type: 'customComponent',
    defaultValue: {
      date: initialDate,
      dateSearchOperator: 'before',
    } as unknown as FilterValueType,
    value: {
      date: initialDate,
      dateSearchOperator: 'before',
    } as unknown as FilterValueType,
    customComponent: {
      name: 'DateFilter',
      props: {
        recordType: 'site',
        filterFieldProperties: {
          dateSearchOperators: [
            'after', 'before', 'equals', 'range',
          ],
        },
      },
    },
    role: 'any',
  },
  {
    name: 'service.status',
    label: 'Linked service status',
    type: 'multiselect',
    defaultValue: '',
    value: '',
    options: [
      { id: 'draft', name: 'Draft' },
      { id: 'pending', name: 'Pending' },
      { id: 'published', name: 'Published' },
    ],
    role: 'any',
  },
  {
    name: 'site.location',
    label: 'Location',
    type: 'customComponent',
    defaultValue: {
      suburb: [],
      suburbText: {
        searchOperator: 'contains',
        value: '',
      },
      state: '',
      postcode: '',
    } as unknown as FilterValueType,
    value: {
      suburb: [],
      suburbText: {
        searchOperator: 'contains',
        value: '',
      },
      state: '',
      postcode: '',
    } as unknown as FilterValueType,
    customComponent: {
      name: 'LocationFilter',
      props: {
        recordType: 'Site',
        filterFieldProperties: {
          state: {
            name: 'state',
            label: 'State',
            type: 'dropdown',
            defaultValue: '',
            value: '',
          },
          suburb: {
            name: 'suburb',
            label: 'Suburb',
            type: 'multiselect',
            defaultValue: [],
            value: [],
          },
          suburbText: {
            name: 'suburb',
            label: 'Suburb',
            type: 'text',
            defaultValue: [],
            additionalControls: [
              'contains', 'doesnotcontain', 'endswith', 'equals', 'notequals', 'startswith',
            ],
            value: [],
          },
          postcode: {
            name: 'postcode',
            label: 'Postcode',
            type: 'text',
            defaultValue: '',
            value: '',
          },
        },
      },
    },
    role: 'any',
  },
  {
    name: 'organisation.name',
    label: 'Organisation name',
    type: 'text',
    defaultValue: '',
    value: '',
    additionalControls: [
      'contains', 'doesnotcontain', 'endswith', 'equals', 'notequals', 'startswith',
    ],
    role: 'any',
  },
  {
    name: 'required_fields_missing',
    label: 'Required fields missing',
    type: 'toggle',
    defaultValue: false,
    value: false,
    requiredFields: [],
    role: 'any',
  },
  {
    name: 'service.name',
    label: 'Service name',
    type: 'text',
    defaultValue: '',
    value: '',
    additionalControls: [
      'contains', 'doesnotcontain', 'endswith', 'equals', 'notequals', 'startswith',
    ],
    role: 'any',
  },
  {
    name: 'name',
    label: 'Site name',
    type: 'text',
    defaultValue: '',
    value: '',
    additionalControls: [
      'contains', 'doesnotcontain', 'endswith', 'equals', 'notequals', 'startswith',
    ],
    role: 'any',
  },
  {
    name: 'site.status',
    label: 'Site status',
    type: 'multiselect',
    defaultValue: '',
    value: '',
    options: [
      { id: 'draft', name: 'Draft' },
      { id: 'pending', name: 'Pending' },
      { id: 'published', name: 'Published' },
    ],
    role: 'any',
  },
  {
    name: 'spud_id',
    label: 'SPUD ID',
    type: 'text',
    defaultValue: '',
    value: '',
    role: 'any',
  },
]
