import React from 'react'
import { Toggle, Title } from '@ix/ix-ui'
import styled from 'styled-components'

interface ConfidentialToggleProps {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const SmallToggleWrapper = styled.div`
  transform: scale(0.9);
  margin: 0 5px 5px 0;
`

const ConfidentialToggle: React.FC<ConfidentialToggleProps> = ({ value, onChange, disabled, style }) => {
  const onToggled = () => {
    onChange(!value)
  }

  const onEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      onToggled()
    }
  }

  return (
    <SmallToggleWrapper style={style}>
      <Toggle
        padding='0'
        label={<Title level={4} marginTop='1.1em'>Confidential</Title>}
        checked={value}
        disabled={disabled}
        onChange={onToggled}
        onKeyDown={onEnter}
        tabIndex={0}
      />
    </SmallToggleWrapper>
  )
}

export default ConfidentialToggle
