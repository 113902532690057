
// Get the related focus field name when icon is clicked in Quick Access Bar.
import { Field, Option } from './AddEditRecord.type'

export const getFocusFieldName = (quickAccessButtonClicked: string) => {
  switch (quickAccessButtonClicked) {
  case 'Location':
    return 'location'
  case 'Contact':
    return 'Telephone'
  case 'Operational':
    return 'catchment_description'
  case 'Legacy':
    return 'details'
  default:
    return 'name'
  }
}

export const setFieldFocus = (id: string) => {
  const fieldFocus = document.getElementById(id)
  fieldFocus?.focus()
}

export const setLocationFieldFocus = () => {
  const searchForLocation = document.getElementById('spud-location-autocomplete-Search for Location')
  const locationInput = searchForLocation?.getElementsByTagName('input')[0]
  locationInput?.focus()
}

export const convertCommaSepStringToArray = (stringValue: string): Array<Option> => {
  if (!stringValue) return []
  if (stringValue.includes(',')) {
    return stringValue.split(',').map((item: string) => {
      return {
        id: item,
        name: item,
      }
    })
  }
  return [
    {
      id: stringValue,
      name: stringValue,
    },
  ]
}

export const getValidationRules = (
  saveType: 'draft' | 'pending' | 'published',
  formElement: Field,
  recordType: string | undefined,
) => {
  switch (saveType) {
  case 'draft':
  case 'pending':
    switch (recordType) {
    case 'site':
    case 'service':
      return ['name', 'datasets', 'location'].includes(formElement.name)
    case 'organisation':
      return ['name'].includes(formElement.name)
    }
    break
  default:
    return Object.keys(formElement.rules).length > 0
  }
}
