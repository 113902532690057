import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faArrowDown,
  faEllipsisH,
  faArrowsRotate,
} from '@fortawesome/free-solid-svg-icons'
import {
  Button, PopoutMenu,
  Card,
  Col,
  Container,
  Content,
  Header,
  Page,
  Pagination,
  Paragraph,
  Section,
  Row,
  Title,
  Table,
  Text,
} from '@ix/ix-ui'
import { withContext } from '../../../context/AppContext'
import { AddNewButton, AdvancedFilterWrapper } from '../../../helpers/record'
import { AppContextType, SPUDWebSocketMessage } from '../../../context/AppContext.type'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Toaster } from 'react-hot-toast'
import { useAuth } from '../../../helpers/auth'
import {
  fetchRecordColumns, filterToUrlParams,
  formatReplacedFieldValue,
  formatRowData,
  RecordPageType, urlParamsToFilterObj,
} from './ListView.service'
import styled from 'styled-components'
import { SendMessage } from 'react-use-websocket'
import FilteringComponent from '../../RecordFilter/FilteringComponent'
import AdvancedFilters from '../../RecordFilter/AdvancedFilters'
import {
  AdvancedFilterType, fetchAdvancedFilterPresets, preLoadFiltersByRole,
} from '../../RecordFilter/RecordFilter.service'
import SPUDRecordStatus from '../../../components/General/SPUDRecordStatus'
import pluralize from 'pluralize'
import { fetchCorrectHeading } from '../../page.service'
import axios from 'axios'

type matchParams = {
  recordType: string
}

interface ResponsiveTableProps {
  recordType: string;
} // Explicitly declaring recordType as a prop in Table

type Props = RouteComponentProps<matchParams> & AppContextType & {
  sendMessage: SendMessage,
  websocketConnected: string,
}

const ReviewBackgroundTaskButton = styled.button`
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    text-decoration: none;
  }
`
const TableContainer = styled.div`
display: grid;
grid-template-columns: 1fr; /* Fills the page */
width: 100%;
`
const ResponsiveTable = styled(Table)<ResponsiveTableProps>`
  display: block;
  width: 100%;
  overflow-x: auto;
  table-layout: fixed;

 th, td {
    padding: 1em;
    box-sizing: border-box;
    text-align: left;
  }

  /* Apply specific column widths only for the organisation recordType */
  ${({ recordType }) =>
    recordType === 'organisation' &&
    `
      th:nth-child(1), td:nth-child(1) { width: 20%; }
      th:nth-child(2), td:nth-child(2) { width: 40%; }
      th:nth-child(3), td:nth-child(3) { width: 40%; }
    `}
`

const RecordsPage = (props: Props): React.ReactElement => {
  const [data, setData] = useState<Array<RecordPageType>>([])
  const [total, setTotal] = useState<number>(0)
  const [sort, setSort] = useState<string>('asc')
  const [page, setPage] = useState<number>(1)
  const [sorted, setSorted] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [recordType, setRecordType] = useState<string>('')
  const [filterDeleted, setFilterDeleted] = useState(false)
  const [reloadAfterImport, setReloadAfterImport] = useState(false)
  const [openAdvancedFilters, setOpenAdvancedFilters] = useState(false)
  const [editingFilter, setEditingFilter] = useState('')
  const [backgroundTaskCompleted, setBackgroundTaskCompleted] = useState(false)
  const abortController = new AbortController()
  const { userRole } = useAuth()
  const numberOfRowsSelected = !props.selectAllRows ? Object.keys(props.selectedRows).length : total
  const perPage = 50

  const handleSort = (param: number) => (e: Event): void => {
    e.preventDefault()
    setSorted(param)
    if (sort === 'asc') {
      setSort('desc')
    } else {
      setSort('asc')
    }
  }

  const handlePage = (param: { currentPage: number }): void => {
    setPage(param.currentPage)
  }

  useEffect(() => {
    if (props.lastMessage) {
      const websocketCompletedMessage = props.lastMessage.message as {
        action: string,
        updated: number,
        failed: boolean
      }
      if (props.lastMessage.type === 'completed') {
        setBackgroundTaskCompleted(true)
        // When the task is completed we reload the list
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        websocketCompletedMessage.action !== 'Deleted' && reloadList()
      } else if (props.lastMessage.type === 'replaced_field') {
        const copiedData = [...data]
        const message: {
          field: string,
          value: string | boolean | number,
          record_id: string | number
        } = props.lastMessage.message as {
          field: string,
          value: string | boolean | number,
          record_id: string | number
        }
        const foundRecordIndex = data.findIndex(record => record.record_id === message.record_id)
        if (message.field === 'id') {
          copiedData[foundRecordIndex] = {
            ...copiedData[foundRecordIndex],
            id: formatReplacedFieldValue('id', message.value, message.record_id, recordType),
            iss_id: message.record_id,
            record_id: message.record_id,
            status: formatReplacedFieldValue(
              'status',
              'published',
              message.value as string | number,
              recordType),
          }
        } else {
          const newFieldInfo = formatReplacedFieldValue(message.field, message.value, message.record_id, recordType)
          if (message.field === 'is_active' && message.value === false) {
            copiedData[foundRecordIndex] = {
              ...copiedData[foundRecordIndex],
              status: (
                <SPUDRecordStatus
                  status={'draft'}
                  active={false}
                  table
                  issId={typeof message.record_id === 'number' ? message.record_id : null}
                />),
            }
          }
          copiedData[foundRecordIndex] = {
            ...copiedData[foundRecordIndex],
            [message.field]: newFieldInfo,
          }
        }
        setData(copiedData)
      }
    }
  }, [props.lastMessage])

  const fetchRecords = async (
    filter: string | URLSearchParams | null,
    pageOverride?: number|null,
    orderOverride?: string|null,
  ): Promise<void> => {
    setData([])
    if (!filter) {
      setTotal(0)
    }
    const columns = fetchRecordColumns(
      recordType as 'site'|'service'|'organisation',
      userRole,
    )
    const sorting = sort === 'asc' ? '' : '-'
    const ordering = columns[sorted].key !== 'id' ? columns[sorted].key : orderOverride
    setLoading(true)
    setTimeout(async () => {
      try {
        const response = await props
          .filterList(
            {
              recordType: props.match.params.recordType,
              offset: ((pageOverride || page) - 1) * perPage,
              limit: perPage,
              ordering: sorting + ordering,
            },
            filter,
            abortController,
          )
        setLoading(false)
        setTotal(response.data.count)
        const formattedResponse = response.data.results.map((item) => (
          formatRowData(props.match.params.recordType, item, userRole)
        ))
        setData(formattedResponse)
      } catch (error) {
        // If it's not an aborted request we stop it from loading
        if (!axios.isCancel(error)) {
          setLoading(false)
        }
      }
    }, 0)
  }

  const preLoadFilters = async () => {
    setLoading(true)
    const presetFiltersByRole = fetchAdvancedFilterPresets(
      'record-list',
      props.match.params.recordType as 'site'|'service'|'organisation',
    )
    const presetFilters = await preLoadFiltersByRole(presetFiltersByRole, userRole, 'record-list')
    props.setPageFilters(presetFilters)
  }

  useEffect(() => {
    setData([])
    if (recordType !== props.match.params.recordType) {
      setSorted(0)
      setPage(1)
      setRecordType(props.match.params.recordType)
      if (window.location.hash.includes('?') && window.location.hash.split('?')[1] !== '') {
        const params = new URLSearchParams(window.location.hash.split('?')[1])
        const filters: { [x: string]: AdvancedFilterType } = urlParamsToFilterObj(
          props.match.params.recordType,
          params,
          userRole,
          'record-list',
        )
        props.setPageFilters(filters)
      } else {
        props.setPageFilters({})
        preLoadFilters()
      }
    } else {
      if (window.location.hash.includes('?') && window.location.hash.split('?')[1] !== '') {
        const params = new URLSearchParams(window.location.hash.split('?')[1])
        const filters: {[x: string]: AdvancedFilterType} = urlParamsToFilterObj(
          props.match.params.recordType,
          params,
          userRole,
          'record-list',
        )
        if (!Object.keys(filters).length) {
          fetchRecords(null, page, 'id')
        } else {
          props.setPageFilters(filters)
        }
      } else {
        setSorted(0)
        setPage(1)
        setRecordType(props.match.params.recordType)
        props.setPageFilters({})
        preLoadFilters()
      }
    }
    if (props.popupDismissedWithAction && props.popupType !== null) {
      return () => {
        abortController.abort()
      }
    }
    setBackgroundTaskCompleted(false)
    props.setPopupDismissedWithAction(false)
    return () => {
      abortController.abort()
    }
  }, [sort, page, props.match.params.recordType, props.popupDismissedWithAction])

  useEffect(() => {
    const params = new URLSearchParams()
    let order = 'id'
    if (props.pageFilters) {
      Object.entries(props.pageFilters).forEach(([, filter]) => {
        if (filter) {
          order = filter?.default_order || 'id'
        }
      })
      const processedFilterParams = filterToUrlParams(props.pageFilters, params)
      window.location.hash = `?${processedFilterParams}`
      if (Object.keys(props.pageFilters).length) {
        fetchRecords(processedFilterParams || null, page, order)
      } else {
        fetchRecords(null, page, order)
      }
    }
    return () => {
      abortController.abort()
    }
  }, [props.pageFilters])

  useEffect(() => {
    const lastWebsocketMessage = props.lastMessage as SPUDWebSocketMessage<{
      action: string
    }>
    if (
      lastWebsocketMessage?.type === 'completed' &&
      lastWebsocketMessage?.message.action === 'Imported' &&
      !reloadAfterImport
    ) {
      setReloadAfterImport(true)
      const params = new URLSearchParams()
      if (props.pageFilters) {
        const processedFilterParams = filterToUrlParams(props.pageFilters, params)
        fetchRecords(processedFilterParams || null, page, 'id')
      } else {
        fetchRecords(null, 1, 'id')
      }
    } else if (props.lastMessage?.type === 'import_record') {
      setReloadAfterImport(false)
    }
  }, [props.lastMessage, reloadAfterImport])

  const filterAndFetchRecords = (filters: {[x: string]: AdvancedFilterType}) => {
    const params = new URLSearchParams()
    const processedFilterParams = filterToUrlParams(filters, params)
    window.location.hash = `?${processedFilterParams}`
    setPage(1)
    if (Object.keys(filters).length) {
      props.setPageFilters(filters)
      setFilterDeleted(!!filters?.deleted)
    } else {
      props.setPageFilters({})
    }
  }

  let results
  if (data.length) {
    results = (
      <TableContainer>
        <ResponsiveTable
          recordType={recordType} // Pass the recordType prop here for the css specification
          sorted={sorted}
          sort={sort}
          rows={data}
          title={`${recordType} record list`}
          headers={fetchRecordColumns(
    recordType as 'site' | 'service' | 'organisation',
    userRole,
          )}
          archivedRowKey='is_active'
          callbackSort={handleSort}
          selectableRow={userRole === 'Administrator' && (
            recordType === 'site' || recordType === 'service')}
          callBackSelected={(rows: { [x: number]: unknown }) => {
            if (Object.keys(rows).length) {
              props.setSelectedRows({ ...rows })
            } else {
              props.setSelectAllRows(false)
              props.setSelectedRows({})
            }
          }}
          selectAll={props.selectAllRows}
          compact={true}
        />
      </TableContainer>
    )
  } else {
    results = (
      <div style={{ textAlign: 'center' }}>
        {!loading
          ? <Card>
            <FontAwesomeIcon icon={faSearch as IconProp} style={{ color: '#E3EDFA', fontSize: `${72}px` }} />

            <Title level={2} align="center">No records found</Title>

            <Paragraph style={{ textAlign: 'center' }}>
              Oops, we couldn&apos;t find any records for your search.
            </Paragraph>

            <Paragraph style={{ textAlign: 'center' }}>
              <AddNewButton record={props.match.params.recordType} />
            </Paragraph>
          </Card>
          : <Card>Loading</Card> }
      </div>
    )
  }

  const reloadList = () => {
    const params = new URLSearchParams()
    const processedFilterParams = filterToUrlParams(props.pageFilters, params)
    const columns = fetchRecordColumns(
      recordType as 'site'|'service'|'organisation',
      userRole,
    )
    const sorting = sort === 'asc' ? '' : '-'
    const ordering = columns[sorted].key !== 'id' ? columns[sorted].key : 'id'
    fetchRecords(processedFilterParams, page, sorting + ordering)
  }

  const backgroundTaskResult = () => {
    const result = props.webSocketResults as SPUDWebSocketMessage<{
      failed: boolean,
      action: string
      updated: number
    }>
    return result.message.failed || result.type === 'task.duplicate'
      ? result.message.action === 'Imported' && !result.message.updated
        ? 'Failed to import (see errors)'
        : `${result.message.action} (completed with errors)`
      : `${result.message.action} (completed)`
  }

  return (
    <Page>
      <Content>
        <Header style={{ padding: '2em 0.75em', margin: '0 1em' }}>
          <Container style={{ paddingRight: '0' }}>
            {fetchCorrectHeading(
              'record-list',
              props.match.params.recordType,
              props.setPopupType,
              props.setPopupDismissedWithAction,
              props.setPopupOptions,
              total,
            )}
          </Container>
        </Header>
        <Section style={{ position: 'relative' }}>
          <Row>
            <Col>
              <Row>
                <FilteringComponent
                  userRole={userRole}
                  pageFilters={props.pageFilters}
                  filterCallback={(filters) => filterAndFetchRecords(filters)}
                  onOpenAdvancedFilters={(openFilter, chip) => {
                    setOpenAdvancedFilters(openFilter)
                    if (chip) {
                      setEditingFilter(chip)
                    } else {
                      setEditingFilter('')
                    }
                  }}
                  openAdvancedFilters={openAdvancedFilters}
                  pageType='record-list'
                  recordType={props.match.params.recordType}
                />
              </Row>
            </Col>
          </Row>
          {userRole === 'Administrator' && <Row padding="0 0 0 10px" borderRadius="3px">
            <Col direction='row'>
              <Row align='center'>

                {recordType === 'site' &&
                    <Button
                      light={!numberOfRowsSelected || filterDeleted}
                      active={!!(numberOfRowsSelected && !filterDeleted)}
                      disabled={!numberOfRowsSelected || filterDeleted}
                      onClick={() => {
                        props.setPopupDismissedWithAction(false)
                        props.setPopupOptions({
                          recordType: recordType,
                          total: total,
                        })
                        props.setPopupType('allocate')
                      }}
                    >
                      Allocate
                    </Button>
                }
                {recordType !== 'organisation' &&
                  <Button
                    light={!numberOfRowsSelected || status === 'deleted'}
                    active={!!(backgroundTaskCompleted || (numberOfRowsSelected && status !== 'deleted'))}
                    disabled={((!numberOfRowsSelected && !backgroundTaskCompleted) || status === 'deleted')}
                    onClick={() => {
                      props.setPopupDismissedWithAction(false)
                      props.setPopupOptions({
                        recordType: recordType,
                        total: total,
                      })
                      props.setPopupType('bulk-update')
                    }}
                  >
                    Update
                  </Button>}
                {backgroundTaskCompleted && <Text padding="0 10px 0 0">
                  {backgroundTaskResult()}
                  <ReviewBackgroundTaskButton
                    onClick={() => {
                      props.setPopupType('review')
                    }}
                  >
                    See results
                  </ReviewBackgroundTaskButton>
                </Text>}
                <Text padding="0 10px 0 0">
                  {recordType !== 'organisation' && `${numberOfRowsSelected} of`} {total} {
                    recordType}s {recordType !== 'organisation' && numberOfRowsSelected ? 'selected' : 'loaded'}
                </Text>
                {userRole === 'Administrator' &&
                  <PopoutMenu
                    label={<FontAwesomeIcon icon={faEllipsisH as IconProp} color='#222' aria-label="More Actions" />}
                    buttonBackgroundColour='#e3edfa'
                    buttonBackgroundHoverColour='#e0e6ee'
                    menuItems={[
                      {
                        label: 'Download all filtered records as CSV',
                        icon: faArrowDown,
                        method: () => {
                          props.setPopupDismissedWithAction(false)
                          props.setPopupType('export')
                          props.setPopupEntry('filtered')
                          props.setPopupOptions({
                            recordType: recordType,
                            total: total,
                          })
                        },
                        disabled: total === 0,
                      },
                      {
                        label: 'Download selected records as CSV',
                        icon: faArrowDown,
                        method: () => {
                          props.setPopupDismissedWithAction(false)
                          props.setPopupType('export')
                          props.setPopupEntry('selected')
                          props.setPopupOptions({
                            recordType: recordType,
                            total: total,
                          })
                        },
                        disabled: !numberOfRowsSelected,
                      },
                    ]}
                  />
                }
              </Row>
            </Col>
            <Col>
              <Row justify="flex-end">
                {recordType !== 'organisation' &&
                  <Button
                    light={!numberOfRowsSelected || status === 'deleted'}
                    active={!!(numberOfRowsSelected && status !== 'deleted')}
                    onClick={() => {
                      props.setPopupOptions({
                        recordType: recordType,
                        total: total,
                      })
                      props.setPopupType('bulk-delete')
                    }}
                    aria-label='bulk-delete-button'
                  >
                    Delete
                  </Button>
                }
                <Button
                  light={true}
                  onClick={reloadList}
                  aria-label='refresh-search'
                >
                  <FontAwesomeIcon icon={faArrowsRotate as IconProp} spin={loading} />
                </Button>
              </Row>
            </Col>
          </Row>}
          {userRole !== 'Administrator' && <Row>
            <Col>
              <Text padding="0 10px 0 0">
                {total} {pluralize('record', total)} loaded
              </Text>
            </Col>
            <Col>
              <Row justify="flex-end">
                <Button
                  light={true}
                  onClick={reloadList}
                  aria-label='refresh-search'
                >
                  <FontAwesomeIcon icon={faArrowsRotate as IconProp} spin={loading} />
                </Button>
              </Row>
            </Col>
          </Row>}
          {results}

          <Row>
            <Col>
              <Row justify="flex-end">
                <Pagination
                  total={total}
                  showNumbers
                  showEndNumbers
                  pageNeighbours={2}
                  onPageChange={handlePage}
                  pageSize={perPage}
                  pageOverride={page}
                />
              </Row>
            </Col>
          </Row>

        </Section>
        <Toaster />
      </Content>
      {openAdvancedFilters && <AdvancedFilterWrapper>
        <AdvancedFilters
          onDismissAdvancedFilters={() => {
            setOpenAdvancedFilters(false)
            setEditingFilter('')
          }}
          recordType={recordType}
          currentPageFilters={props.pageFilters}
          userRole={userRole}
          onUpdateFilters={(filters) => filterAndFetchRecords(filters)}
          pageType={'record-list'}
          editingFilter={editingFilter}
        />
      </AdvancedFilterWrapper>}
    </Page>
  )
}

export default withContext(RecordsPage)
