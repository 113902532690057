import Api, { getSPUDAPIUrl } from '../helpers/api'
import { SPUDOnSaveResponseDetails } from '../../@types/SPUDRecord.type'

/**
 * @typedef {Object} CrisisKeywordsType
 * @property {number} [id] - The ID of the crisis keyword.
 * @property {string} name - The name of the crisis keyword.
 */
export type CrisisKeywordsType = {
  id?: number,
  name: string
}

/**
 * Fetches a list of crisis keywords from the API.
 *
 * @param {Object} searchParams - The parameters to use for the search.
 * @param {string} searchParams.name - The name to search for.
 * @param {number} searchParams.offset - The offset to use for pagination.
 * @param {AbortController} abortController - The AbortController to use for cancelling the request.
 * @returns {Promise<Object>} A promise that resolves to an object containing the results of the search and the URL for the next page of results.
 */
export const listCrisisKeywords = async (
  searchParams: {
    name: string,
    offset: number,
  },
  abortController: AbortController,
): Promise<object> => {
  return await Api.get<{
    results: Array<CrisisKeywordsType>,
    next: string | null
  }>(
    `${getSPUDAPIUrl()}directory/crisis-keywords/`,
    {
      params: searchParams,
      signal: abortController.signal,
    })
}

/**
 * Creates a new crisis keyword in the API.
 *
 * @param {Object} crisisKeyword - The crisis keyword to create.
 * @returns {Promise<Object>} A promise that resolves to an object containing the details of the created crisis keyword.
 */
export const createNewCrisisKeyword = async (crisisKeyword: { [x: string]: unknown }): Promise<{
    data: SPUDOnSaveResponseDetails
  }> => {
  return await Api.post(`${getSPUDAPIUrl()}directory/crisis-keywords/`, crisisKeyword)
}
