import API, { AxiosError, InternalAxiosRequestConfig } from 'axios'
import history from './history'
import keycloak from '../../keycloak'
import { notifyUserAboutFailedRequest } from './failedToFetchRecords'

export const getSPUDAPIUrl = (): string|null|undefined => {
  return window.SPUD_API_URL
}

export const getWebsocketUrl = (accessToken: string): string => {
  return `${window.SPUD_WS_URL}${accessToken ? `?access_token=${accessToken}` : ''}`
}

export const getCaptchaSiteKey = (): string => {
  return window.Cypress ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' : window.PUBLIC_RECAPTCHA_SITE_KEY
}

export type AxiosErrorWithObjectResponse = Omit<AxiosError<{ [key: string]: unknown }>, 'response'> & {
  response: NonNullable<AxiosError<{ [key: string]: unknown }>['response']>
}

export const isAxiosErrorWithObjectResponsePayload = (
  possibleError: unknown): possibleError is AxiosErrorWithObjectResponse => {
  return API.isAxiosError(possibleError) &&
    typeof possibleError.response === 'object' &&
    typeof possibleError.response.data === 'object' &&
    possibleError.response.data !== null
}

API.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  if (config.headers && keycloak?.authenticated) {
    config.headers.Authorization = `Bearer ${keycloak.token}`
  }
  return config
},
(error) => {
  if (error.response.status === 500) {
    console.log(error)
  }
  Promise.reject(error)
})

API.interceptors.response.use((response) => response,
  (error) => {
    if (!error.response) {
      // this is normally triggered when there's a network error.
      // however when request is aborted it doesn't contain
      // a typical error response but rather an object
      // { message: 'canceled' }
      // To avoid a pop-up from displaying when the user changes
      // views or alters filters before a request is completed.
      // We will only show the error if it's not a canceled obj
      if (error.message !== 'canceled') {
        notifyUserAboutFailedRequest(`${error.message}: Failed to fetch records`)
      }
      return Promise.reject(error)
    }

    if (error.response.status === 500) {
      console.error(error)
    }

    if (error.response.status === 403 || error.response.status === 401) {
      notifyUserAboutFailedRequest('User not authorised')
      // If a 403 is caused by an authentication
      if (!error.response.data?.detail) {
        history.push('/logout')
      }
    }
    if (error.response.status === 400) {
      notifyUserAboutFailedRequest(`Bad request: ${error.message}`)
    }

    // return Error object with Promise
    return Promise.reject(error)
  })

export default API
