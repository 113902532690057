import 'regenerator-runtime/runtime'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { rehydrateMarks } from 'react-imported-component'

import App from './App'

const root = document.getElementById('root')

if (root) {
  if (root.hasChildNodes()) {
    rehydrateMarks().then(() => {
      // eslint-disable-next-line react/no-deprecated
      ReactDOM.hydrate(<App />, root)
    })
  } else {
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(<App />, root)
  }
}

if (module.hot) {
  module.hot.accept()
}
