import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, Col, Row, Title, Alert, Textarea, Text } from '@ix/ix-ui'
import { useFieldArray, useFormContext, Controller } from 'react-hook-form'
import { SPUDInputField } from '../../helpers/record'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLevelUpAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import { AngledArrowContainer } from './AngledArrowContainer'
import styled from 'styled-components'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import BulkUpdateRecordChangeReview from '../forms/BulkUpdateRecordChangeReview'
import { BulkServiceProviderUpdateType } from '../../services/spud.service'
import { bulkUpdateRecordChangeReviewType } from '../../externalPages/BulkAutoUpdate/BulkAutoUpdateService'
import SPUDMultiEntryFieldSiteDetails from './SPUDMultiEntryFieldSiteDetails'
import TrashIcon from '../TrashIcon'
import ConfidentialToggle from '../ConfidentialToggle'

export type SPUDEmailsProps = {
  title: string,
  recordType: string,
  siteValues: SPUDSiteRecordDetails,
  disabled: boolean,
  bulkUpdateChanges: BulkServiceProviderUpdateType,
  loadingForm: boolean,
  resetBulkUpdateRecordChanges?: boolean,
  setResetBulkUpdateRecordChanges?: Dispatch<SetStateAction<boolean>>,
}

export interface SPUDEmailType {
  email: string,
  confidential: boolean,
  comment: string
}

const EmailLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 1.25em;
`

const SPUDEmailCard = styled.div`
  width: 100%;
  border-radius: 5px;
  margin: 10px 0;
  box-shadow: 0 0 6px 3px #eaeaea;
  padding: 1em;
`

const EmailCardHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
`

function SPUDEmails ({
  title,
  recordType,
  siteValues,
  disabled,
  bulkUpdateChanges,
  loadingForm,
  resetBulkUpdateRecordChanges,
  setResetBulkUpdateRecordChanges,
}: SPUDEmailsProps): React.ReactElement {
  const { control, setValue, getValues, formState: { errors } } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emails',
  })

  const [siteRecordEmails, setSiteRecordEmails] = useState<Array<{
    email: string,
    comment?: string
  }>>([])

  const [originalRecordEmails, setOriginalRecordEmails] = useState<Array<{
    email: string,
    comment?: string
  }>>([])

  const [originalEmailsLoaded, setOriginalEmailsLoaded] = useState(false)

  let disableAutoFocus = true
  const formErrors = errors as {
    emails?: Array<{
      email: {
        message: string
      }
    }>
  }

  useEffect(() => {
    if (recordType === 'service' && siteValues?.emails) {
      setSiteRecordEmails(siteValues?.emails)
    } else {
      disableAutoFocus = false
      setSiteRecordEmails([])
    }
  }, [siteValues?.emails, recordType])

  useEffect(() => {
    if (!loadingForm) {
      if (!originalEmailsLoaded) {
        setOriginalRecordEmails(getValues('emails'))
        setOriginalEmailsLoaded(true)
      }
    }
  }, [loadingForm])

  const acceptChanges = (key: string, value: unknown) => {
    setValue(key, value)
  }

  return (
    <div>
      <Row style={{ margin: '0.5rem 0' }}>
        <Col>
          <Title level={4}>{title}</Title>
        </Col>
        <Col flex={12}>
          <Button active={!disabled} onClick={() => append({ email: '' }, { shouldFocus: false })}>
            <FontAwesomeIcon icon={faPlus as IconProp}/> Add an Email
          </Button>
        </Col>
      </Row>
      {recordType === 'service' && (
        <Row>
          <Col>
            <SPUDMultiEntryFieldSiteDetails
              fieldType='Email'
              items={siteRecordEmails as Array<SPUDEmailType>}
              dataStructure={{
                email: {
                  dataType: 'string',
                  primary: true,
                  humanReadableLabel: 'email',
                },
                comment: {
                  dataType: 'string',
                  primary: false,
                  humanReadableLabel: 'comment',
                },
              }}
            />
          </Col>
        </Row>
      )}
      {fields.map((item, index) => (
        <Row key={item.id}>
          <Col direction="row">
            <AngledArrowContainer>
              <FontAwesomeIcon size="2x" rotation={90} icon={faLevelUpAlt as IconProp} color="#A3A3A3" />
            </AngledArrowContainer>
            <SPUDEmailCard>
              <EmailCardHeader>
                <TrashIcon onClick={() => remove(index)} active={!disabled} />
              </EmailCardHeader>
              <Row>
                <Col>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Controller
                      name={`emails.${index}.email`}
                      control={control}
                      rules={{
                        pattern: {
                          // eslint-disable-next-line max-len
                          value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                          message: 'That does not look like an email address, please try again.',
                        },
                      }}
                      render={({ field }) => (
                        <SPUDInputField
                          id="email"
                          autoFocus={!disableAutoFocus}
                          placeholder="Email"
                          required={true}
                          label={<EmailLabel>Email</EmailLabel>}
                          fullwidth
                          style={{ width: '50em' }}
                          hideAsterisk={true}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name={`emails.${index}.confidential`}
                      control={control}
                      rules={{ required: false }}
                      defaultValue={false}
                      render={({ field }) => (
                        <ConfidentialToggle
                          style={{ position: 'absolute', left: '2.5em', top: '-17.5px' }}
                          value={field.value || false}
                          onChange={(checked) => {
                            field.onChange(checked)
                          }}
                          disabled={disabled}
                        />
                      )}
                    />
                  </div>
                  {formErrors?.emails?.[index]?.email && (
                    <Alert type="error" aria-label={`emails-${index}`}>
                      {formErrors?.emails?.[index]?.email?.message}
                    </Alert>
                  )}
                </Col>
              </Row>
              <Row style={{ display: 'none' }}>
                <Col>
                  <Controller
                    name={`emails.${index}.comment`}
                    control={control}
                    rules={{ required: false }}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <Title level={4}>Comment</Title>
                        <Textarea {...field} />
                      </>
                    )}
                  />
                  {siteRecordEmails?.[index]?.comment && (
                    <Text>Site email {index + 1} comment: {siteRecordEmails?.[index]?.comment}</Text>
                  )}
                </Col>
              </Row>
            </SPUDEmailCard>
          </Col>
        </Row>
      ))}
      {bulkUpdateChanges && (
        <Row>
          <Col>
            <BulkUpdateRecordChangeReview
              bulkUpdateChanges={bulkUpdateChanges}
              setValue={acceptChanges}
              fieldKey="emails"
              fieldLabel={title}
              currentFieldValue={originalRecordEmails as bulkUpdateRecordChangeReviewType}
              resetBulkUpdateRecordChanges={resetBulkUpdateRecordChanges}
              setResetBulkUpdateRecordChanges={setResetBulkUpdateRecordChanges}
            />
          </Col>
        </Row>
      )}
    </div>
  )
}

export default SPUDEmails
