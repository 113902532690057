import React, { useRef, useState } from 'react'
import { Textarea, Title } from '@ix/ix-ui'
import { ControllerRenderProps } from 'react-hook-form'
import styled from 'styled-components'
import { SPUDToggle } from '../../helpers/record'

type SPUDTextAreaProps = {
  field: ControllerRenderProps | null | undefined | {
    value: string,
    name: string,
    onChange: (value: React.ChangeEvent<HTMLTextAreaElement>) => void,
  },
  label: string,
  highlight?: boolean
  canShowLineBreaks: boolean
  disabled: boolean,
  minHeight?: string,
  onChange?: (value: React.ChangeEvent<HTMLTextAreaElement>) => void}

const TextArea = styled(Textarea)<{highlight: boolean | undefined, height: string, minHeight: string}>`
  resize: vertical;
  height: ${props => props.height};
  border: ${props => props.highlight ? '3px solid #ff9595' : '1px solid #3a8ae8'};
  min-height: ${props => props.minHeight || '30px'};
  margin-bottom: 0;
  :hover {
    border: ${props => props.highlight ? '3px solid #ff9595' : '1px solid #3a8ae8'};
  }
  :active {
    border: ${props => props.highlight ? '3px solid #ff9595' : '1px solid #3a8ae8'};
  }
`

function SPUDTextArea (
  {
    field,
    label,
    highlight,
    canShowLineBreaks = false,
    disabled,
    minHeight,
    onChange,
    ...rest
  }: SPUDTextAreaProps): React.ReactElement {
  const [fieldValue, setFieldValue] = useState('')
  const [showLineBreaks, setShowLineBreaks] = useState(false)

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const formatFieldWithLineBreaks = (showLineBreak: boolean) => {
    let newVal
    if (showLineBreak) {
      newVal = field?.value.replaceAll('\n', '<br/>\n')
    } else {
      newVal = field?.value.replaceAll('<br/>\n', '\n')
    }
    setShowLineBreaks(!showLineBreaks)
    setFieldValue(newVal)
  }

  const calculateHeight = () => {
    if (textAreaRef.current) {
      return `${textAreaRef.current?.scrollHeight}px`
    }
    return 'inherit'
  }

  const updatedField = { ...field, onChange: onChange || field?.onChange }

  return <div>
    <Title level={4}>{label}</Title>
    <TextArea
      ref={textAreaRef}
      rows={1}
      id={field?.name}
      highlight={highlight && !field?.value}
      height={calculateHeight()}
      disabled={(canShowLineBreaks && showLineBreaks) || disabled}
      value={showLineBreaks ? fieldValue : field?.value}
      onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const target = event.target as HTMLTextAreaElement
        target.style.height = 'inherit'
        target.style.height = `${target.scrollHeight}px`
      }}
      placeholder={label}
      minHeight={`${minHeight || '30px'}`}
      {...updatedField}
      {...rest}
    />
    {canShowLineBreaks && (
      <SPUDToggle
        padding='0'
        offColor="#E3EDFA"
        label={<Title level={4} marginTop='0'>Show line breaks</Title>}
        checked={showLineBreaks}
        onChange={(checked: boolean) =>
          formatFieldWithLineBreaks(checked)
        }
        onKeyDown={(key: React.KeyboardEvent<HTMLInputElement>) => {
          if (key.key === 'Enter') {
            formatFieldWithLineBreaks(!showLineBreaks)
          }
        }}
      />
    )}
  </div>
}

export default SPUDTextArea
