import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  AdvancedFilterType,
  DateFilterValueType,
  FilterValueType,
  formatFilterFieldSummaryValue,
} from './RecordFilter.service'

type FilterChipsProps = {
  activeFilters: {[x: string]: AdvancedFilterType},
  onChipClick: (key: string) => void,
  filterPanelOpen?: boolean
  clickable?: boolean
}

const FilterChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const FilterChip = styled.div<{editing: boolean, clickable: boolean}>`
  border-radius: 3px;
  border: 1px solid ${props => props.theme.colors.active};
  padding: 5px;
  color:${props => props.editing ? '#fff' : '#4c1811'};
  font-size: 14px;
  margin: 0.3em;
  background-color: ${props => props.editing ? props.theme.colors.active : '#fff'};
  min-height: 2em;

  :hover {
    cursor: ${props => props.clickable ? 'pointer' : 'default'};
  }
`

function FilterChips ({ activeFilters, onChipClick, filterPanelOpen = false, clickable = true }: FilterChipsProps) {
  const [editingFilter, setEditingFilter] = useState('')

  useEffect(() => {
    if (!activeFilters?.[editingFilter]) {
      setEditingFilter('')
    }
  }, [activeFilters?.[editingFilter]])

  const orderDateFilterValue = (filterValue: AdvancedFilterType<DateFilterValueType>) => {
    if (filterValue.value?.dateSearchOperator === 'range') {
      filterValue.value = {
        fromDate: filterValue.value.fromDate,
        dateSearchOperator: filterValue.value.dateSearchOperator,
        date: filterValue.value.date,
      }
    }
    return filterValue
  }

  const preProcessFilterValueToBeDisplayed = (filterKey: string, FilterValue: AdvancedFilterType) => {
    if (filterKey.includes('date')) {
      return <strong>
        {formatFilterFieldSummaryValue<DateFilterValueType>(
          orderDateFilterValue(FilterValue as unknown as AdvancedFilterType<DateFilterValueType>),
        )}
      </strong>
    }
    return <strong>{formatFilterFieldSummaryValue<FilterValueType>(FilterValue)}</strong>
  }

  return <div>
    <FilterChipContainer>
      {Object.entries(activeFilters).map(([key, value]) =>
        <FilterChip
          key={key}
          onClick={() => {
            if (editingFilter === key) {
              setEditingFilter('')
              onChipClick('')
            } else {
              setEditingFilter(key)
              onChipClick(key)
            }
          }}
          editing={key === editingFilter && filterPanelOpen}
          clickable={clickable}
        >
          {value.label}:  {preProcessFilterValueToBeDisplayed(key, value)}
        </FilterChip>,
      )}
    </FilterChipContainer>
  </div>
}

export default FilterChips
