import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Title,
  Button,
  Table,
  Row,
  Col,
} from '@ix/ix-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { fetchDupeHeadings } from './ImportRecord.service'
import styled from 'styled-components'
import * as _ from 'lodash'
import { DuplicateRowType } from '../../../../services/spud.service'

export type PostProcessRowType = {
  rowNo: number,
  row_data: {[x: string]: unknown }
}

type DuplicateRowsProps = {
  duplicateType: string
  recordType: string
  duplicateGroup: Array<DuplicateRowType>
  setDuplicateOverrides?: Dispatch<SetStateAction<Array<PostProcessRowType>>>
  setDuplicateRowOverrides?: Dispatch<SetStateAction<Array<number>>>
  duplicateOverrides?: Array<PostProcessRowType>
  duplicateRowOverrides?: Array<number>
  duplicateCheckType: 'pre'|'post'
}

const ExpandButton = styled(Button)`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  :hover {
    background: none;
    border: none;
  }
  :focus {
    border: none;
    background: none;
  }
`

function DuplicateRows (
  {
    duplicateGroup,
    duplicateType,
    recordType,
    setDuplicateOverrides,
    setDuplicateRowOverrides,
    duplicateOverrides,
    duplicateRowOverrides,
    duplicateCheckType,
  }: DuplicateRowsProps) {
  const [expandDuplicate, setExpandDuplicate] = useState(false)
  const handleSort = () => (e: Event): void => {
    e.preventDefault()
  }

  useEffect(() => {
    duplicateGroup.forEach(row => {
      if (row.matchingRow) {
        row.matchingRowElm = (
          <div style={{ wordBreak: 'break-all', maxWidth: '20rem' }}>
            {row.matchingRow}
          </div>
        )
        return row
      } else {
        return row
      }
    })
  }, [duplicateGroup])

  const preCheckSetDuplicateOverrides = (rows: {[x: number]: { rowNo: number }}) => {
    const rowNos = Object.values(rows).flatMap((row: {rowNo: number}) => row.rowNo)
    if (duplicateRowOverrides) {
      setDuplicateRowOverrides?.([
        ...duplicateRowOverrides,
        ..._.difference(rowNos, duplicateRowOverrides),
      ])
    }
  }

  const postCheckSetDuplicateOverrides = (rows: {[x: number]: PostProcessRowType}) => {
    if (Object.keys(rows).length) {
      const rowValues = Object.values(rows).flatMap(
        (row: PostProcessRowType) => (
          {
            row_index: row.rowNo,
            row: row.row_data,
          }
        )) as unknown as Array<PostProcessRowType>
      if (duplicateOverrides && setDuplicateOverrides) {
        const differences = _.differenceBy(rowValues, duplicateOverrides, 'row_index')
        setDuplicateOverrides([
          ...duplicateOverrides,
          ...differences,
        ])
      }
    } else {
      setDuplicateOverrides && setDuplicateOverrides([])
    }
  }

  return (
    <div>
      <Title level={5}>
        <Row onClick={() => setExpandDuplicate(!expandDuplicate)} style={{ cursor: 'pointer' }}>
          <Col direction='row' justify='flex-start' align='center'>
            <div style={{ padding: '0 5px' }}>
              {duplicateCheckType === 'pre' ? `Dupe Check ${duplicateType}` : duplicateType}
            </div>
            <ExpandButton>
              <FontAwesomeIcon
                icon={
                  !expandDuplicate
                    ? faChevronRight as IconProp
                    : faChevronDown as IconProp
                }
              />
            </ExpandButton>
          </Col>
        </Row>
      </Title>
      {expandDuplicate &&
        <Table<DuplicateRowType>
          rows={duplicateGroup}
          title="Duplicate Rows"
          headers={fetchDupeHeadings(recordType, duplicateCheckType)}
          callbackSort={handleSort}
          selectableRow={true}
          callBackSelected={
            duplicateCheckType === 'pre'
              ? preCheckSetDuplicateOverrides
              : postCheckSetDuplicateOverrides
          }
          compact={true}
        />}
    </div>
  )
}

export default DuplicateRows
