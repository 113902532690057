import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Row, Col, Title } from '@ix/ix-ui'
import SPUDUserAutocomplete from '../../../../components/General/SPUDUserAutocomplete'
import { flattenSelectedRows, PopUpContainer } from '../ListView.service'
import { Option } from '../../../../components/forms/AddEditRecord.type'
import { allocateUsers, AllocateRecordType } from '../../../../services/allocation.service'
import { userService } from '../../../../services/user.service'
import AllocateRecordsTable from './AllocateUserTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type AllocateUsersProps = {
  selectedRows: {[x: number]: {record_id: string | number, name: string}},
  recordType: string
  dismissPopup: (allocated: boolean) => void,
  selectedAllRows: boolean,
  total: number
}

const AllocatedUsersCard = styled.div`
  z-index: 5;
  position: relative;
  background-color: #fff;
  min-width: 50%;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 3px 6px 3px rgb(0 0 0 / 15%);
  text-align: left;
  height:100%;
  max-height: 600px;
  padding-bottom: 80px;
  padding-top: 100px;
`

const AllocatedUserHeader = styled.div`
  background-color: ${props => props.theme.dialogHeaderBackgroundColour};
  padding: 20px;
  border-bottom: 3px solid ${props => props.theme.dialogHeaderHighlightColour};
  font-size: 1.4em;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position:absolute;
  width: 100%;
  top: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}){
    font-size: 1.2em;
  }
`
const AllocatedUserTitle = styled.h4`
  font-size: 1.4em;
  font-weight: bold;
  margin: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 1.2em;
  }
`

const AllocatedUsersCloseButtonContainer = styled.div`
  padding: 5px;
`

const AllocatedUsersContainerInner = styled.div`
  padding-bottom: 20px;
  width: 100%;
`

const AllocatedUsersCloseButton = styled.button`
  border: 0;
  background: none;
  color: #fff;
  padding: 0;
  font-size: 1em;
  cursor: pointer;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  &:hover {
    background-color: rgb(255 255 255 / 22%);
    border-radius: 50%;
  }
`

const AllocatedUsersContentContainer = styled.div`
  padding: 0 20px 20px 20px;
  display: flex;
  flex: 3;
  overflow-y: auto;
  height: 100%;
  width:100%;
`

const AllocatedUsersContent = styled.div`
  height: 100%;
  width: 100%;
`

const AllocatedUsersControlBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: ${props => props.theme.colors.accentColor};
  padding: 1em;
  position:absolute;
  width: 100%;
  bottom: 0;
`

const AllocatedUsersConfirmButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const AllocateToAllContainer = styled.div`
  padding: 1em;
  width: 100%;
  z-index: 4;
`

function AllocateUsers (
  {
    selectedRows,
    recordType,
    dismissPopup,
    selectedAllRows,
  }: AllocateUsersProps) {
  const [allocateToAllUser, setAllocateToAllUser] = useState<Option | null>(null)
  const [recordAllocationArr, setRecordAllocationArr] = useState<Array<AllocateRecordType>>([])
  const [users, setUsers] = useState<Array<Option>>([])
  const [numberOfRows, setNumberOfRows] = useState(0)
  const [selectedRecords, setSelectedRecords] = useState<Array<{record_id: number | string | null, name: string}>>([])

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await userService.list({
        limit: 0, offset: 0, recordType: 'site',
      })
      const options = response.data.results.map(user => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
      }))
      setUsers(options)
    }
    fetchUsers()
    setNumberOfRows(Object.keys(selectedRows).length)
  }, [])

  useEffect(() => {
    setSelectedRecords(flattenSelectedRows(selectedRows))
  }, [selectedAllRows])

  return <PopUpContainer>
    <AllocatedUsersCard aria-label='allocate-users'>
      <AllocatedUserHeader>
        <AllocatedUserTitle>
          <div>
            Allocate users
          </div>
          <div>
            {numberOfRows} {recordType} selected
          </div>
        </AllocatedUserTitle>
        <AllocatedUsersCloseButtonContainer>
          <AllocatedUsersCloseButton
            title='Close popup'
            onClick={() => dismissPopup(false)}
          >
            <FontAwesomeIcon icon={faTimes as IconProp}/>
          </AllocatedUsersCloseButton>
        </AllocatedUsersCloseButtonContainer>
      </AllocatedUserHeader>
      <AllocatedUsersContentContainer>
        <AllocatedUsersContainerInner>
          <AllocatedUsersContent>
            <AllocateToAllContainer>
              <Row align='center'>
                <Col>
                  <Title level={4} marginTop='0'>Allocate to all</Title>
                </Col>
                <Col>
                  <SPUDUserAutocomplete
                    selectedOption={allocateToAllUser}
                    selectOptionCallback={(user) => {
                      setAllocateToAllUser(user)
                      if (user && 'id' in user) {
                        const recordsToAllocate: Array<AllocateRecordType> = []
                        selectedRecords.forEach((record) => {
                          recordsToAllocate.push({
                            record_id: record.record_id,
                            allocated_user_id: user.id,
                          })
                        })
                        setRecordAllocationArr(recordsToAllocate)
                      } else {
                        setRecordAllocationArr([])
                      }
                    }}
                    users={users}
                    // setting the unique id to 0 as recordIds
                    // will be used for the records,
                    // and no record will have the id of 0
                    uniqueId={0}
                  />
                </Col>
              </Row>
            </AllocateToAllContainer>
            <Row>
              <AllocateRecordsTable
                selectedRecords={selectedRecords}
                allocateToAllUser={allocateToAllUser}
                users={users}
                callback={(selectedUsers) => {
                  let allocatedToAllRecords: AllocateRecordType[]
                  let notAllocatedToAllRecords: AllocateRecordType[]
                  if (allocateToAllUser) {
                    // if the allocated to all record is selected,
                    // we need to separate that out from the individual row changes
                    // then find the difference and set the new list.
                    // the reason for this is to ensure that any rows
                    // that were set with the allocated to all user doesn't get lost
                    // as selectedUsers doesn't include information
                    allocatedToAllRecords = recordAllocationArr.filter(
                      record => record.allocated_user_id === allocateToAllUser.id,
                    )
                    notAllocatedToAllRecords = selectedUsers.filter(
                      record => record.allocated_user_id !== allocateToAllUser.id,
                    )
                    const diff = allocatedToAllRecords.filter(allocatedToAllRecord => {
                      return !notAllocatedToAllRecords.some(notAllocatedToAllRecord => {
                        return allocatedToAllRecord.record_id === notAllocatedToAllRecord.record_id
                      })
                    })
                    setRecordAllocationArr([...notAllocatedToAllRecords, ...diff])
                  } else {
                    setRecordAllocationArr(selectedUsers)
                  }
                }}
              />
            </Row>
          </AllocatedUsersContent>
        </AllocatedUsersContainerInner>
      </AllocatedUsersContentContainer>
      <AllocatedUsersControlBar>
        <AllocatedUsersConfirmButtonGroup>
          <Button
            active={true}
            onClick={async () => {
              const response = await allocateUsers(recordAllocationArr)
              if (response) {
                dismissPopup(true)
              }
            }}
          >
            Allocate
          </Button>
        </AllocatedUsersConfirmButtonGroup>
      </AllocatedUsersControlBar>
    </AllocatedUsersCard>
  </PopUpContainer>
}

export default AllocateUsers
