import React from 'react'
import styled from 'styled-components'
import BulkUpdateContextProvider from './BulkUpdateContextProvider'
import BulkUpdateApp from './BulkUpdateApp'
import { RouteComponentProps } from 'react-router'

const BulkAuoUpdatePage = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  background: #F4F6F9;
`

type matchParams = {
  access_code?: string | undefined
}

function BulkAutoUpdate (props: RouteComponentProps<matchParams>) {
  return <BulkAuoUpdatePage>
    <BulkUpdateContextProvider>
      <BulkUpdateApp accessCode={props.match.params?.access_code} />
    </BulkUpdateContextProvider>
  </BulkAuoUpdatePage>
}

export default BulkAutoUpdate
