import { FormConfig, Payload } from './AddEditRecord.type'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListCheck } from '@fortawesome/free-solid-svg-icons'
import { validateABN } from '../../helpers/record'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const ORGANISATION_FORM_ELEMENTS: Array<FormConfig> = [
  {
    title: 'Essential',
    ref: React.createRef(),
    icon: <FontAwesomeIcon icon={faListCheck as IconProp} />,
    fields: [
      {
        name: 'date_last_updated',
        label: 'Last updated date',
        type: 'hidden',
        defaultValue: '',
        rules: { required: false },
        allowAcceptServiceProviderChange: false,
      },
      {
        name: 'name',
        label: 'Organisation Name',
        type: 'text',
        rules: { required: true },
        highlight: true,
        hideAsterisk: true,
        defaultValue: '',
        allowAcceptServiceProviderChange: false,
      },
      {
        name: 'abn',
        label: 'ABN',
        type: 'text',
        mask: '99 999 999 999',
        rules: {
          validate: (value: string) => validateABN(value) || 'The ABN entered is not valid',
          required: false,
        },
        defaultValue: '',
        allowAcceptServiceProviderChange: false,
      },
    ],
  },
]

export const ORGANISATION_FORM_DATA: Payload = {
  record_type: 'organisation',
  iss_id: null,
  status: 'draft',
  data: {},
}
