import React from 'react'
import styled from 'styled-components'

const NoRecordsMessageContainer = styled.div`
Font-weight: bold;
text-align: center;
padding: 45px;
 border-radius: 15px;
 font-size: 18px;
 color: #4f4f4f
`

type Props = {
  linkedRecord: string;
}
function NoLinkedRecords ({ linkedRecord }: Props) {
  const NoRecordsMessage = () => {
    switch (linkedRecord) {
    case 'organisation':
      return <div>There are no linked sites</div>
    case 'site':
      return <div> There are no linked services</div>

    case 'service':
      return <div>There are no additional services</div>
    default:
      return <div>There are no linked records</div>
    }
  }

  return (
    <NoRecordsMessageContainer>
      <NoRecordsMessage/>
    </NoRecordsMessageContainer>
  )
}

export default NoLinkedRecords
