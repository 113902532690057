import React from 'react'
import {
  Col,
  Container,
  Content,
  Header,
  Page,
  Section,
  Row,
  Title,
} from '@ix/ix-ui'
import { withContext } from '../../../context/AppContext'
import { useAuth } from '../../../helpers/auth'

const AdminHomePage = () => {
  const { userRole } = useAuth()

  if (userRole !== 'Administrator') {
    return (
      <>
        <Header>
          <Title level={1}>Unauthorized Access</Title>
        </Header>
        <Section>You are unauthorized to access this page.</Section>
      </>
    )
  }

  return (
    <Page>
      <Content>
        <Row style={{ padding: '1em 0 0 2em' }}>
          <Col>
            <Title level={1}>Administration</Title>
          </Col>
        </Row>
        <Section>
          <Container style={{ background: '#fff' }}>
            <Row>
              <Col>
                ...
              </Col>
            </Row>
          </Container>
        </Section>
      </Content>
    </Page>
  )
}

export default withContext(AdminHomePage)
