import * as React from 'react'
import { Row, Col, Button } from '@ix/ix-ui'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef } from 'react'
import { OutsideComponentClick } from '../../../effects/OutsideComponentClick'
import { GeographicType } from './GeogrpahicTypes'

/**
 * Styled button component for locale actions.
 */
const LocaleButton = styled(Button)`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  :hover {
    text-decoration: underline;
    font-weight: bold;
    background: none;
    border: none;
  }
  :focus {
    border: none;
    background: none;
  }
`

/**
 * Styled button component for dismiss actions.
 */
const DismissButton = styled(Button)`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  text-align: left;
  margin: 5px;
  padding: 0;
  :hover {
    text-decoration: underline;
    font-weight: bold;
    background: none;
    border: none;
  }
`

/**
 * Styled button component for adding locales.
 */
const AddLocaleButton = styled(Button)`
  background: ${props => props.theme.colors.active};
  border: none;
  cursor: pointer;
  font-size: 12px;
  text-align: left;
  margin: 5px;
  padding: 0 5px;
  :hover {
    text-decoration: underline;
    font-weight: bold;
  }
`

/**
 * Container for the add locale button.
 */
const AddLocaleButtonContainer = styled.div`
  background-color: ${(props) => props.theme.colors.grayMedium};
  border-radius: 3px;
  z-index: 3;
  position: absolute;
`

/**
 * List of Australian states.
 */
const STATES = [
  { id: 'ACT', name: 'Australian Capital Territory', location_type: 'state' },
  { id: 'NSW', name: 'New South Wales', location_type: 'state' },
  { id: 'NT', name: 'Northern Territory', location_type: 'state' },
  { id: 'QLD', name: 'Queensland', location_type: 'state' },
  { id: 'SA', name: 'South Australia', location_type: 'state' },
  { id: 'TAS', name: 'Tasmania', location_type: 'state' },
  { id: 'VIC', name: 'Victoria', location_type: 'state' },
  { id: 'WA', name: 'Western Australia', location_type: 'state' },
]

/**
 * Component for adding a locale in the SPUD application.
 *
 * @param {Object} props - Component properties.
 * @param {string} [props.locale] - The locale to be added.
 * @param {Function} props.onAddLocale - Callback function to add the locale.
 * @param {Array} props.selectedLocales - List of already selected locales.
 * @param {string} props.columnType - Type of the column (state, region, lga, etc.).
 * @param {GeographicType} props.location - Geographic location details.
 * @returns {React.ReactElement} The rendered component.
 */
function SPUDAddLocale ({
  locale,
  selectedLocales,
  onAddLocale,
  columnType,
  location,
}: {
  locale?: string
  onAddLocale: (locale: string) => void
  selectedLocales: { name: string }[],
  columnType: string,
  location: GeographicType,
}) {
  const [showAddLocale, setShowAddLocale] = React.useState(false)
  const [localeAlreadySet, setLocaleAlreadySet] = React.useState(false)
  const [parentAlreadySet, setParentAlreadySet] = React.useState(false)
  const spudAddLocaleRef = useRef(null)
  const clickedOutsideComponent = OutsideComponentClick(spudAddLocaleRef)

  /**
   * Handles the addition of a locale.
   */
  const onAdd = () => {
    locale && onAddLocale(locale)
    setShowAddLocale(false)
  }

  /**
   * Effect to check if the locale is already set.
   */
  useEffect(() => {
    if (selectedLocales.find(selectedLocale => selectedLocale.name === locale)) {
      setLocaleAlreadySet(true)
    } else {
      setLocaleAlreadySet(false)
    }
  })

  /**
   * Effect to handle clicks outside the component.
   */
  useEffect(() => {
    if (clickedOutsideComponent) {
      setShowAddLocale(false)
    }
  }, [clickedOutsideComponent])

  /**
   * Effect to check if the parent locale is already set based on the column type.
   */
  useEffect(() => {
    const stateParentSet = selectedLocales.find(
      selectedLocale => selectedLocale.name === STATES.find(
        state => state.id === location.state)?.name,
    )
    // For when you are selecting the state in the initial state screen
    const selectedState = selectedLocales.find(
      selectedLocale => selectedLocale.name === STATES.find(
        state => state.id === location.id)?.name,
    )
    const RegionParentSet = selectedLocales.find(
      selectedLocale => location.region && location.region.includes(selectedLocale.name),
    )
    const LGAParentSet = selectedLocales.find(
      selectedLocale => location.lga === selectedLocale.name,
    )
    if (columnType === 'state') {
      if (stateParentSet) {
        setLocaleAlreadySet(true)
      } else if (!stateParentSet && selectedState) {
        setLocaleAlreadySet(true)
      } else {
        setLocaleAlreadySet(false)
      }
    } else if (columnType === 'region') {
      if (stateParentSet) {
        setParentAlreadySet(true)
      } else {
        setParentAlreadySet(false)
      }
    } else if (columnType === 'lga') {
      if (stateParentSet || RegionParentSet) {
        setParentAlreadySet(true)
      } else {
        setParentAlreadySet(false)
      }
    } else if (columnType === 'suburb' || columnType === 'postcode') {
      if (stateParentSet || RegionParentSet || LGAParentSet) {
        setParentAlreadySet(true)
      } else {
        setParentAlreadySet(false)
      }
    }
  })

  return (
    <div ref={spudAddLocaleRef}>
      <LocaleButton
        onClick={() => {
          setShowAddLocale(!showAddLocale)
        }}
      >
        {locale} {
          columnType === 'suburb' && location.location_type
            ? `(${location.location_type})`
            : columnType === 'region' && location.region_type ? `(${location.region_type})` : ''
        }
      </LocaleButton>
      {showAddLocale && (
        <AddLocaleButtonContainer aria-label='add locale'>
          <Row>
            <Col
              direction='row'
              justify={localeAlreadySet ? 'center' : 'flex-end'}
              align='center'
              style={{ padding: '0' }}
            >
              <DismissButton
                onClick={() => setShowAddLocale(false)}
              >
                {localeAlreadySet ? 'Locale Set' : parentAlreadySet ? 'Parent Locale set' : 'Cancel'}
              </DismissButton>
              {!localeAlreadySet && !parentAlreadySet &&
                <AddLocaleButton active onClick={onAdd} aria-label='add'>
                  <FontAwesomeIcon icon={faPlus}/>
                </AddLocaleButton>}
            </Col>
          </Row>

        </AddLocaleButtonContainer>
      )}
    </div>
  )
}

export default SPUDAddLocale
