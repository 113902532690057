import React from 'react'
import { Page, Row, Col, Content, Button, Title } from '@ix/ix-ui'
import { Route, Redirect } from 'react-router-dom'
import { RouteProps } from 'react-router'
import { useKeycloak } from '@react-keycloak/web'
import SideNav from '../components/SideNav'
import { AppContextType } from '../context/AppContext.type'
import BackgroundTaskProgress from '../pages/RecordsPage/ListView/BackgroundTaskProgress'
import SPUDHeading from '../pages/SPUDHeading'
import { PopUpBackdrop } from '../pages/RecordsPage/ListView/ListView.service'
import { getAudience } from '../helpers/auth'
import styled from 'styled-components'

const UnAuthorisedUserContainer = styled.div`
  padding: 1em;
  border: 2px solid black;
`

const UnAuthLogoutLinkButton = styled(Button)`
  background: none;
  border: none;
  text-decoration: underline;
  padding: 0;
  :hover {
    padding: 0;
    text-decoration: none;
    border: none;
    background: none;
  };
  :focus {
    border: none;
    background: none;
    box-shadow: none;
  }
`

type Props = RouteProps & {
  appContext: AppContextType | null,
  pageType: string,
}
function PrivateRoute (props: Props): React.ReactElement {
  const { keycloak, initialized } = useKeycloak()

  const AdminMailLink = (user: {
    email: string,
    given_name: string,
  }) => 'database@infoxchange.org' +
         `?subject=${encodeURIComponent(
           `User ${user.email} is requesting access`,
         )}`

  if (!initialized) {
    return <div>Loading ... </div>
  }

  if (keycloak.authenticated && !keycloak.resourceAccess?.[getAudience()]) {
    return <UnAuthorisedUserContainer>
      <Row>
        <Col direction="row">
          <Title level={4}>
            User: <strong>{keycloak.tokenParsed.email}</strong> is unauthorised
          </Title>
        </Col>
      </Row>
      <Row>
        <Col direction="row">
          Email the system administrator to request access:
        </Col>
      </Row>
      <Row>
        <Col direction="row">
          <a href={`mailto:${AdminMailLink(keycloak.tokenParsed)}`}>database@infoxchange.org</a>
        </Col>
      </Row>
      <Row>
        <Col direction="row" justify='center'>
          <UnAuthLogoutLinkButton onClick={keycloak.logout}>
            Logout
          </UnAuthLogoutLinkButton>
        </Col>
      </Row>
    </UnAuthorisedUserContainer>
  }

  if (keycloak.authenticated) {
    sessionStorage.setItem('redirectTo', props.location?.pathname as string)
    sessionStorage.setItem('filterHash', props.location?.hash as string)
    if (props.appContext) {
      return <>
        <Page>
          <SideNav
            {...props.appContext}
            onClearFailedMessages={() => props.appContext?.clearWsMessages()}
          />
          <Content>
            <Row>
              <Col>
                <BackgroundTaskProgress
                  lastMessage={props.appContext.lastMessage as {
                    type: string
                    message: {
                      total_records: 0
                      processed: 0
                    }
                  }}
                />
                {props.pageType !== 'search' && props.pageType !== 'administration' && props.pageType !== 'user' &&
                  props.pageType !== 'email-campaign' && <SPUDHeading searchRecords={props.appContext.searchRecords} />}
                <Route {...props} />
              </Col>
            </Row>
          </Content>
        </Page>
        {props.appContext.popupType && props.appContext.displayPopUp()}
        {props.appContext.popupType &&
          <PopUpBackdrop containerHeight={`${document.documentElement.scrollHeight}px`}/>}
      </>
    } else {
      return <Route {...props} />
    }
  }

  return <Redirect to={{ pathname: '/login' }} />
}

export default PrivateRoute
