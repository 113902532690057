import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import SPUDAutoComplete from './SPUDAutoComplete'
import accreditation from '../../helpers/supplimentaryFiles/accreditation.json'
import {
  Button,
  Row,
  Col,
  Text,
  Title,
} from '@ix/ix-ui'
import styled from 'styled-components'
import { SPUDInputField } from '../../helpers/record'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { BulkServiceProviderUpdateType } from '../../services/spud.service'
import BulkUpdateRecordChangeReview from '../forms/BulkUpdateRecordChangeReview'
import { bulkUpdateRecordChangeReviewType } from '../../externalPages/BulkAutoUpdate/BulkAutoUpdateService'

const AccreditationChip = styled.div`
  border-radius: 3px;
  border: 1px solid #3a8ae8;
  padding: 5px;
  color: #4c1811;
  font-size: 14px;
  margin: 0.3em;
  background-color: #fff;
  height: 2em;
`

const AccreditationChipButton = styled.button`
  margin-left: 1em;
  border: none;
  background: none;
  cursor: pointer;
  :hover {
    background: #e3edfa;
  }
`

const AccreditationChipContainer = styled.div.attrs({
  className: 'form-accreditations',
})`
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  background-color: #f7f4f4;
  padding: 5px;
  border-radius: 3px;
  min-height: 100px;
`

const AccreditationContainer = styled.div`
    width: 100%;
    border-radius: 5px;
    margin: 10px 0;
    box-shadow: 0 0 6px 3px #eaeaea;
    padding: 1em;
`

export type SPUDAccreditationProps = {
  title: string,
  formKey: string,
  value: Array<string> | undefined,
  callback: (name: string, value: unknown) => void,
  bulkUpdateChanges: BulkServiceProviderUpdateType,
  disabled: boolean
  loadingForm: boolean,
  resetBulkUpdateRecordChanges?: boolean,
  setResetBulkUpdateRecordChanges?: Dispatch<SetStateAction<boolean>>,
}

function SPUDAccreditation (
  {
    title,
    value,
    callback,
    formKey,
    disabled,
    bulkUpdateChanges,
    loadingForm,
    resetBulkUpdateRecordChanges,
    setResetBulkUpdateRecordChanges,
  }: SPUDAccreditationProps): React.ReactElement {
  const [accreditations, setAccreditations] = useState<Array<string>>([])
  const [selectedOption, setSelectedOption] = useState<string>('')
  const [newAccreditation, setNewAccreditation] = useState<string>('')
  const [originalRecordAccreditations, setOriginalRecordAccreditations] = useState<Array<string>>([])
  const [originalAccreditationsLoaded, setOriginalAccreditationsLoaded] = useState(false)

  useEffect(() => {
    if (!loadingForm) {
      !originalAccreditationsLoaded && setOriginalRecordAccreditations(value as Array<string>)
      setOriginalAccreditationsLoaded(true)
    }
  }, [loadingForm])

  useEffect(() => {
    value && setAccreditations([...value])
  }, [value])

  const canAddAccreditation = (accreditationToAdd: string): boolean => {
    return !accreditations.some(accreditation => accreditation === accreditationToAdd)
  }

  return (
    <div>
      <Row>
        <Col>
          <Title level={4}>{title}</Title>
        </Col>
      </Row>
      <Row align='center'>
        <Col md={12}>
          <AccreditationContainer>
            <Row>
              <Col>
                <SPUDAutoComplete
                  label='Select an accreditation from the list'
                  options={accreditation.accreditation}
                  selectedOption={undefined}
                  isMulti={false}
                  onChange={(option) => {
                    if (typeof option === 'string') {
                      setNewAccreditation('')
                      setSelectedOption(option)
                    }
                  }}
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col align='center' style={{ margin: '1em 0' }}>
                <Text size='14px'>
                  Select an accreditation from the list above or enter a new one below.
                </Text>
                <Text>
                  Note: Selecting one from the list will clear any entered text.
                </Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <SPUDInputField
                  id='newAccreditation'
                  label='Enter a new Accreditation not in the list'
                  value={newAccreditation}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSelectedOption('')
                    setNewAccreditation(event.target.value)
                  }}
                  fullwidth
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Title level={4}>
                  Entered accreditations:
                </Title>
              </Col>
            </Row>
            <Row>
              <Col>
                <AccreditationChipContainer>
                  {accreditations.map((accreditation, index) => (
                    <AccreditationChip key={`${accreditation}_${index}`}>
                      {accreditation}
                      <AccreditationChipButton
                        onClick={() => {
                          const updatedAccreditations = [...accreditations]
                          updatedAccreditations.splice(index, 1)
                          setAccreditations(updatedAccreditations)
                          callback(formKey, updatedAccreditations)
                        }}
                        disabled={disabled}
                      >
                        <FontAwesomeIcon icon={faTimes as IconProp} />
                      </AccreditationChipButton>
                    </AccreditationChip>
                  ))}
                </AccreditationChipContainer>
              </Col>
            </Row>
          </AccreditationContainer>
        </Col>
      </Row>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button active={canAddAccreditation(
          selectedOption || newAccreditation)} onClick={() => {
          const newAccreditations = [...accreditations, selectedOption || newAccreditation]
          setAccreditations(newAccreditations)
          callback(formKey, newAccreditations)
          setNewAccreditation('')
        }} disabled={!canAddAccreditation(
          selectedOption || newAccreditation)}
        >
          Add accreditation
        </Button>
      </div>
      {bulkUpdateChanges && <Row>
        <Col>
          <BulkUpdateRecordChangeReview
            bulkUpdateChanges={bulkUpdateChanges}
            setValue={callback}
            fieldKey={formKey}
            fieldLabel={title}
            currentFieldValue={originalRecordAccreditations as bulkUpdateRecordChangeReviewType}
            setResetBulkUpdateRecordChanges={setResetBulkUpdateRecordChanges}
            resetBulkUpdateRecordChanges={resetBulkUpdateRecordChanges}
          />
        </Col>
      </Row>}
    </div>
  )
}

export default SPUDAccreditation
