
import styled from 'styled-components'
import React from 'react'

const TabWrapper = styled.div`
display: flex;
& button:first-child {
    border-top-left-radius: 6px;
border-bottom-left-radius: 6px;
  }
  & button:last-child {
border-top-right-radius: 6px;
border-bottom-right-radius: 6px;
    
  }
`
const TabButton = styled.button <{active: boolean, danger?:boolean}>`
  background-color: ${props => {
    if (props.active) return props.theme.colors.active
    if (props.danger) return props.theme.colors.danger
    return 'white'
  }};
  color: ${props => {
    if (props.active) return 'white'
    if (props.danger) return 'white'
    return 'black'
  }};
  border: 2px solid lightblue;
  padding: 8px 12px;
  margin: 0;
  cursor: pointer;
  outline: none;
  float: left;
  font-size: 14px;



  :hover {
   background-color: #3A8AE8;
    color: white;
  }

  @media (max-width: 768px) { 
    max-width: 100%;
  }
`
type StyledServicePanelPropsType = {

  recordId: string | undefined,
  hasExternalComments: boolean,
  recordType: string | undefined,
  currentRightPanelTab: 'linkedRecords'|'comments'|'external_comments',
  onChange: (tab:'linkedRecords'|'comments'|'external_comments') => void

}
const StyledServicePanel = ({
  recordId,
  recordType,
  onChange,
  hasExternalComments,
  currentRightPanelTab,
} : StyledServicePanelPropsType) => {
  return (
    <TabWrapper>
      <TabButton
        active={currentRightPanelTab === 'linkedRecords'}
        onClick={() => onChange('linkedRecords')}
      >
        Linked Records
      </TabButton>
      {recordId && recordType === 'site' && (
        <TabButton
          active={currentRightPanelTab === 'comments'}
          onClick={() => onChange('comments')}
        >
          Comments
        </TabButton>
      )}
      {recordId && (recordType === 'site' || recordType === 'service') && (
        <TabButton
          active={currentRightPanelTab === 'external_comments'}
          onClick={() => onChange('external_comments')}
          danger={hasExternalComments}
          title={hasExternalComments
            ? 'Has external comments'
            : 'No external comments'
          }
        >
          External comments
        </TabButton>
      )}
    </TabWrapper>
  )
}

export default StyledServicePanel
