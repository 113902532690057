import { Option } from '../../../../components/forms/AddEditRecord.type'
import { AllocateRecordType } from '../../../../services/allocation.service'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SPUDUserAutocomplete from '../../../../components/General/SPUDUserAutocomplete'
import { Title, Card } from '@ix/ix-ui'

const RecordsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const AllocatedUserTableHeading = styled.thead`
  tr {
      th {
        background-color: #fff;
        z-index: 3;
        position: -webkit-sticky;
        position: sticky;
        top: -6px;
        padding: 5px;
        border-bottom: 1px solid black;
      }
  }
`

const AllocatedUserTableBody = styled.tbody`
  tr {
     td {
      padding: 5px;
    }
    border-bottom: 1px solid #c7c7c7;
  }
`

const TableContainer = styled.div`
  padding: 0.5em;
  width: 100%;
  min-height: 100px;
`

const AllocateRecordRow = ({ record, allocateToAllUser, allocateRecordRowCallback, users }: {
  record: {record_id: string | null | number, name: string},
  allocateToAllUser: Option | null
  allocateRecordRowCallback: (userRecordToAllocate: AllocateRecordType | null) => void,
  users: Array<Option>,
}) => {
  const [selectUser, setSelectedUser] = useState<Option | null>(null)

  useEffect(() => {
    setSelectedUser(allocateToAllUser)
  }, [allocateToAllUser])

  if (record.record_id) {
    return <tr>
      <td style={{ width: '5%' }}>
        {record.record_id}
      </td>
      <td style={{ width: '50%' }}>
        {record.name}
      </td>
      <td style={{ width: '45%' }}>
        <SPUDUserAutocomplete
          selectedOption={selectUser}
          users={users}
          selectOptionCallback={(user) => {
            setSelectedUser(user)
            if (user && 'id' in user) {
              allocateRecordRowCallback({
                record_id: record.record_id,
                allocated_user_id: user.id,
              })
            } else {
              allocateRecordRowCallback(null)
            }
          }}
          uniqueId={record.record_id}
        />
      </td>
    </tr>
  }
  return null
}

const AllocateRecordsTable = (
  {
    selectedRecords,
    allocateToAllUser,
    callback,
    users,
    fetchingRecords,
  }: {
  selectedRecords: Array<{record_id: string | number | null, name: string}>,
  allocateToAllUser: Option | null
  callback: (users: Array<AllocateRecordType>) => void,
  users: Array<Option>,
  fetchingRecords?: boolean
}) => {
  const [selectedUsers, setSelectedUsers] = useState<Array<AllocateRecordType>>([])
  return <TableContainer>
    {fetchingRecords && <div style={{ textAlign: 'center' }}>
      <Card>
        Loading
      </Card>
    </div>}
    {!fetchingRecords && <RecordsTable>
      <AllocatedUserTableHeading>
        <tr>
          <th style={{ width: '5%' }}>
            <Title level={4} marginTop='0'>
              ID
            </Title>
          </th>
          <th style={{ width: '50%' }}>
            <Title level={4} marginTop='0'>
              Name
            </Title>
          </th>
          <th style={{ width: '45%' }}>
            <Title level={4} marginTop='0'>
              User to allocate
            </Title>
          </th>
        </tr>
      </AllocatedUserTableHeading>
      <AllocatedUserTableBody>
        {selectedRecords.map((row) => {
          return <>
            <AllocateRecordRow
              key={row.record_id}
              record={row}
              allocateToAllUser={allocateToAllUser}
              users={users}
              allocateRecordRowCallback={(user) => {
                if (user) {
                  const newSelectedUsers = [...selectedUsers, user]
                  setSelectedUsers(newSelectedUsers)
                  callback(newSelectedUsers)
                }
              }}
            />
          </>
        })}
      </AllocatedUserTableBody>
    </RecordsTable>}
  </TableContainer>
}

export default AllocateRecordsTable
