import React, { useState } from 'react'
import {
  Title,
} from '@ix/ix-ui'
import { searchLocation } from '../../services/location.service'
import { AsyncPaginate } from 'react-select-async-paginate'
import { Option } from '../forms/AddEditRecord.type'
import { ActionMeta, MultiValue, SingleValue } from 'react-select'
import { fetchAccessId } from '../../helpers/record'

export type LocationOptionType = {
  id: string,
  name: string,
  postcode?: string | undefined,
  state: string,
  // eslint-disable-next-line camelcase
  location_type: string,
}

type Props = {
  label: string,
  placeholder: string,
  required: boolean
  highlight: boolean
  disabled: boolean
  value?: Option,
  multiValue?: Array<Option>,
  multi: boolean,
  externalForm: boolean,
  customStyle?: { [x: string]: unknown }
  onChange?: (option: SingleValue<LocationOptionType>,
             actionMeta: ActionMeta<unknown>) => void
  onMultiChange?: (option: MultiValue<Option>,
             actionMeta: ActionMeta<unknown>) => void,
}

const DEFAULT_BORDER = '1px solid #3a8ae8'

function SPUDLocationAutocomplete (props: Props): React.ReactElement {
  const [locations, setLocations] = useState<Array<
    LocationOptionType>>([])

  const searchForLocation = async <T, >(searchTerm: string, loadedOptions: ReadonlyArray<T>) => {
    const response = await searchLocation(
      searchTerm,
      loadedOptions.length,
      '',
      ['suburbs'],
      props.externalForm ? fetchAccessId() as string : '',
    )

    // eslint-disable-next-line camelcase
    const formatName = (res: {name: string, postcode?: string, state: string, location_type: string}) => {
      if (res.postcode) {
        return `${res.name} (${res?.postcode}, ${res.state})`.toUpperCase()
      } else {
        return `${res.name} (${res?.location_type}, ${res.state})`.toUpperCase()
      }
    }
    const options = response.results
    options && setLocations([...locations, ...options])

    return {
      options: options.map(res => (
        {
          name: formatName(res),
          id: res.id,
        })),
      hasMore: !!response.next,
    }
  }

  const generateElementId = (): string => (
    `spud-location-autocomplete-${props.label}`
  )

  const onChange = (value: SingleValue<Option>) => {
    if (value) {
      const location = locations.find(location => location.id === value?.id)
      if (location) {
        props.onChange?.(location, { action: 'select-option', option: location })
      }
    } else {
      props.onChange?.(
        { id: '', name: '', state: '', postcode: '', location_type: '' },
        { action: 'select-option', option: value },
      )
    }
  }

  const onMultiChange = <T extends readonly Option[]>(
    selections: T,
    actionMeta: ActionMeta<Option>,
  ) => {
    props.onMultiChange?.(selections.map((item: Option) => {
      const location = locations.find(location => location.id === item?.id)
      if (location) {
        return {
          id: location.name,
          name: item.name,
        }
      }
      return item
    }), actionMeta)
  }

  const customStyle = {
    control: () => ({
      border: props.highlight ? !props.multi && props.value?.id ? DEFAULT_BORDER : '3px solid #ff9595' : DEFAULT_BORDER,
      borderRadius: 3,
      display: 'flex',
      padding: 1,
      marginTop: 1,
    }),
  }

  const LocationAutoCompleteLabel = () => <label htmlFor={generateElementId() || 'autocomplete'}>
    <Title level={4} marginTop='1.35em'>{props.label}</Title>
  </label>

  if (props.multi) {
    return <>
      <LocationAutoCompleteLabel />
      <AsyncPaginate
        id={generateElementId() || 'autocomplete'}
        loadOptions={searchForLocation}
        debounceTimeout={1000}
        styles={props.customStyle || customStyle}
        getOptionLabel={(option: Option) => option.name }
        getOptionValue={(option: Option) => !option?.id ? '' : option.id.toString()}
        value={props.multiValue}
        isClearable={true}
        openMenuOnClick={false}
        noOptionsMessage={() =>
          'No locations found'
        }
        isMulti={props.multi}
        onChange={onMultiChange}
        isDisabled={props.disabled}
        className='spud-autocomplete'
        classNamePrefix='spud-autocomplete'
      />
    </>
  } else {
    return <>
      <LocationAutoCompleteLabel />
      <AsyncPaginate
        id={generateElementId() || 'autocomplete'}
        loadOptions={searchForLocation}
        debounceTimeout={1000}
        styles={props.customStyle || customStyle}
        getOptionLabel={(option: Option) => option.name.toUpperCase() }
        getOptionValue={(option: Option) => !option?.id ? '' : option.id.toString()}
        value={props.value}
        isClearable={true}
        openMenuOnClick={false}
        noOptionsMessage={() =>
          'No locations found'
        }
        onChange={onChange}
        isDisabled={props.disabled}
      />
    </>
  }
}

export default SPUDLocationAutocomplete
