import React from 'react'

function ServiceIcon () {
  return <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.63 124.63">
    <title>Service [Converted]</title>
    <path
      fill='#231f20'
      fillRule='evenodd'
      // eslint-disable-next-line max-len
      d="M70.85,8.94a62.21,62.21,0,0,1,0,124.42v-7.13a55.08,55.08,0,0,0,0-110.16Zm0,124.42a62.21,62.21,0,1,1,0-124.42v7.13a55.08,55.08,0,1,0,0,110.16Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      fill='none'
      fillRule='evenodd'
      stroke='#231f20'
      strokeMiterlimit={10}
      strokeWidth='0.22px'
      // eslint-disable-next-line max-len
      d="M70.85,8.94A62.21,62.21,0,1,1,8.64,71.15,62.32,62.32,0,0,1,70.85,8.94Zm0,7.13A55.08,55.08,0,1,1,15.77,71.15,55.22,55.22,0,0,1,70.85,16.07Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      fill='#231f20'
      fillRule='evenodd'
      // eslint-disable-next-line max-len
      d="M50.9,39.26a4.68,4.68,0,1,0-4.68-4.68A4.67,4.67,0,0,0,50.9,39.26Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      fill='#231f20'
      fillRule='evenodd'
      d="M72.86,38.82a4.65,4.65,0,1,0-4.54-4.68A4.67,4.67,0,0,0,72.86,38.82Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      fill='#231f20'
      fillRule='evenodd'
      d="M91.3,59.56A3.52,3.52,0,0,0,94.75,56a3.49,3.49,0,0,0-7,0A3.53,3.53,0,0,0,91.3,59.56Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      fill='#231f20'
      fillRule='evenodd'
      stroke='#231f20'
      strokeMiterlimit={10}
      strokeWidth='0.22px'
      // eslint-disable-next-line max-len
      d="M37.94,57.18l4-13.32a6.12,6.12,0,0,1,5-4,38.55,38.55,0,0,1,7.63,0,5.76,5.76,0,0,1,5,4.18,55.93,55.93,0,0,1,2,8.14,67.13,67.13,0,0,1,1.66-7.63c1.22-2.16,2.59-4.68,5-5a28.43,28.43,0,0,1,9.86.36c3,.65,4.25,3.46,4.68,5.69,1.66,7.06,2.59,12.17,5.33,14.26,1.73,1.37,4.39-.14,6.62,0,2.66.22,3.67,2.45,3.38,5.33L98,71.73c0,1-1.66.86-1.73-.29l-.14-6.26-.65,18.58c-.29,1.58-2.66,1.37-3,0V74.1a1.47,1.47,0,0,0-2.3-.36L90,83.46a1.48,1.48,0,0,1-2.88.14l-.36-20.09c-3-2.59-5.11-9.43-7.2-17.42-.07-.36-.5.5-.58.14l-.22,36.5a2.27,2.27,0,0,1-4.32,0l-.07-20.3c0-1.08-2.81-1.73-2.81.07V83c-.36,2.52-4.1,1.87-4.32-.5V45.88c-.94-.58-2.23,5.62-4.68,14a1,1,0,0,1-1.66,0c-1.73-5.26-1.58-12.1-5-15-.72-.5-.29,1.58-.22,2.45l4.54,20.23h-4V82.82c-.22,2.59-4,2.09-4.32.29V67.48H49.25V83.1c.07,1.51-3.6,2.09-4,0V67.55H41L46.08,46.1c.29-1.08-1.15-.22-1.51.79L41,58.55C39.67,60.5,37.37,59.56,37.94,57.18Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      fill='#231f20'
      fillRule='evenodd'
      // eslint-disable-next-line max-len
      d="M33.91,91.6c-.58-3-1.37-5.26,3.53-5A34.78,34.78,0,0,1,44.86,92a41.72,41.72,0,0,0,7,5.4c1.37.94,2.52,3.1,4.82,4a18.25,18.25,0,0,1,8.42,6.77A15.6,15.6,0,0,1,67,113.63l.14,11c-7.49-.43-13.46-2.88-19.58-5.47C39.31,111.76,32,104.63,25.06,98.58c-1.66-2-1.58-4.1-1.58-6,.79-13.9-.86-20.66-2.16-30.53a5.56,5.56,0,0,1,2.23-3.82A3.17,3.17,0,0,1,27,58c2.16,2.66,3.31,6.12,4.1,9.65.72,5,1.58,13.68,1.87,18.79-.94,1.94-.5,5.4.79,6.55l4.68,4c2.66,1.58,3.6,3.31,6.55,5.76,1.08.94,4.18,4.25,3.67,3.17-3.82-4.9-5.69-7.2-9.94-9.86C37.87,95.42,34.85,93.18,33.91,91.6Z"
      transform="translate(-8.53 -8.84)"
    />
    <path
      fill='#231f20'
      fillRule='evenodd'
      // eslint-disable-next-line max-len
      d="M108,91.6c.65-3,1.37-5.26-3.53-5A34,34,0,0,0,97.06,92a42.36,42.36,0,0,1-6.91,5.4c-1.44.86-2.59,3-4.9,4a18,18,0,0,0-8.42,6.7,16.12,16.12,0,0,0-1.94,5.47l-.14,11c7.49-.43,13.46-2.81,19.58-5.4,8.28-7.42,15.62-14.62,22.54-20.59,1.73-2,1.58-4.1,1.66-6-.86-13.9.86-20.66,2.16-30.53a5.56,5.56,0,0,0-2.23-3.82A3.17,3.17,0,0,0,115,58c-2.16,2.66-3.31,6.12-4.1,9.65-.72,5-1.66,13.68-1.87,18.72.94,1.94.5,5.47-.86,6.62l-4.61,4c-2.74,1.58-3.67,3.31-6.62,5.76-1.08.86-4.1,4.25-3.67,3.17,3.82-4.9,5.69-7.2,9.94-9.94C104,95.42,107.14,93.18,108,91.6Z"
      transform="translate(-8.53 -8.84)"
    />
  </svg>
}

export default ServiceIcon
