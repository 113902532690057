import Geocode from 'react-geocode'

// Because the google maps API doesn't export place types we have to create them ourselves
// It is an incomplete version of the PlaceResult type because we only need the
// address components
/* eslint-disable camelcase */
interface GeocoderAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}
export interface PlaceResult {
  address_components?: GeocoderAddressComponent[],
  types: string[]
  name: string
}
/* eslint-enable camelcase */

const loadGeocode = () => {
  Geocode.setApiKey(window.GOOGLE_API_KEY)
}

export const fetchGeocodeFromAddress = async (
  address: string,
): Promise<{lat: string, lon: string}> => {
  loadGeocode()
  const response = (await Geocode.fromAddress(address)) as {
    results: Array<{geometry: { location: { lat: string, lng: string } } }>}
  const coords = response.results.map(result => result.geometry.location,
  )
  return {
    lat: coords[0]?.lat,
    lon: coords[0]?.lng,
  }
}

export const searchForAddress = async (inputValue: string): Promise<{
  predictions: Array<{
    // eslint-disable-next-line camelcase
    place_id: string,
    description: string,
  }>
}> => {
  const places = new window.google.maps.places.AutocompleteService()
  return await places.getPlacePredictions(
    { input: inputValue, componentRestrictions: { country: ['au', 'cx', 'nf'] } })
}
// disabled because the type of the class is unknown
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPlaceDetails = (): any => {
  return new window.google.maps.places.PlacesService(document.createElement('div'))
}
