
import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react'
import { AgeGroupType, BulkServiceProviderUpdateType, spudAPI } from '../services/spud.service'
import { Checkbox, Col, Row } from '@ix/ix-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import BulkUpdateRecordChangeReview from './forms/BulkUpdateRecordChangeReview'
import styled from 'styled-components'
import { fetchAccessId } from '../helpers/record'
import { bulkUpdateRecordChangeReviewType } from '../externalPages/BulkAutoUpdate/BulkAutoUpdateService'

const StyledCheckboxWrapper = styled.div`
      display: flex;
      align-items: center;
      gap: 8px;
      text-align: left;
    

  label {
    font-size: 14px;
  }
`

export type SPUDAgeGroupsPropTypes = {
  name: string,
  description: string,
  callback: ((name: string, value: unknown) => void) | undefined
  value: string |Array<AgeGroupType>
  formKey: string,
  bulkUpdateChanges: BulkServiceProviderUpdateType | undefined
  resetBulkUpdateRecordChanges: boolean,
  setResetBulkUpdateRecordChanges?: Dispatch<SetStateAction<boolean>>,
  externalForm: boolean,

}
export const SPUDAgeGroups = ({
  externalForm,
  name,
  description,
  value,
  callback,
  formKey,
  bulkUpdateChanges,
  resetBulkUpdateRecordChanges,
  setResetBulkUpdateRecordChanges,
}: SPUDAgeGroupsPropTypes) => {
  // // eslint-disable-next-line camelcase
  const [ageGroups, setAgeGroups] = useState<Array<AgeGroupType>>([])
  const [selectedAgeGroups, setSelectedAgeGroups] = useState(new Set())

  const handleOnChange = (key: string, checked: boolean) => {
    setSelectedAgeGroups((prevSelected) => {
      const updatedSelected = new Set(prevSelected)
      if (checked) {
        updatedSelected.add(key)
      } else {
        updatedSelected.delete(key)
      }
      callback?.(formKey, Array.from(updatedSelected))
      return updatedSelected
    })
  }
  const acceptChanges = (key: string, value: unknown) => { callback?.(key, value) }
  const getCheckValue = (checkedValue: string) => {
    if (selectedAgeGroups && selectedAgeGroups.has(checkedValue)) {
      return true
    }
    return false
  }

  useEffect(() => {
    const getAgeGroups = async () => {
      const response = await spudAPI.fetchAgeGroups(
        {
          recordType: 'service',
          limit: 20,
          offset: 0,
          accessId: externalForm ? fetchAccessId() : null,
        })
      console.log(response.data.results)
      setAgeGroups(response.data.results)
    }
    getAgeGroups()
  }, [])

  useEffect(() => {
    console.log(value)
    const existingSetAgeGroups = new Set()

    if (value && Array.isArray(value)) {
      value.forEach((ageGroup: AgeGroupType) => {
        existingSetAgeGroups.add(ageGroup?.name || ageGroup)
      })
    }
    setSelectedAgeGroups(existingSetAgeGroups)
  }, [value])

  const cRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (cRef.current) {
      const selectedAgeGroupsArr = Array.from(selectedAgeGroups)
      if (selectedAgeGroupsArr.length > 0 && selectedAgeGroupsArr.length < ageGroups.length) {
        cRef.current.indeterminate = true
      } else {
        cRef.current.indeterminate = false
        cRef.current.checked = Boolean(selectedAgeGroupsArr.length)
      }
      console.log(selectedAgeGroups, ageGroups)
    }
  }, [cRef, selectedAgeGroups])

  const handleClearSelection = () => {
    setSelectedAgeGroups(new Set())
  }
  return (
    <StyledCheckboxWrapper>
      <div>
        {name}
        {description}
        <div>
          <button onClick={handleClearSelection}
            aria-label='Clear age groups'
            style={{
              position: 'relative',
              border: '1px solid #d3d3d3',
              marginBottom: '25px',
              padding: '3px',
              margin: '4px',
              fontSize: '11px',
              top: '-2px',
            }}
          >
            <FontAwesomeIcon
              icon={faX}
              style={{
                border: 'none',
                marginRight: '5px',
                color: 'red',
              }}
            />
        Clear all
          </button>
          <input type= 'checkbox' name='ageGroupSelectAll'id='ageGroupSelectAll'
            ref={cRef}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              console.log(e.target)
              const checked = e.target.checked
              for (const ageGroup of ageGroups) {
                handleOnChange(ageGroup.name, checked)
              }
              e.target.indeterminate = false
            }}
          />
          <label htmlFor='ageGroupSelectAll'
            style={{
              position: 'relative',
              fontSize: '11px',
              top: '-3px',

            } }
          > Select All</label>
          <ul style={{ listStyle: 'none', padding: 0, margin: 0, textAlign: 'left' }}>
            {ageGroups.map((ageGroup) => (
              <li key={ageGroup.description}>
                <Checkbox
                  label={ageGroup.description}
                  id={ageGroup.description}
                  name={ageGroup.name}
                  checked={getCheckValue(ageGroup.name) || false }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleOnChange(ageGroup.name, e.target.checked)
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
        {bulkUpdateChanges && (
          <Row>
            <Col>
              <BulkUpdateRecordChangeReview
                bulkUpdateChanges={bulkUpdateChanges}
                setValue={acceptChanges}
                fieldKey='age_groups'
                fieldLabel={name}
                currentFieldValue={value as bulkUpdateRecordChangeReviewType}
                resetBulkUpdateRecordChanges={resetBulkUpdateRecordChanges}
                setResetBulkUpdateRecordChanges={setResetBulkUpdateRecordChanges}
              />
            </Col>
          </Row>
        )}
      </div>
    </StyledCheckboxWrapper>
  )
}

export default SPUDAgeGroups
