import React from 'react'
import { Row, Col } from '@ix/ix-ui'
import styled from 'styled-components'

const BulkUpdateWelcomeDivider = styled.hr`
  width: 100%
`
const BulkUpdateFinalisedContainer = styled.div`
    font-size: 12px;
    font-weight: bold;
  `
function BulkUpdateFinalised () {
  return <div>
    <Row>
      <Col>
        <Row>
          <Col>
            <BulkUpdateFinalisedContainer>
              <p>
            Thank you for completing the update form.
            The information you provided may be adjusted for consistency in presentation
            and will be available online within seven (7) days calendar days from submission.
              </p>
            </BulkUpdateFinalisedContainer>
          </Col>
        </Row>
        <Row>
          <Col>
            <BulkUpdateWelcomeDivider/>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
}

export default BulkUpdateFinalised
