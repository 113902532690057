import React, { useEffect, useState } from 'react'
import { Button } from '@ix/ix-ui'
import pluralize from 'pluralize'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

/**
 * Styled button component for loading the next location type.
 */
const NextLocationTypeButton = styled(Button)`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  z-index: 4;
  min-width: 300px;
  :hover {
    text-decoration: underline;
    font-weight: bold;
    background: none;
    border: none;
  }
  :focus {
    border: none;
    background: none;
  }
`

/**
 * Props for the SPUDLoadNextLocationTypeButton component.
 *
 * @typedef {Object} SPUDLoadNextLocationTypeButtonProps
 * @property {string} columnType - The type of the column (e.g., state, region, lga, etc.).
 * @property {Array<{ name: string, id: string }>} previousColumnValues - List of previous column values.
 * @property {number} index - The index of the current column.
 * @property {Function} onColumnChange - Callback function to handle column change.
 */
type SPUDLoadNextLocationTypeButtonProps = {
  columnType: string,
  previousColumnValues: Array<{ name: string, id: string }>,
  index: number,
  onColumnChange: (value: string) => void,
}

/**
 * Component for loading the next location type in the SPUD application.
 *
 * @param {SPUDLoadNextLocationTypeButtonProps} props - Component properties.
 * @returns {React.ReactElement} The rendered component.
 */
function SPUDLoadNextLocationTypeButton (
  {
    columnType,
    previousColumnValues,
    index,
    onColumnChange,
  }: SPUDLoadNextLocationTypeButtonProps): React.ReactElement {
  const [buttonValue, setButtonValue] = useState({ name: '', id: '' })

  /**
   * Effect to set the button value based on the previous column values.
   */
  useEffect(() => {
    if (previousColumnValues?.[index]) {
      setButtonValue(previousColumnValues?.[index])
    }
  }, [previousColumnValues])

  return (
    <div>
      <NextLocationTypeButton
        onClick={() => {
          if (columnType !== 'Region') {
            onColumnChange(buttonValue.name)
          } else {
            onColumnChange(buttonValue.id)
          }
        }}
      >
        {buttonValue.name} {pluralize(columnType)} <FontAwesomeIcon icon={faArrowRight} />
      </NextLocationTypeButton>
    </div>
  )
}

export default SPUDLoadNextLocationTypeButton
