import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import {
  constructDefaultFormData,
  fetchCorrectComponent, formatErrorMessage,
  getFormConfig,
  hasFormBeenSubmitted,
  SPUDToggle,
  validateSections,
} from '../../helpers/record'
import { Accordion, Alert, Title, Row, Col, Button } from '@ix/ix-ui'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import { SPUDServiceRecordDetails } from '../../../@types/Service.type'
import SPUDTextArea from '../../components/General/SPUDTextArea'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { DialogOptions } from '../../context/AppContext.type'
import * as _ from 'lodash'
import { objectDifference, OMIT_FIELDS_FOR_CHECK } from './BulkAutoUpdateService'
import debounce from 'lodash.debounce'
import { SPUDOrganisationRecordDetails } from '../../../@types/Organisation.type'
import { BulkAutoUpdateRecord } from './BulkUpdateContext.type'
import ReactSwitch from 'react-switch'

const BulkAutoUpdateAdditionalControls = styled.div`
  padding: 1em;
`

type BulkAutoUpdateFormProps<T extends SPUDSiteRecordDetails | SPUDServiceRecordDetails> = {
  recordId: string,
  recordType: string,
  formData: T,
  originalData: T,
  contextUpdater: (
    data: T | Record<string, never>,
    comment: string,
    confirm: boolean,
    noChanges: boolean,
    deleted: boolean,
  ) => void,
  onNext: () => void
  onPrevious?: () => void,
  confirmed: boolean
  comment: string
  activeServiceIndexContext: number | null,
  noChanges: boolean,
  willBeDeleted: boolean,
  isNewService: boolean,
  setDialogOptions: Dispatch<SetStateAction<DialogOptions>>
  organisationData?: BulkAutoUpdateRecord<SPUDOrganisationRecordDetails> | Record<string, never>
  resetOrganisation?: () => void,
  resetSiteName?: () => void,
  siteData?: T
}

function BulkAutoUpdateForm (
  {
    recordId,
    recordType,
    formData,
    originalData,
    contextUpdater,
    onNext,
    onPrevious,
    confirmed,
    comment,
    noChanges,
    willBeDeleted,
    isNewService,
    setDialogOptions,
    organisationData,
    resetOrganisation,
    resetSiteName,
    siteData,
  }
  // eslint-disable-next-line
  : BulkAutoUpdateFormProps<any>) {
  const [currentFormData, setCurrentFormData] = useState<
    SPUDSiteRecordDetails | SPUDServiceRecordDetails | Record<string, never>>()

  const [warningDisplayed, setWarningDisplayed] = useState(false)

  const methods = useForm({
    defaultValues: constructDefaultFormData(recordType, true),
    mode: 'all',
  })

  const fillForm = (
    formContent: SPUDSiteRecordDetails | SPUDServiceRecordDetails | Record<string, never>,
  ) => {
    for (const formKey of Object.keys(formContent) as Array<
      keyof SPUDSiteRecordDetails> & Array<
      keyof SPUDServiceRecordDetails>) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      methods.setValue(formKey, formContent[formKey])
    }
  }

  const hasNoChanges = () => {
    const currentValues = { ...methods.getValues() }
    const originalValues = { ...originalData }
    let diff = _.omit(objectDifference(originalValues, currentValues), OMIT_FIELDS_FOR_CHECK)
    if (recordType === 'site') {
      const currentOrgValues = { ...organisationData?.currentData.update.data }
      const originalOrgValues = { ...organisationData?.originalData.update.data }
      const orgDiff = objectDifference(originalOrgValues, currentOrgValues)
      diff = {
        ...orgDiff,
        ...diff,
      }
    }
    return Object.keys(diff).length === 0
  }

  const isConfirmed = (updatedComment: string, hasChanges: () => boolean) => {
    // has no changes and is confirmed
    if (hasChanges() && methods.getValues('bulk_update_confirm_no_changes')) {
      return true
    // has changes
    } else if (!hasChanges()) {
      return true
    // has a comment and confirmed no changes
    } else if (!!updatedComment && methods.getValues('bulk_update_confirm_no_changes')) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (typeof formData !== 'undefined' && formData !== null) {
      setCurrentFormData(formData)
      fillForm(formData)
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setCurrentFormData(constructDefaultFormData(recordType, true) as any)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fillForm(constructDefaultFormData(recordType, true) as any)
      methods.reset()
      contextUpdater(
        methods.getValues() as SPUDSiteRecordDetails | SPUDServiceRecordDetails | Record<string, never>,
        methods.getValues('bulk_update_comment'),
        isConfirmed(methods.getValues('bulk_update_comment'), hasNoChanges),
        noChanges,
        willBeDeleted,
      )
    }
  }, [formData, recordType])

  const resetForm = (resetConfirmNoChanges = false, resetDeleteRecord = false) => {
    methods.setValue?.('bulk_update_confirm_no_changes', resetConfirmNoChanges)
    methods.setValue?.('bulk_update_delete', resetDeleteRecord)
    if (originalData) {
      fillForm(originalData)
      setCurrentFormData(originalData)
      contextUpdater(originalData, '', true, resetConfirmNoChanges, resetDeleteRecord)
      if (recordType === 'site') {
        resetOrganisation?.()
        resetSiteName?.()
      }
    }
  }

  const onConfirm = () => {
    resetForm(true)
    setWarningDisplayed(false)
  }

  const onDismiss = () => {
    methods.setValue('bulk_update_confirm_no_changes', false)
    contextUpdater(
      methods.getValues(),
      methods.getValues('bulk_update_comment'),
      true,
      false,
      methods.getValues('bulk_update_delete'),
    )
    setWarningDisplayed(false)
  }

  const changesAfterConfirm = () => {
    if (!hasNoChanges()) {
      setWarningDisplayed(true)
      window.scrollTo(
        0,
        0,
      )
      setDialogOptions({
        onConfirm,
        onDismiss,
        title: 'Changes',
        description: 'You have already edited this form,' +
          ' are you sure you want to remove the information ' +
          'you have edited and put the form back to it\'s original state?',
        show: true,
        type: 'confirm',
      })
    }
  }

  const updateContext = useCallback(
    debounce((values, updatedComment, hasChanges) => {
      contextUpdater(
        values as SPUDSiteRecordDetails | SPUDServiceRecordDetails | Record<string, never>,
        updatedComment,
        isConfirmed(updatedComment, hasChanges),
        methods.getValues('bulk_update_confirm_no_changes'),
        methods.getValues('bulk_update_delete'),
      )
    }, 1000),
    [],
  )

  useEffect(() => {
    if (recordType === 'service' && methods.formState.isDirty) {
      contextUpdater(
        methods.getValues() as SPUDSiteRecordDetails | SPUDServiceRecordDetails | Record<string, never>,
        methods.getValues('bulk_update_comment'),
        isConfirmed(methods.getValues('bulk_update_comment'), hasNoChanges),
        noChanges,
        methods.getValues('bulk_update_delete'),
      )
    } else if (recordType === 'site' && methods.formState.isDirty) {
      updateContext(methods.getValues(), methods.getValues('bulk_update_comment'), hasNoChanges)
    }
    if (
      noChanges &&
      !hasNoChanges() &&
      methods.getValues('bulk_update_confirm_no_changes') &&
      !warningDisplayed
    ) {
      methods.setValue('bulk_update_confirm_no_changes', false)
      contextUpdater(
        methods.getValues(),
        methods.getValues('bulk_update_comment'),
        isConfirmed(methods.getValues('bulk_update_comment'), hasNoChanges),
        false,
        methods.getValues('bulk_update_delete'),
      )
      setWarningDisplayed(true)
    }
  }, [methods.getValues()])

  useEffect(() => {
    !comment && methods.setValue('bulk_update_confirm_no_changes', noChanges)
    methods.setValue('bulk_update_delete', willBeDeleted)
    methods.setValue('bulk_update_comment', comment)
  }, [noChanges, recordId, comment, willBeDeleted])

  const canContinue = () => {
    let valid = false
    if (isNewService) {
      valid = !!methods.getValues('name')
    } else if (methods.getValues('bulk_update_delete')) {
      valid = true
    } else {
      const confirmNoChanges = methods.getValues('bulk_update_confirm_no_changes')
      if (!hasNoChanges()) {
        valid = true
      } else if (confirmNoChanges && hasNoChanges()) {
        valid = true
      } else if (comment && confirmNoChanges) {
        valid = true
      }
    }
    return valid
  }

  const proceed = () => {
    if (!hasNoChanges()) {
      contextUpdater(
        methods.getValues(),
        methods.getValues('bulk_update_comment') || comment,
        true,
        false,
        methods.getValues('bulk_update_delete'),
      )
    } else {
      contextUpdater(
        methods.getValues(),
        methods.getValues('bulk_update_comment') || comment,
        true,
        !methods.getValues('bulk_update_delete'),
        methods.getValues('bulk_update_delete'),
      )
    }
    onNext?.()
  }

  const forceChange = (key: string, value: string) => {
    methods.setValue(key, value)
    methods.trigger(key)
    contextUpdater(
        methods.getValues() as SPUDSiteRecordDetails | SPUDServiceRecordDetails | Record<string, never>,
        methods.getValues('bulk_update_comment'),
        isConfirmed(methods.getValues('bulk_update_comment'), hasNoChanges),
        noChanges,
        methods.getValues('bulk_update_delete'),
    )
  }

  return <FormProvider {...methods}>
    <form onSubmit={(evt) => evt.preventDefault()}>
      {
        getFormConfig(recordType)?.length &&
        getFormConfig(recordType)?.map((formGroup, index) => (
          !formGroup.hide &&
          <Accordion
            padding='0 0 10px 10px'
            heading={formGroup.title}
            key={`${formGroup.title}_${index}`}
            innerRef={formGroup.ref}
            status={validateSections(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              methods.formState.errors,
              formGroup, hasFormBeenSubmitted(recordId, methods),
            )}
            defaultOpen={true}
            scrollIntoViewOnOpen
          >
            <>
              {formGroup.fields.map((formElement, index) => (
                formElement.serviceProviderEditable && currentFormData && <>
                  <Controller
                    key={`${formElement.name}-${index}`}
                    name={formElement.name}
                    control={methods.control}
                    rules={{ ...formElement.rules }}
                    render={({ field }) =>
                      fetchCorrectComponent(formElement,
                        !recordId,
                        'public',
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        currentFormData as SPUDSiteRecordDetails | SPUDServiceRecordDetails,
                        recordType,
                        true,
                        field,
                        methods.getValues,
                        forceChange,
                        siteData,
                      )
                    }
                  />
                  {formElement?.name &&
                  methods.formState.errors?.[formElement?.name] &&
                  <span>
                    <Alert type="error">
                      {formatErrorMessage(methods, formElement.label, formElement.name)}
                    </Alert>
                  </span>
                  }
                </>
              ))}
            </>
          </Accordion>
        ))
      }
      <BulkAutoUpdateAdditionalControls>
        <Row>
          <Col>
            <Controller
              name={'bulk_update_comment'}
              control={methods.control}
              render={({ field }) => (
                <SPUDTextArea
                  field={field}
                  label={'Comment'}
                  highlight={false}
                  canShowLineBreaks={false}
                  minHeight='150px'
                  disabled={false}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    methods.setValue?.(field.name, event.target.value)
                    contextUpdater(
                      methods.getValues() as SPUDSiteRecordDetails | SPUDServiceRecordDetails | Record<string, never>,
                      event.target.value,
                      confirmed,
                      noChanges,
                      methods.getValues('bulk_update_delete'),
                    )
                    methods.trigger(field.name)
                  }}
                />
              )}
            />
            {!isNewService &&
              <Controller
                name={'bulk_update_confirm_no_changes'}
                control={methods.control}
                render={({ field }) => (
                  <SPUDToggle
                    padding='0'
                    label={<Title level={4}>
                      All information on file is correct as originally presented above and no changes are required
                    </Title>}
                    checked={methods.getValues?.(field.name)}
                    onChange={(checked: boolean) => {
                      methods.setValue?.(field.name, checked)
                      changesAfterConfirm()
                      methods.trigger(field.name)
                    }}
                    onKeyDown={(key: React.KeyboardEvent<HTMLInputElement>) => {
                      const checked = methods.getValues?.(field.name)
                      if (key.key === 'Enter') {
                        methods.setValue?.(field.name, checked)
                        changesAfterConfirm()
                        methods.trigger(field.name)
                      }
                    }}
                    offColor="#E3EDFA"
                    onBlur={field?.onBlur}
                    // Typecasting because the controller ref is a callback while the switch is an Object
                    ref={field.ref as React.RefCallback<ReactSwitch> & React.RefObject<ReactSwitch>}
                    name={field?.name}
                  />)}
              />
            }
            {recordType === 'service' && !isNewService &&
              <Controller
                name={'bulk_update_delete'}
                control={methods.control}
                render={({ field }) => (
                  <SPUDToggle
                    padding='0'
                    label={<Title level={4}>
                      Delete this service
                    </Title>}
                    checked={methods.getValues?.(field.name)}
                    onChange={(checked: boolean) => {
                      methods.setValue?.(field.name, checked)
                      methods.trigger(field.name)
                    }}
                    onKeyDown={(key: React.KeyboardEvent<HTMLInputElement>) => {
                      const checked = methods.getValues?.(field.name)
                      if (key.key === 'Enter') {
                        methods.setValue?.(field.name, !checked)
                        methods.trigger(field.name)
                      }
                    }}
                    offColor="#E3EDFA"
                    onBlur={field?.onBlur}
                    // Typecasting because the controller ref is a callback while the switch is an Object
                    ref={field.ref as React.RefCallback<ReactSwitch> & React.RefObject<ReactSwitch>}
                    name={field?.name}
                  />)}
              />}
          </Col>
          <Row>
            <Col direction='row' justify='space-between'>
              <div>
                {recordType === 'service' &&
                  <Button onClick={onPrevious}>
                    <FontAwesomeIcon icon={faArrowLeft as IconProp}/> Update site
                  </Button>}
                <Button onClick={() => resetForm()}>
                  Reset
                </Button>
              </div>
              <div>
                <Button
                  onClick={proceed}
                  disabled={!canContinue()}
                  active={canContinue()}
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        </Row>
      </BulkAutoUpdateAdditionalControls>
    </form>
  </FormProvider>
}

export default BulkAutoUpdateForm
