import { ParseResult } from 'papaparse'
import * as _ from 'lodash'
import { Dictionary } from 'express-serve-static-core'
import { DuplicateRowType, DuplicateType } from '../../../../services/spud.service'

const ORG_DUPE_TABLE = [
  {
    key: 'rowNo',
    name: 'Row #',
  },
  {
    key: 'recordId',
    name: 'Organisation Id',
  },
  {
    key: 'name',
    name: 'Organisation Name',
  },
]

const SITE_DUPE_TABLE = [
  {
    key: 'rowNo',
    name: 'Row #',
  },
  {
    key: 'recordId',
    name: 'Site Id',
  },
  {
    key: 'name',
    name: 'Site Name',
  },
  {
    key: 'address',
    name: 'Address',
  },
]

const SERVICE_DUPE_TABLE = [
  {
    key: 'rowNo',
    name: 'Row #',
  },
  {
    key: 'recordId',
    name: 'Service Id',
  },
  {
    key: 'name',
    name: 'Service Name',
  },
  {
    key: 'site',
    name: 'Site Id',
  },
]

export const fetchDupeHeadings = (recordType: string, duplicateCheckType: 'pre'|'post') => {
  switch (recordType) {
  case 'organisation':
    if (duplicateCheckType === 'post') {
      return [
        ...ORG_DUPE_TABLE,
        {
          key: 'matchingRowElm',
          name: 'Matching record(s) #',
        },
        {
          key: 'duplicate_type',
          name: 'Duplicate type',
        },
        {
          key: 'duplicate_source',
          name: 'Duplicate source',
        },
      ]
    }
    return [
      ...ORG_DUPE_TABLE,
      {
        key: 'matchingRow',
        name: 'Matching Row(s) #',
      },
    ]
  case 'service':
    if (duplicateCheckType === 'post') {
      return [
        ...SERVICE_DUPE_TABLE,
        {
          key: 'matchingRowElm',
          name: 'Matching record(s) #',
        },
        {
          key: 'duplicate_type',
          name: 'Duplicate type',
        },
        {
          key: 'duplicate_source',
          name: 'Duplicate source',
        },
      ]
    }
    return [
      ...SERVICE_DUPE_TABLE,
      {
        key: 'matchingRow',
        name: 'Matching Row(s) #',
      },
    ]
  default:
    if (duplicateCheckType === 'post') {
      return [
        ...SITE_DUPE_TABLE,
        {
          key: 'matchingRowElm',
          name: 'Matching record(s) #',
        },
        {
          key: 'duplicate_type',
          name: 'Duplicate type',
        },
        {
          key: 'duplicate_source',
          name: 'Duplicate source',
        },
      ]
    }
    return [
      ...SITE_DUPE_TABLE,
      {
        key: 'matchingRow',
        name: 'Matching Row(s) #',
      },
    ]
  }
}

const constructAddressDupeRowValue = (dupeRecord: {[x: string]: unknown}, joiner = ' ') => {
  return `${dupeRecord?.['location.building_name'] || ''}${joiner}` +
    `${dupeRecord?.['location.flat_unit'] || ''}${joiner}` +
    `${dupeRecord?.['location.floor_level'] || ''}${joiner}` +
    `${dupeRecord?.['location.postcode'] || ''}${joiner}` +
    `${dupeRecord?.['location.state'] || ''}${joiner}` +
    `${dupeRecord?.['location.street_name'] || ''}${joiner}` +
    `${dupeRecord?.['location.street_suffix'] || ''}${joiner}` +
    `${dupeRecord?.['location.street_type'] || ''}${joiner}` +
    `${dupeRecord?.['location.suburb'] || ''}`
}

const fetchMatchingRows = (
  dupeGroup: Dictionary<unknown[]>,
  dupeRecord: {[x: string]: unknown},
  dupeKey: string,
  csvData: Array<{[x: string]: unknown}>) => {
  let matchedRecordWithGroupInstance
  if (dupeKey === 'address') {
    matchedRecordWithGroupInstance = dupeGroup?.[constructAddressDupeRowValue(dupeRecord, '+')]
  } else {
    const dupeRecordValue = dupeRecord[dupeKey] as string
    matchedRecordWithGroupInstance = dupeGroup?.[dupeRecordValue]
  }
  let matchedRows: Array<number> = []
  if (matchedRecordWithGroupInstance) {
    const matchingRows = matchedRecordWithGroupInstance.filter(dupe => dupe !== dupeRecord)
    matchedRows = []
    matchingRows.forEach((row: unknown) => {
      matchedRows.push(csvData.findIndex(
        (data: { [x: string]: unknown }) =>
          data === row,
      ) + 2)
    })
  }
  return `[${matchedRows.join(', ')}]`
}

const createDupeRow = (
  dupeRecord: {[x: string]: unknown },
  recordType: string,
  csvData: Array<{[x: string]: unknown}>,
  dupeGroup: Dictionary<unknown[]>,
  dupeKey: string,
): DuplicateRowType => {
  const rowIndex = csvData.findIndex(
    (data: {[x: string]: unknown}) =>
      data === dupeRecord,
  )
  let duplicateRowObj = {
    rowNo: rowIndex + 2,
    spud_id: dupeRecord?.spud_id || '',
    recordId: dupeRecord?.iss_id || (dupeRecord?.spud_id && `SP_${dupeRecord?.spud_id}`) || '',
    name: dupeRecord?.name,
    matchingRow: fetchMatchingRows(dupeGroup, dupeRecord, dupeKey, csvData),
  }
  if (recordType === 'site') {
    duplicateRowObj = {
      ...duplicateRowObj,
      ...{
        address: constructAddressDupeRowValue(dupeRecord),
      },
    }
  } else if (recordType === 'service') {
    duplicateRowObj = {
      ...duplicateRowObj,
      ...{
        site: dupeRecord?.site,
      },
    }
  }
  return duplicateRowObj as DuplicateRowType
}

const locationMatchString = (item: {[x: string]: unknown }): string => {
  return `${item?.['location.building_name'] || ''}+` +
    `${item?.['location.flat_unit'] || ''}+` +
    `${item?.['location.floor_level'] || ''}+` +
    `${item?.['location.postcode'] || ''}+` +
    `${item?.['location.state'] || ''}+` +
    `${item?.['location.street_name'] || ''}+` +
    `${item?.['location.street_suffix'] || ''}+` +
    `${item?.['location.street_type'] || ''}+` +
    `${item?.['location.suburb'] || ''}`
}

const fetchCorrectDuplicateCheck = (
  parsedCsvResults: ParseResult<unknown>,
  recordType: string,
): Array<{
    key: string,
    values: Dictionary<unknown[]>
  }> => {
  const duplicateCheck = [
    {
      key: 'spud_id',
      values: _.groupBy(parsedCsvResults.data, 'spud_id'),
    },
    {
      key: 'iss_id',
      values: _.groupBy(parsedCsvResults.data, 'iss_id'),
    },
  ]

  if (recordType === 'site') {
    duplicateCheck.push({
      key: 'name',
      values: _.groupBy(parsedCsvResults.data, 'name'),
    })
    duplicateCheck.push({
      key: 'address',
      values: _.groupBy(parsedCsvResults.data, (item: {[x: string]: unknown}) => locationMatchString(item)),
    })
  } else if (recordType === 'organisation') {
    duplicateCheck.push({
      key: 'name',
      values: _.groupBy(parsedCsvResults.data, 'name'),
    })
  }
  return duplicateCheck
}

export const reportDuplicates = (
  parsedCsvResults: ParseResult<unknown>,
  recordType: string): {
  duplicateGroups: {[x: string]: Array<DuplicateRowType> },
  numberOfDuplicateRows: number,
  duplicateReport: Array<{[x: string]: unknown}>
} => {
  let dupeGroups: DuplicateType = {}
  let dupeCount = 0
  const duplicateReport: Array<{[x: string]: unknown}> = []
  const numberOfDuplicateRows: Array<number> = []
  const duplicateGroupMatch: Array<{
    key: string,
    values: Dictionary<unknown[]>
  }> = fetchCorrectDuplicateCheck(parsedCsvResults, recordType)
  for (const duplicateGroup of duplicateGroupMatch) {
    if (parsedCsvResults.meta.fields?.includes(duplicateGroup.key) || (parsedCsvResults.meta.fields?.includes(
      'location.building_name' ||
      'location.flat_unit' ||
      'location.floor_level' ||
      'location.postcode' ||
      'location.state' ||
      'location.street_name' ||
      'location.street_suffix' ||
      'location.street_type' ||
      'location.suburb',
    ) && duplicateGroup.key === 'address')) {
      Object.entries(duplicateGroup.values).forEach(([key, value]) => {
        if (key) {
          let duplicates: Array<DuplicateRowType> = []
          if (Array.isArray(value) && value.length > 1) {
            duplicates = [...duplicates, ...value.map(
              dupeRow => {
                const duplicateRowItem = createDupeRow(
                  dupeRow,
                  recordType,
                  parsedCsvResults.data as Array<DuplicateRowType>,
                  duplicateGroup.values,
                  duplicateGroup.key,
                )
                if (!numberOfDuplicateRows.includes(duplicateRowItem.rowNo as number)) {
                  numberOfDuplicateRows.push(duplicateRowItem.rowNo as number)
                }
                duplicateReport.push({ ...duplicateRowItem, reason: duplicateGroup.key })
                return duplicateRowItem
              },
            )]
            dupeCount += 1
            dupeGroups = {
              ...dupeGroups,
              ...{
                [`${dupeCount}-${duplicateGroup.key}`]: duplicates,
              },
            }
          }
        }
      })
    }
  }
  return {
    duplicateGroups: dupeGroups,
    numberOfDuplicateRows: numberOfDuplicateRows.length,
    duplicateReport: duplicateReport,
  }
}

export const preProcessInstructions = (recordType: string): string => {
  switch (recordType) {
  case 'organisation':
    return 'Note: the file will be checked for any duplicate Org IDs or Org Names prior to import.'
  case 'site':
    return 'Note: the file will be checked for any duplicate Site IDs, Site Names or Site Addresses prior to import.'
  case 'service':
    return 'Note: the file will be checked for any duplicate Service IDs or linked Site IDs prior to import.'
  default:
    return ''
  }
}
