import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Row, Col, Text, Checkbox, Accordion, Title } from '@ix/ix-ui'
import { FormConfig /*, Option */ } from '../../../../components/forms/AddEditRecord.type'
import FilterChips from '../../../RecordFilter/FilterChips'
import {
  AdvancedFilterType,
  quickFilterPresetToObj,
  QuickFilterPresetType,
} from '../../../RecordFilter/RecordFilter.service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { downloadCSV } from '../../../../services/spud.service'
import pluralize from 'pluralize'
import { SPUDToggle } from '../../../../helpers/record'
import { useAuth } from '../../../../helpers/auth'
import toast from 'react-hot-toast'
import SPUDToastNotification from '../../../../components/General/SPUDToastNotification'

interface SiteMultiFields {
  [key: string]: {
    fields: string[];
  };
}

const SITE_MULTI_FIELDS: SiteMultiFields = {
  location: {
    fields: [
      'flat_unit', 'floor_level', 'building_name',
      'street_number', 'street_name', 'street_type', 'street_suffix',
      'suburb', 'state', 'postcode', 'confidential', 'geo_point',
    ],
  },
  postal_addresses: {
    fields: [
      'address_line_1', 'address_line_2', 'suburb', 'state',
      'postcode', 'confidential',
    ],
  },
  emails: {
    fields: [
      'email', 'confidential', 'comment',
    ],
  },
  phones: {
    fields: [
      'number', 'phone_type', 'confidential', 'comment',
    ],
  },
  social_media: {
    fields: [
      'url', 'type',
    ],
  },
  opening_hours: {
    fields: [
      'day', 'open', 'close', 'note',
    ],
  },
}

type ExportProps = {
  recordType: string
  dismissPopup: () => void,
  popupEntry?: string | null,
  pageFilters?: {[x: string]: AdvancedFilterType},
  filterQuery?: string,
  total: number,
  selectedRows: Array<{[x: string]: unknown}> | null,
  headers: Array<FormConfig> | null,
}

const ExportCard = styled.div`
  z-index: 5;
  position: fixed;
  background-color: #fff;
  width: 60%;
  height: 80%;
  top: 10%;
  border-radius: 3px;
  box-shadow: 0 3px 6px 3px rgb(0 0 0 / 15%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
`

const ExportHeader = styled.div`
  background-color: ${props => props.theme.dialogHeaderBackgroundColour};
  padding: 1em;
  border-bottom: 3px solid ${props => props.theme.dialogHeaderHighlightColour};
  font-size: 1.4em;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}){
    font-size: 1.2em;
  }
`

const ExportTitle = styled.h4`
  font-size: 1.4em;
  font-weight: bold;
  margin: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 1.2em;
  }
`

const ExportCloseButtonContainer = styled.div`
  padding: 5px;
`

const ExportCloseButton = styled.button`
  border: 0;
  background: none;
  color: #fff;
  padding: 0;
  font-size: 1em;
  cursor: pointer;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  &:hover {
    background-color: rgb(255 255 255 / 22%);
  }
`

const ExportContentContainer = styled.div`
  padding: 1em;
  display: flex;
  flex: 3;
  max-height: 70vh;
  overflow-y: scroll;
`

const ExportContent = styled.div`
  height: 100%;
  width: 100%;
`

const ExportControlBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: ${props => props.theme.colors.accentColor};
  padding: 1em;
`

const ExportConfirmButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const ExportSourceContainer = styled.div`
  padding: 1em;
  width: 100%;
  z-index: 4;
`

const ExportPanelHr = styled.hr`
  width: 100%;
  height: 2px;
`

const ExportHeaderContainer = styled.div`
  padding: 1em;
  width: 100%;
  z-index: 4;
`

const ExportHeaderGridContainer = styled.div`
  display: grid;
  text-align: left;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(1em, auto);
  padding: 1em;
`

const LinkStyleButton = styled.button`
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    text-decoration: none;
  }
`

function Export (
  {
    recordType,
    dismissPopup,
    popupEntry,
    pageFilters,
    filterQuery,
    total,
    selectedRows,
    headers,
  }: ExportProps) {
  const [quickPresets] = useState<Array<QuickFilterPresetType>>([])
  const [activeFilters, setActiveFilters] = useState<{[x: string]: AdvancedFilterType}>({})
  const [selectedHeaders, setSelectedHeaders] = useState<{ [x: string]: boolean }>({})
  const [toggled, setToggled] = useState(false)
  const { user } = useAuth()

  useEffect(() => {
    if (pageFilters && Object.keys(pageFilters).length) {
      const quickPresetObj = quickFilterPresetToObj(quickPresets)
      let currentPageFilters = {}
      Object.entries(pageFilters).forEach(([pageFilter, filterValue]) => {
        if (quickPresetObj?.[pageFilter]) {
          currentPageFilters = { ...currentPageFilters, [pageFilter]: quickPresetObj[pageFilter] }
        } else {
          currentPageFilters = { ...currentPageFilters, [pageFilter]: filterValue }
        }
      })
      setActiveFilters(currentPageFilters)
    } else {
      setActiveFilters({})
    }
  }, [pageFilters])

  const exportRecords = async () => {
    // Prepare query
    let query = `?publishedOnly=${toggled}&record_type=${recordType}`
    if (filterQuery?.length !== 0) {
      query = `${query}&${filterQuery}`
    }
    if (!(query.includes('deleted-0'))) {
      query = `${query}&deleted-0=false`
    }

    let recordIds: Array<number> = []
    if (popupEntry === 'selected') {
      recordIds = selectedRows?.map((row) => row.record_id as number) ?? []
    }

    const response = await downloadCSV(
      recordType,
      query,
      {
        record_ids: recordIds,
        selected_headers: Object.keys(selectedHeaders),
        export_email: user?.username,
      },
    )
    if (response && response.data) {
      // setBackgroundTaskInProgress(true)

      toast.custom(
        <SPUDToastNotification
          title="Submitted"
          message={
            <span>Exported CSV to be emailed.</span>
          }
          success
        />,
        {
          position: 'bottom-center',
        },
      )

      dismissPopup()
    }
  }

  let source
  if (popupEntry === 'filtered') {
    if (pageFilters && Object.keys(pageFilters).length) {
      source = (
        <ExportSourceContainer>
          <Row align='center'>
            <Text padding='0 0 .5em 0'>
              Export {pluralize(recordType)} using the following filters
            </Text>
          </Row>
          <Row>
            <FilterChips
              activeFilters={activeFilters}
              onChipClick={() => {
                // TODO: make chips close popup and open filter panel
              }}
              clickable={false} // Until these chips are clickable do this to hide pointer
            />
          </Row>
          <Row>
            <SPUDToggle
              label={<Title level={4} marginTop='0px'>Published only</Title>}
              checked={toggled}
              onChange={(checked: boolean) => {
                setToggled(checked)
              }}
              offColor="#F4F6F9"
              padding="12px 0"
            />
          </Row>
          <Row align='center'>
            <Col>
              <ExportPanelHr />
            </Col>
          </Row>
        </ExportSourceContainer>
      )
    } else {
      source = (
        <ExportSourceContainer>
          <Row align='center'>
            <Text padding='0 0 .5em 0'>
              There are no filters applied
            </Text>
          </Row>
          <Row align='center'>
            <Col>
              <ExportPanelHr />
            </Col>
          </Row>
        </ExportSourceContainer>
      )
    }
  }
  if (popupEntry === 'selected') {
    if (pageFilters && Object.keys(pageFilters).length) {
      source = (
        <ExportSourceContainer>
          <Row align='center'>
            <Text padding='0 0 .5em 0'>
              Export selected {pluralize(recordType)}
            </Text>
          </Row>
          <Row>
            <SPUDToggle
              label={<Title level={4} marginTop='0px'>Published only</Title>}
              checked={toggled}
              onChange={(checked: boolean) => {
                setToggled(checked as boolean)
              }}
              offColor="#F4F6F9"
              padding="12px 0"
            />
          </Row>
          <Row align='center'>
            <Col>
              <ExportPanelHr />
            </Col>
          </Row>
        </ExportSourceContainer>
      )
    } else {
      source = (
        <ExportSourceContainer>
          <Row align='center'>
            <Text padding='0 0 .5em 0'>
              There is no selection
            </Text>
          </Row>
          <Row align='center'>
            <Col>
              <ExportPanelHr />
            </Col>
          </Row>
        </ExportSourceContainer>
      )
    }
  }

  const csvFields = (
    headers?.map((x) => x.fields.filter(
      (field) => !field.hideFromExport,
    ).flatMap(
      (field) => field.exportLabel || field.name,
    ))
  ) ?? []
  const subFields: { [key: string]: string[] } = {}
  const subIndexes: { [key: string]: number } = {}

  csvFields?.forEach(function (v1, k1) {
    v1.forEach(v2 => {
      if (v2 in SITE_MULTI_FIELDS) {
        subFields[v2] = SITE_MULTI_FIELDS[v2].fields.map(
          (field: string) => `${v2}.${field}`,
        )
        subIndexes[v2] = k1
      }
    })
  })

  for (const field in subFields) {
    const subIndex = subIndexes[field]
    csvFields[subIndex].splice(csvFields[subIndex].indexOf(field), 1, ...subFields[field])
  }

  // Add ID as its not a field in form components
  csvFields[0].unshift('id')

  const csvHeaders = csvFields?.flatMap(field => field)
  const csvFieldTypes = headers?.map((header) => header.title)

  let headerSelection
  if (csvHeaders && Object.keys(csvHeaders).length) {
    headerSelection = (
      <ExportHeaderContainer>
        <Row>
          <Col>
            <strong>
              CSV headers
              <LinkStyleButton
                onClick={() => {
                  let allHeaders = {}
                  csvHeaders.forEach((header) => {
                    allHeaders = {
                      ...allHeaders,
                      [header]: true,
                    }
                  })
                  setSelectedHeaders(allHeaders)
                }}
              >
                Select all headers
              </LinkStyleButton>
            </strong>
          </Col>
          <Col direction='row' justify='flex-end'>
            <LinkStyleButton
              onClick={() => {
                setSelectedHeaders({})
              }}
            >
              Clear all headers
            </LinkStyleButton>
          </Col>
        </Row>
        {csvFieldTypes?.map((type, index) =>
          <Accordion
            padding='10px 15px 0 0'
            heading={type}
            key={type}
            defaultOpen={true}
            scrollIntoViewOnOpen
          >
            <Row>
              <Col>
                <ExportHeaderGridContainer>
                  {csvFields?.[index].map(header =>
                    <div key={header}>
                      <Checkbox
                        label={header}
                        id={header}
                        checked={selectedHeaders?.[header] || false}
                        onChange={(checked: React.ChangeEvent<HTMLInputElement>) => {
                          const selectedHeadersCopy = selectedHeaders
                          if (!checked.target.checked) {
                            delete selectedHeadersCopy[header]
                            setSelectedHeaders({ ...selectedHeadersCopy })
                          } else {
                            setSelectedHeaders({
                              ...selectedHeaders,
                              [header]: checked.target.checked,
                            })
                          }
                        }}
                      />
                    </div>,
                  )}
                </ExportHeaderGridContainer>
              </Col>
            </Row>
          </Accordion>,
        )}
      </ExportHeaderContainer>
    )
  }

  return <ExportCard aria-label='export'>
    <ExportHeader>
      <ExportTitle>
        <div>
          Export {pluralize(recordType, total)}
        </div>
      </ExportTitle>
      <ExportCloseButtonContainer>
        <ExportCloseButton onClick={() => dismissPopup()}>
          <FontAwesomeIcon icon={faTimes as IconProp}/>
        </ExportCloseButton>
      </ExportCloseButtonContainer>
    </ExportHeader>
    <ExportContentContainer>
      <ExportContent>
        {source}
        <Row>
          {headerSelection}
        </Row>
      </ExportContent>
    </ExportContentContainer>
    <ExportControlBar>
      <ExportConfirmButtonGroup>
        <Button
          active
          onClick={() => exportRecords()}
          disabled={Object.keys(selectedHeaders).length === 0}
        >
          Export
        </Button>
      </ExportConfirmButtonGroup>
    </ExportControlBar>
  </ExportCard>
}

export default Export
