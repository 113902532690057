import React, { useEffect, useState, useCallback, Dispatch, SetStateAction } from 'react'
import {
  Title,
  Row,
  Col,
  Pagination,
  Button,
  Text,
  Skeleton,
  Card,
} from '@ix/ix-ui'
import SPUDAutoComplete from '../SPUDAutoComplete'
import { SPUDInputField } from '../../../helpers/record'
import {
  appendLgaAndRegionToResultsIfSearchIsAPostcode,
  appendRegionToResults,
  SearchLGAs,
  searchLocation,
  SearchRegion,
  SearchSuburbs,
} from '../../../services/location.service'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Option } from '../../forms/AddEditRecord.type'
import debounce from 'lodash.debounce'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SPUDSiteRecordDetails } from '../../../../@types/Site.type'
import { BulkServiceProviderUpdateType } from '../../../services/spud.service'
import BulkUpdateRecordChangeReview from '../../forms/BulkUpdateRecordChangeReview'
import { useFormContext } from 'react-hook-form'
import SPUDAddLocale from './SPUDAddLocale'
import SPUDLoadNextLocationTypeButton from './SPUDLoadNextLocationTypeButton'
import { GeographicType } from './GeogrpahicTypes'

export type SPUDGeographicCoverageProps = {
  title: string,
  recordType: string,
  formKey: string,
  highlight?: boolean,
  value: Array<string> | undefined,
  siteValues: SPUDSiteRecordDetails,
  callback: (name: string, params: Array<string>) => void,
  onFormSubmitted: boolean,
  disabled: boolean,
  bulkUpdateChanges: BulkServiceProviderUpdateType,
  resetBulkUpdateRecordChanges?: boolean,
  setResetBulkUpdateRecordChanges?: Dispatch<SetStateAction<boolean>>,
}

const STATES = [
  { id: 'ACT', name: 'Australian Capital Territory', location_type: 'state' },
  { id: 'NSW', name: 'New South Wales', location_type: 'state' },
  { id: 'NT', name: 'Northern Territory', location_type: 'state' },
  { id: 'QLD', name: 'Queensland', location_type: 'state' },
  { id: 'SA', name: 'South Australia', location_type: 'state' },
  { id: 'TAS', name: 'Tasmania', location_type: 'state' },
  { id: 'VIC', name: 'Victoria', location_type: 'state' },
  { id: 'WA', name: 'Western Australia', location_type: 'state' },
]

const FILTER_STATES = [
  { id: 'ANY', name: 'ANY' },
  { id: 'ACT', name: 'ACT' },
  { id: 'NSW', name: 'NSW' },
  { id: 'NT', name: 'NT' },
  { id: 'QLD', name: 'QLD' },
  { id: 'SA', name: 'SA' },
  { id: 'TAS', name: 'TAS' },
  { id: 'VIC', name: 'VIC' },
  { id: 'WA', name: 'WA' },
]

const LocaleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  text-decoration: underline;
  :hover {
    font-weight: bold;
  }
`

const LocaleChip = styled.div<{ hasButton: boolean }>`
  border-radius: 3px;
  border: ${props => (props.hasButton ? '1px solid #3a8ae8' : 'none')}; // No border if there's no button
  padding: 5px;
  color: ${props => (props.hasButton ? '#4c1811' : '#767676')}; // Gray if there's no button
  font-size: 14px;
  margin: 0.3em;
  background-color: #fff;
  height: 2em;
  display: inline-block;
`

const LocaleChipButton = styled.button`
  margin-left: 1em;
  border: none;
  cursor: pointer;
  :hover {
    background: #e3edfa;
  }
`

const LocaleChipContainer = styled.div<{ highlight: boolean | undefined }>`
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  background-color: #f7f4f4;
  padding: 5px;
  border: ${props => props.highlight ? '3px solid #ff9595' : 'inherit'};
  border-radius: 3px;
  width: 100%;
  min-height: 100px;
`
const SiteLocaleChipContainer = styled.div`
  background: none;
  color: #767676;
  border: none;
`

const PAGE_SIZE = 10

function SPUDGeographicCoverage (
  {
    title,
    recordType,
    value,
    callback,
    formKey,
    siteValues,
    highlight,
    disabled,
    bulkUpdateChanges,
    resetBulkUpdateRecordChanges,
    setResetBulkUpdateRecordChanges,
    onFormSubmitted = false,
  }: SPUDGeographicCoverageProps): React.ReactElement {
  const [locations, setLocations] = useState<Array<GeographicType>>(STATES)
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedState, setSelectedState] = useState('')
  const [selectedLga, setSelectedLga] = useState('')
  const [selectedRegion, setSelectedRegion] = useState('')

  const [searchTerm, setSearchTerm] = useState('')
  const [total, setTotal] = useState<number>(0)
  const [page, setPage] = useState<number>(1)

  const [filteredState, setFilteredState] = useState<Option | undefined | null>({
    id: 'ANY',
    name: 'ANY',
  })

  const [selectedLocales, setSelectedLocales] = useState<Array<{
    state: string,
    name: string,
  }>>([])

  const { getValues, setValue } = useFormContext()

  const fetchLga = async (pageOverride:number = page) => {
    setLoading(true)
    const response = await SearchLGAs(selectedState, selectedRegion, pageOverride)
    setLoading(false)
    setTotal(response.count)
    setLocations(response.results)
  }

  const fetchRegions = async (pageOverride:number = page) => {
    setLoading(true)
    const response = await SearchRegion(selectedState, pageOverride)
    setLoading(false)
    setTotal(response.count)
    setLocations(response.results)
  }

  const fetchSuburbs = async () => {
    setLoading(true)
    const response = await SearchSuburbs(selectedState, selectedLga, page)
    setLoading(false)
    setTotal(response.count)
    setLocations(response.results)
  }

  const searchForLocation = async (search: string, state: Option) => {
    if (state) {
      setLoading(true)
      const response = await searchLocation(search, (page - 1) * PAGE_SIZE, state.name)
      setLoading(false)
      // if the search term is a postcode, append the LGA and region to the results
      if (searchTerm.match(/^\d{4}$/)) {
        const results = appendLgaAndRegionToResultsIfSearchIsAPostcode(response.results)
        setTotal(response.count)
        setLocations(results.results)
      } else {
        const results = appendRegionToResults(response.results)
        setTotal(response.count)
        setLocations(results.results)
      }
    }
  }

  useEffect(() => {
    if (searchTerm !== '' && filteredState) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      search(searchTerm, filteredState)
    }
  }, [searchTerm, filteredState])

  const search = useCallback(
    debounce((search: string, state: Option) => {
      setSelectedState('')
      setSelectedRegion('')
      setSelectedLga('')
      searchForLocation(search, state)
    }, 200),
    [],
  )

  const reset = () => {
    setPage(1)
    setTotal(0)
    setSearchTerm('')
    setFilteredState({
      id: 'ANY',
      name: 'ANY',
    })
    setSelectedState('')
    setSelectedRegion('')
    setSelectedLga('')
    setLocations(STATES)
  }

  useEffect(() => {
    if (selectedState && !selectedRegion) {
      fetchRegions()
    }
  }, [selectedState, page])

  useEffect(() => {
    if (selectedRegion && !selectedLga) {
      fetchLga()
    }
  }, [selectedRegion, page])

  useEffect(() => {
    if (selectedLga) {
      fetchSuburbs()
    }
  }, [selectedLga, page])

  useEffect(() => {
    if (filteredState && searchTerm) {
      setSelectedState('')
      setSelectedRegion('')
      setSelectedLga('')
      searchForLocation(searchTerm, filteredState)
    }
  }, [page, filteredState])

  useEffect(() => {
    if (value) {
      const formattedValues = value.map(val => {
        const localeArray = val.split(';')
        return {
          state: localeArray[0],
          name: localeArray[1] || '',
        }
      })
      setSelectedLocales([...formattedValues])
    }
  }, [value])

  useEffect(() => {
    if (onFormSubmitted) {
      setFilteredState(null)
      setSearchTerm('')
      reset()
    }
  }, [onFormSubmitted])

  const flattenLocales = (locales: Array<{
    state: string,
    name: string,
  }>): Array<string> => {
    return locales.map(locale => {
      if (locale.name) {
        return `${locale.state};${locale.name}`
      } else {
        return `${locale.state}`
      }
    })
  }

  /**
   * Ensure that the correct value gets displayed in the column depending on what stage
   * the state -> suburb flow or the location has been searched
   * @param column
   * @param location
   * @return the column value
   */
  const renderColumnValue = (column: string, location: GeographicType, index: number): React.ReactElement | string => {
    switch (column) {
    case 'state':
      if (loading) {
        return <Skeleton/>
      }
      return (
        <SPUDAddLocale
          locale={
            location.location_type === 'state'
              ? location.name as string
              : STATES.find(state => state.id === location.state || state.id === selectedState)?.name
          }
          location={location}
          columnType={column}
          selectedLocales={selectedLocales}
          onAddLocale={(locale) => {
            const newLocales = [...selectedLocales, {
              state: typeof location.id === 'string' ? location.id : location.state as string,
              name: STATES.find(state => state.id === locale)?.name || locale,
            }]
            setSelectedLocales(newLocales)
            callback(formKey, flattenLocales(newLocales))
          }}
        />)
    case 'region':
      if (loading) {
        return <Skeleton/>
      } else if (selectedState) {
        return (
          <SPUDAddLocale
            locale={location?.region_type ? location.name : selectedRegion || location.region}
            selectedLocales={selectedLocales}
            location={location}
            columnType={column}
            onAddLocale={(locale) => {
              const newLocales = [...selectedLocales, {
                state: location.state as string,
                name: locale,
              }]
              setSelectedLocales(newLocales)
              callback(formKey, flattenLocales(newLocales))
            }}
          />)
      } else if (!selectedState && !searchTerm) {
        return (
          <SPUDLoadNextLocationTypeButton
            previousColumnValues={STATES}
            index={index}
            columnType='Region'
            onColumnChange={(stateValue) => {
              setSelectedState(stateValue)
            }}
          />)
      } else {
        return location.region
          ? (
            <SPUDAddLocale
              locale={location.region}
              selectedLocales={selectedLocales}
              location={location}
              columnType={column}
              onAddLocale={(locale) => {
                const newLocales = [...selectedLocales, {
                  state: location.state as string,
                  name: locale,
                }]
                setSelectedLocales(newLocales)
                callback(formKey, flattenLocales(newLocales))
              }}
            />)
          : '-'
      }
    case 'lga':
      if (loading) {
        return <Skeleton/>
      } else if (selectedRegion) {
        return (
          <SPUDAddLocale
            locale={location.lga || selectedLga}
            selectedLocales={selectedLocales}
            location={location}
            columnType={column}
            onAddLocale={(locale) => {
              const newLocales = [...selectedLocales, {
                state: location.state as string,
                name: locale,
              }]
              setSelectedLocales(newLocales)
              callback(formKey, flattenLocales(newLocales))
            }}
          />)
      } else if (!selectedRegion && selectedState && !searchTerm) {
        return (
          <SPUDLoadNextLocationTypeButton
            previousColumnValues={locations}
            index={index}
            columnType='LGA'
            onColumnChange={(regionValue) => {
              setSelectedRegion(regionValue)
            }}
          />)
      } else {
        return location.lga
          ? (
            <SPUDAddLocale
              locale={location.lga || selectedLga}
              selectedLocales={selectedLocales}
              location={location}
              columnType={column}
              onAddLocale={(locale) => {
                const newLocales = [...selectedLocales, {
                  state: location.state as string,
                  name: locale,
                }]
                setSelectedLocales(newLocales)
                callback(formKey, flattenLocales(newLocales))
              }}
            />)
          : '-'
      }
    case 'postcode':
      if (loading) {
        return <Skeleton/>
      } else if (selectedRegion && selectedLga) {
        return (
          <SPUDAddLocale
            locale={location.postcode}
            selectedLocales={selectedLocales}
            location={location}
            columnType={column}
            onAddLocale={(locale) => {
              const newLocales = [...selectedLocales, {
                state: location.state as string,
                name: locale,
              }]
              setSelectedLocales(newLocales)
              callback(formKey, flattenLocales(newLocales))
            }}
          />)
      } else if (!selectedLga && selectedRegion && !searchTerm) {
        return (
          <SPUDLoadNextLocationTypeButton
            previousColumnValues={locations}
            index={index}
            columnType='Suburbs'
            onColumnChange={(regionValue) => {
              setSelectedLga(regionValue)
            }}
          />)
      } else {
        return location.postcode
          ? (
            <SPUDAddLocale
              locale={location.postcode}
              selectedLocales={selectedLocales}
              location={location}
              columnType={column}
              onAddLocale={(locale) => {
                const newLocales = [...selectedLocales, {
                  state: location.state as string,
                  name: locale,
                }]
                setSelectedLocales(newLocales)
                callback(formKey, flattenLocales(newLocales))
              }}
            />)
          : '-'
      }
    case 'suburb':
      if (loading) {
        return <Skeleton/>
      } else if (selectedLga && !searchTerm) {
        return (
          <SPUDAddLocale
            locale={location.name}
            location={location}
            columnType={column}
            selectedLocales={selectedLocales}
            onAddLocale={(locale) => {
              const newLocales = [...selectedLocales, {
                state: location.state as string,
                name: locale,
              }]
              setSelectedLocales(newLocales)
              callback(formKey, flattenLocales(newLocales))
            }}
          />)
      } else {
        return location.name && searchTerm
          ? (
            <SPUDAddLocale
              locale={location.name}
              location={location}
              columnType={column}
              selectedLocales={selectedLocales}
              onAddLocale={(locale) => {
                const newLocales = [...selectedLocales, {
                  state: location.state as string,
                  name: locale,
                }]
                setSelectedLocales(newLocales)
                callback(formKey, flattenLocales(newLocales))
              }}
            />)
          : '-'
      }
    default:
      return '-'
    }
  }

  const acceptChanges = (key: string, value: unknown) => {
    setValue(key, value)
  }

  const handlePage = (param: { currentPage: number }): void => {
    setPage(param.currentPage)
  }

  return <div>
    <Title level={4}>
      {title}
      <Button style={{ margin: '0 10px' }} onClick={() => {
        setSelectedLocales([])
        callback(formKey, [])
      }}
      >
        Delete all
      </Button>
    </Title>
    {siteValues?.catchment && recordType === 'service' && <Row>
      <Col>
        <Title level={5}>
          Site Geographic Coverage
        </Title>
        <SiteLocaleChipContainer>
          {siteValues?.catchment?.map(site =>
            <LocaleChip key={site} hasButton={false}>
              {site}
            </LocaleChip>,
          )}
        </SiteLocaleChipContainer>
      </Col>
    </Row>}
    <Row>
      <LocaleChipContainer highlight={highlight && !selectedLocales.length} className='form-geo-coverage'>
        {selectedLocales.map((locale, index) => (
          <LocaleChip key={`${locale}_${index}`} hasButton={true}>
            {locale.state}; {locale.name}
            <LocaleChipButton
              onClick={() => {
                const updatedLocales = [...selectedLocales]
                updatedLocales.splice(index, 1)
                setSelectedLocales([...updatedLocales])
                callback(formKey, flattenLocales(updatedLocales))
              }}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faTimes as IconProp} />
            </LocaleChipButton>
          </LocaleChip>
        ))}
      </LocaleChipContainer>
    </Row>
    {bulkUpdateChanges && <Row>
      <Col>
        <BulkUpdateRecordChangeReview
          bulkUpdateChanges={bulkUpdateChanges}
          setValue={acceptChanges}
          fieldKey='catchment'
          fieldLabel={title}
          currentFieldValue={getValues('catchment')}
          allowChange={false}
          resetBulkUpdateRecordChanges={resetBulkUpdateRecordChanges}
          setResetBulkUpdateRecordChanges={setResetBulkUpdateRecordChanges}
        />
      </Col>
    </Row>}
    <Row style={{ marginBottom: '1em' }}>
      <Col md={3}>
        <SPUDAutoComplete
          label='State'
          options={FILTER_STATES}
          isMulti={false}
          selectedOption={filteredState}
          onChange={(values) => {
            if (typeof values === 'string') {
              setFilteredState({ id: values, name: values })
            } else {
              setFilteredState(null)
              reset()
            }
          }}
          disabled={disabled}
        />
      </Col>
      <Col md={7}>
        <SPUDInputField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(event.target.value)
          }}
          id='search'
          name='search'
          label={<Title level={4} marginTop='1em'>Search</Title>}
          fullwidth
          value={searchTerm}
        />
        <Text>
          To search for a location, please select a state from the dropdown and then begin typing,
          or browse using the table below by first selecting a state.
        </Text>
      </Col>
      <Col md={2} style={{ marginTop: '1.5em' }}>
        <Button primary onClick={() => reset()} style={{ height: '3.5em', width: '100%' }}>
          Clear
        </Button>
      </Col>
    </Row>
    <Row>
      <Col direction='row'>
        <LocaleButton
          disabled={loading}
          tabIndex={-1}
          onClick={() => {
            reset()
          }}
        >
          {selectedState ? 'Back to Australian states' : 'State'}
        </LocaleButton>
      </Col>
      <Col direction='row'>
        {selectedRegion
          ? (
            <LocaleButton
              disabled={loading}
              onClick={() => {
                setLoading(true)
                setPage(1)
                setTotal(0)
                setSelectedRegion('')
                setSelectedLga('')
                fetchRegions(0)
              }}
            >{selectedState ? `Back to ${selectedState} Regions` : ''}</LocaleButton>
          )
          : searchTerm
            ? <Text size='14px'>Regions</Text>
            : selectedState && <Text size='14px'>{selectedState} Regions</Text>
        }
      </Col>
      <Col direction='row'>
        {selectedLga
          ? (
            <LocaleButton
              disabled={loading}
              onClick={() => {
                setSelectedLga('')
                setTotal(0)
                setPage(1)
                fetchLga(0)
              }}
            >{selectedRegion ? `Back to ${selectedRegion} Lgas` : ''}</LocaleButton>
          )
          : searchTerm
            ? <Text size='14px'>LGAs</Text>
            : selectedRegion && <Text size='14px'>{selectedRegion} Lgas</Text>
        }
      </Col>
      <Col>
        <Text size='14px'>
          {selectedLga ? `${selectedLga} postcodes` : searchTerm ? 'Postcodes' : ''}
        </Text>
      </Col>
      <Col direction='row'>
        <Text size='14px'>
          {selectedLga ? `${selectedLga} suburbs` : searchTerm ? 'Suburbs' : ''}
        </Text>
      </Col>
    </Row>
    <Row>
      <Col>
        {locations.map((location, index) => (
          <Row key={location.id} align='center' style={{
            border: '1px solid #e0e6ee',
          }}
          >
            <Col>
              <Text padding='1em'>
                {renderColumnValue('state', location, index)}
              </Text>
            </Col>
            <Col>
              <Text padding='1em'>
                {renderColumnValue('region', location, index)}
              </Text>
            </Col>
            <Col>
              <Text padding='1em'>
                {renderColumnValue('lga', location, index)}
              </Text>
            </Col>
            <Col>
              <Text padding='1em'>
                {renderColumnValue('postcode', location, index)}
              </Text>
            </Col>
            <Col direction='row'>
              {renderColumnValue('suburb', location, index)}
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
    {locations.length === 0 && !loading &&
      <Row>
        <Col>
          <Card>
            <Title level={2} align="center">No Locations found</Title>
          </Card>
        </Col>
      </Row>
    }
    <Row>
      <Pagination
        total={total}
        onPageChange={handlePage}
        pageSize={PAGE_SIZE}
        pageOverride={page}
        showNumbers
        showEndNumbers
        pageNeighbours={2}
      />
    </Row>
  </div>
}

export default SPUDGeographicCoverage
