import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowsRotate,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  Col,
  Container,
  Content,
  Header,
  Page,
  Pagination,
  Paragraph,
  Section,
  Row,
  Title,
  Table,
  Text,
} from '@ix/ix-ui'
import { withContext } from '../../context/AppContext'
import { AppContextType } from '../../context/AppContext.type'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Toaster } from 'react-hot-toast'
import { useAuth } from '../../helpers/auth'
import { AdvancedFilterWrapper } from '../../helpers/record'
import FilteringComponent from '../RecordFilter/FilteringComponent'
import {
  AdvancedFilterType,
  fetchAdvancedFilterPresets,
  preLoadFiltersByRole,
} from '../RecordFilter/RecordFilter.service'
import {
  fetchRecordColumns,
  filterToUrlParams,
  formatRowData, RecordPageType,
  urlParamsToFilterObj,
} from '../RecordsPage/ListView/ListView.service'
import { fetchCorrectHeading } from '../page.service'
import AdvancedFilters from '../RecordFilter/AdvancedFilters'
import axios from 'axios'
import styled from 'styled-components'

type Props = AppContextType

const DashboardPage = (props: Props): React.ReactElement => {
  const { userRole } = useAuth()
  const [data, setData] = useState<Array<RecordPageType>>([])
  const [total, setTotal] = useState<number>(0)
  const [sort, setSort] = useState<string>(userRole === 'Administrator' ? 'desc' : 'asc')
  const [page, setPage] = useState<number>(1)
  const [sorted, setSorted] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [pageFilters, setPageFilters] = useState<{[x: string]: AdvancedFilterType}>()
  const [openAdvancedFilters, setOpenAdvancedFilters] = useState(false)
  const [editingFilter, setEditingFilter] = useState('')

  const abortController = new AbortController()

  const perPage = 10

  const handleSort = (param: number) => (e: Event): void => {
    e.preventDefault()
    setSorted(param)
    if (sort === 'asc') {
      setSort('desc')
    } else {
      setSort('asc')
    }
  }

  const handlePage = (param: { currentPage: number }): void => {
    setPage(param.currentPage)
  }

  const fetchRecords = async (
    filter: string | URLSearchParams | null,
    pageOverride?: number|null,
    orderOverride?: string|null,
  ): Promise<void> => {
    setData([])
    if (!filter) {
      setTotal(0)
    }
    const columns = fetchRecordColumns('site', userRole)
    const sorting = sort === 'asc' ? '' : '-'
    const ordering = columns[sorted].key !== 'id' ? columns[sorted].key : orderOverride
    setLoading(true)
    setTimeout(async () => {
      try {
        const response = await props
          .filterList(
            {
              recordType: 'site',
              offset: ((pageOverride || page) - 1) * perPage,
              limit: perPage,
              ordering: sorting + ordering,
            },
            filter,
            abortController,
          )
        setLoading(false)
        setTotal(response.data.count)

        const formattedResponse = response.data.results.map((item) => (
          formatRowData('site', item, userRole)
        ))
        setData(formattedResponse)
      } catch (error) {
        // If it's not an aborted request we stop it from loading
        if (!axios.isCancel(error)) {
          setLoading(false)
        }
      }
    }, 0)
  }

  const preLoadFilters = async () => {
    setLoading(true)
    const presetFiltersByRole = fetchAdvancedFilterPresets(
      'dashboard',
      'site',
    )
    const presetFilters = await preLoadFiltersByRole(presetFiltersByRole, userRole, 'dashboard')
    setPageFilters(presetFilters)
  }

  const TableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr; /* Fills the page */
  width: 100%;
`

  const ResponsiveTable = styled(Table)`
  display: block;
  width: 100%;
  overflow-x: auto;
  table-layout: fixed;

  th, td {
    padding: 1em;
    box-sizing: border-box;
    text-align: left;
  }

  /* Stops the small fields from being too big */
  th:first-child,
  td:first-child,
  th:nth-child(n+4),
  td:nth-child(n+4) {
    width: 10%;
  }

  /* Targets the specific cols to make them wider, a lil hackey but its the only way to get around using block */
  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(3),
  td:nth-child(3) {
    width: auto;
  }
`

  useEffect(() => {
    setData([])
    if (window.location.hash.includes('?') && window.location.hash.split('?')[1] !== '') {
      const params = new URLSearchParams(window.location.hash.split('?')[1])
      const filters: { [x: string]: AdvancedFilterType } = urlParamsToFilterObj(
        'site',
        params,
        userRole,
        'dashboard',
      )

      if (!Object.keys(filters).length) {
        fetchRecords(null, page, 'id')
      } else {
        setPageFilters(filters)
      }
    } else {
      setSorted(0)
      setPage(1)
      preLoadFilters()
    }
    return () => {
      abortController.abort()
    }
  }, [sort, page])

  useEffect(() => {
    const params = new URLSearchParams()
    let order = 'id'
    if (pageFilters) {
      Object.entries(pageFilters).forEach(([, filter]) => {
        if (filter) {
          order = filter?.default_order || 'id'
        }
      })
      const processedFilterParams = filterToUrlParams(pageFilters, params)
      window.location.hash = `?${processedFilterParams}`
      if (Object.keys(pageFilters).length) {
        fetchRecords(processedFilterParams || null, page, order)
      } else {
        fetchRecords(null, page, order)
      }
    }
    return () => {
      abortController.abort()
    }
  }, [pageFilters])

  const filterAndFetchRecords = (filters: {[x: string]: AdvancedFilterType}) => {
    const params = new URLSearchParams()
    const processedFilterParams = filterToUrlParams(filters, params)
    window.location.hash = `?${processedFilterParams}`
    setPage(1)
    if (Object.keys(filters).length) {
      setPageFilters(filters)
    } else {
      setPageFilters({})
    }
  }

  const reloadList = () => {
    if (pageFilters) {
      const params = new URLSearchParams()
      const processedFilterParams = filterToUrlParams(pageFilters, params)
      const columns = fetchRecordColumns(
        'site',
        userRole,
      )
      const ordering = columns[sorted].key !== 'id' ? columns[sorted].key : 'id'
      fetchRecords(processedFilterParams, page, ordering)
    }
  }

  let results
  if (data.length && !loading) {
    results = (
      <TableContainer>
        <ResponsiveTable
          sorted={sorted}
          sort={sort}
          rows={data}
          title="List of sites in Dashboard"
          headers={fetchRecordColumns('site', userRole)}
          archivedRowKey='is_active'
          callbackSort={handleSort}
        />
      </TableContainer>
    )
  } else {
    results = (
      <div style={{ textAlign: 'center' }}>
        {!loading
          ? <Card>
            <FontAwesomeIcon icon={faSearch as IconProp} style={{ color: '#E3EDFA', fontSize: `${72}px` }} />

            <Title level={2} align="center">No records found</Title>

            <Paragraph style={{ textAlign: 'center' }}>
              Oops, we couldn&apos;t find any records for your search.
            </Paragraph>

            <Paragraph style={{ textAlign: 'center' }}>
              <Button primary to={'/records/site/add'} >
                Add Site
              </Button>
            </Paragraph>
          </Card>
          : <Card>Loading</Card> }
      </div>
    )
  }

  return (
    <Page>
      <Content>
        <Header style={{ padding: '2em 0.75em', margin: '0 1em' }}>
          <Container style={{ paddingRight: '0' }}>
            {fetchCorrectHeading('dashboard', '')}
          </Container>
        </Header>
        <Section>
          <Row>
            <Col>
              <Row>
                <Col direction='row'>
                  <FilteringComponent
                    userRole={userRole}
                    pageFilters={pageFilters}
                    filterCallback={(filters) => filterAndFetchRecords(filters)}
                    onOpenAdvancedFilters={(openFilter, chip) => {
                      setOpenAdvancedFilters(openFilter)
                      if (chip) {
                        setEditingFilter(chip)
                      } else {
                        setEditingFilter('')
                      }
                    }}
                    openAdvancedFilters={openAdvancedFilters}
                    pageType='dashboard'
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col direction='row' justify="flex-end">
              <Button
                light={true}
                onClick={reloadList}
                aria-label='refresh-search'
              >
                <FontAwesomeIcon icon={faArrowsRotate as IconProp} spin={loading} />
              </Button>
            </Col>
          </Row>

          {results}

          <Row>
            <Col>
              <Row align="center">
                <Text padding="20px 10px 0 0">
                  Showing records {(page - 1) * perPage + 1} - {Math.min(page * perPage, total)} of {total}
                </Text>
              </Row>
            </Col>
            <Col>
              <Row justify="flex-end">
                <Pagination
                  total={total}
                  onPageChange={handlePage}
                  pageSize={perPage}
                  pageOverride={page}
                  showNumbers
                  showEndNumbers
                  pageNeighbours={2}
                />
              </Row>
            </Col>
          </Row>

        </Section>
        <Toaster />
      </Content>
      {openAdvancedFilters && <AdvancedFilterWrapper>
        <AdvancedFilters
          onDismissAdvancedFilters={() => {
            setOpenAdvancedFilters(false)
            setEditingFilter('')
          }}
          recordType='site'
          currentPageFilters={pageFilters}
          userRole={userRole}
          onUpdateFilters={(filters) => filterAndFetchRecords(filters)}
          pageType={'dashboard'}
          editingFilter={editingFilter}
        />
      </AdvancedFilterWrapper>}
    </Page>
  )
}

export default withContext(DashboardPage)
