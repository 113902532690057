import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Title,
  Button,
} from '@ix/ix-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLevelUpAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { SPUDInputField } from '../../helpers/record'
import { AngledArrowContainer } from './AngledArrowContainer'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type SPUDAdvancedListProps = {
  title?: string,
  formKey: string,
  value: Array<string>,
  callback: (name: string, params: Array<string>) => void,
  disabled: boolean
}

const AdvancedListInputContainer = styled.div`
  width: 100%;
  margin-top: 5px;
`

function SPUDAdvancedList ({ title, formKey, value, callback, disabled }: SPUDAdvancedListProps): React.ReactElement {
  const [rows, setRows] = useState<Array<string>>([])

  const onChange = (value: string, index: number): void => {
    rows[index] = value
    setRows([...rows])
    callback(formKey, rows)
  }

  const [autoFocus, setAutoFocus] = useState(false)

  useEffect(() => {
    if (value?.length) {
      setRows(value)
    }
  }, [value])

  return (
    <div>
      <Row>
        <Col>
          <Title level={4}>
            {title}
          </Title>
        </Col>
        <Col flex={11}>
          <Button active={!disabled} onClick={() => {
            setRows([...rows, ''])
            setAutoFocus(true)
          }}
          >
            <FontAwesomeIcon icon={faPlus as IconProp}/> Add
          </Button>
        </Col>
      </Row>
      {rows.map((row, index) => (
        <Row key={index} align="center">
          <Col flex={10} direction='row'>
            <AngledArrowContainer>
              <FontAwesomeIcon size='2x' rotation={90} icon={faLevelUpAlt as IconProp} color='#A3A3A3'/>
            </AngledArrowContainer>
            <AdvancedListInputContainer>
              <SPUDInputField
                value={row}
                fullwidth
                autoFocus={autoFocus}
                borderColour="#478fe5"
                marginBottom='0'
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(evt.target.value, index)
                }}
              />
            </AdvancedListInputContainer>
          </Col>
          <Col>
            <Button
              title="Delete"
              icon
              onClick={() => {
                rows.splice(index, 1)
                setRows([...rows])
              }}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faTrash as IconProp}/>
            </Button>
          </Col>
        </Row>
      ))}
    </div>
  )
}

export default SPUDAdvancedList
