import React, { ReactNode } from 'react'
import styled from 'styled-components'

const TooltipContainer = styled.div`
  position: absolute;
  z-index: 5;
  font-size: 1em;
  width: auto;
  text-align: center;
  padding: 0em 0.5em;
  bottom: 1.5em;
  background-color: #E1E2E2;
  color: #807E80;
  display: inline;
  white-space: nowrap;
 font-family: 'Roboto', sans-serif; 
 font-weight: bold;
`

type TooltipProps = {
  children: ReactNode;
  visible: boolean;
}

const Tooltip = ({ children, visible }: TooltipProps) => {
  return visible ? <TooltipContainer>{children}</TooltipContainer> : null
}

export default Tooltip
