import keycloak from '../../keycloak'

export const getAudience = (): string => {
  const credentials = new URL(window.OPENID_CONNECT_CREDENTIALS)
  return credentials.password
}

export type AuthReturnType = {
  userRole: 'Administrator'|'Updater'|'Editor',
  updaterThatCanPublish: boolean,
  idToken: string,
  accessToken: string,
  user: {
    name: string,
    id: string | null,
    // eslint-disable-next-line camelcase
    given_name: string,
    // eslint-disable-next-line camelcase
    family_name: string,
    username: string,
  }
}

export function useAuth (): AuthReturnType {
  const idToken = keycloak?.idTokenParsed
  const accessToken = keycloak?.token
  const userRoles = keycloak?.resourceAccess?.[getAudience()].roles || []

  let presentedRole: 'Administrator'|'Updater'|'Editor'
  if (userRoles.includes('Administrator')) {
    presentedRole = 'Administrator'
  } else if (userRoles.includes('Editor')) {
    presentedRole = 'Editor'
  } else {
    presentedRole = 'Updater'
  }

  return {
    userRole: presentedRole,
    idToken,
    updaterThatCanPublish: userRoles.includes('can_publish'),
    accessToken,
    user: {
      name: idToken?.name || '',
      id: idToken?.sub || null,
      given_name: idToken?.given_name || '',
      family_name: idToken?.family_name || '',
      username: idToken?.preferred_username || '',
    },
  }
}
