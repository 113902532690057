import React from 'react'
import { Option } from '../forms/AddEditRecord.type'
import Select from 'react-select'

type SPUDUserAutocompleteProps = {
  selectedOption: Option | null,
  selectOptionCallback: (option: Option | null) => void,
  uniqueId: string | number,
  users: Array<Option>
}

function SPUDUserAutocomplete ({ selectedOption, selectOptionCallback, uniqueId, users }: SPUDUserAutocompleteProps) {
  const generateElementId = (): string => `spud-user-autocomplete-${uniqueId}`

  const customStyle = {
    control: () => ({
      border: '1px solid #3a8ae8',
      borderRadius: 3,
      display: 'flex',
      padding: 0,
      marginTop: 0,
      height: '2.5em',
    }),
  }

  return (
    <Select
      styles={customStyle}
      id={generateElementId() || 'autocomplete'}
      onChange={(option) => {
        selectOptionCallback(option)
      }}
      options={users}
      isClearable={true}
      defaultValue={selectedOption || null}
      value={selectedOption || null}
      getOptionLabel={(option: Option) => option.name }
      // The getOptionValue has to be a string
      getOptionValue={(option: Option) => !option?.id ? '' : option.id.toString()}
      menuShouldScrollIntoView={false}
    />
  )
}

export default SPUDUserAutocomplete
