import Keycloak from 'keycloak-js'

let credentials
const regex = /^\/auth\/realms\/(.*)\/protocol\/openid-connect\/token/
if (window.OPENID_CONNECT_CREDENTIALS) {
  credentials = new URL(window.OPENID_CONNECT_CREDENTIALS)
}

const keycloak = new Keycloak({
  realm: credentials?.pathname?.match(regex)?.[1] || 'dev',
  clientId: credentials ? credentials.username : 'spud-dev',
  url: credentials ? `${credentials.origin}/auth` : 'https://auth.dev.ixapps.org/auth',
})

export default keycloak
