{
  "streetTypes": [
    { "id": "Avenue", "name": "Avenue" },
    { "id": "Boulevard", "name": "Boulevard" },
    { "id": "Crescent", "name": "Crescent" },
    { "id": "Highway", "name": "Highway" },
    { "id": "Parade", "name": "Parade" },
    { "id": "Road", "name": "Road" },
    { "id": "Street", "name": "Street" },
    { "id": "Terrace", "name": "Terrace" },
    { "id": "Access", "name": "Access" },
    { "id": "Alley", "name": "Alley" },
    { "id": "Alleyway", "name": "Alleyway" },
    { "id": "Amble", "name": "Amble" },
    { "id": "Anchorage", "name": "Anchorage" },
    { "id": "Approach", "name": "Approach" },
    { "id": "Arcade", "name": "Arcade" },
    { "id": "Artery", "name": "Artery" },
    { "id": "Avenue", "name": "Avenue" },
    { "id": "Basin", "name": "Basin" },
    { "id": "Beach", "name": "Beach" },
    { "id": "Bend", "name": "Bend" },
    { "id": "Block", "name": "Block" },
    { "id": "Boulevard", "name": "Boulevard" },
    { "id": "Brace", "name": "Brace" },
    { "id": "Brae", "name": "Brae" },
    { "id": "Break", "name": "Break" },
    { "id": "Bridge", "name": "Bridge" },
    { "id": "Broadway", "name": "Broadway" },
    { "id": "Brow", "name": "Brow" },
    { "id": "Bypass", "name": "Bypass" },
    { "id": "Byway", "name": "Byway" },
    { "id": "Causeway", "name": "Causeway" },
    { "id": "Centre", "name": "Centre" },
    { "id": "Centreway", "name": "Centreway" },
    { "id": "Chase", "name": "Chase" },
    { "id": "Circle", "name": "Circle" },
    { "id": "Circlet", "name": "Circlet" },
    { "id": "Circuit", "name": "Circuit" },
    { "id": "Circus", "name": "Circus" },
    { "id": "Close", "name": "Close" },
    { "id": "Colonnade", "name": "Colonnade" },
    { "id": "Common", "name": "Common" },
    { "id": "Concourse", "name": "Concourse" },
    { "id": "Copsev", "name": "Copsev" },
    { "id": "Corner", "name": "Corner" },
    { "id": "Corso", "name": "Corso" },
    { "id": "Court", "name": "Court" },
    { "id": "Courtyard", "name": "Courtyard" },
    { "id": "Cove", "name": "Cove" },
    { "id": "Crescent", "name": "Crescent" },
    { "id": "Crest", "name": "Crest" },
    { "id": "Cross", "name": "Cross" },
    { "id": "Crossing", "name": "Crossing" },
    { "id": "Crossroad", "name": "Crossroad" },
    { "id": "Crossway", "name": "Crossway" },
    { "id": "Cruiseway", "name": "Cruiseway" },
    { "id": "Cul-de-sac", "name": "Cul-de-sac" },
    { "id": "Cutting", "name": "Cutting" },
    { "id": "Dale", "name": "Dale" },
    { "id": "Dell", "name": "Dell" },
    { "id": "Deviation", "name": "Deviation" },
    { "id": "Dip", "name": "Dip" },
    { "id": "Distributor", "name": "Distributor" },
    { "id": "Drive", "name": "Drive" },
    { "id": "Driveway", "name": "Driveway" },
    { "id": "Edge", "name": "Edge" },
    { "id": "Elbow", "name": "Elbow" },
    { "id": "End", "name": "End" },
    { "id": "Entrance", "name": "Entrance" },
    { "id": "Esplanade", "name": "Esplanade" },
    { "id": "Estate", "name": "Estate" },
    { "id": "Expressway", "name": "Expressway" },
    { "id": "Extension", "name": "Extension" },
    { "id": "Fairway", "name": "Fairway" },
    { "id": "Fire track", "name": "Fire track" },
    { "id": "Firetrail", "name": "Firetrail" },
    { "id": "Flat", "name": "Flat" },
    { "id": "Follow", "name": "Follow" },
    { "id": "Footway", "name": "Footway" },
    { "id": "Foreshore", "name": "Foreshore" },
    { "id": "Formation", "name": "Formation" },
    { "id": "Freeway", "name": "Freeway" },
    { "id": "Front", "name": "Front" },
    { "id": "Frontage", "name": "Frontage" },
    { "id": "Gap", "name": "Gap" },
    { "id": "Garden", "name": "Garden" },
    { "id": "Gardens", "name": "Gardens" },
    { "id": "Gate", "name": "Gate" },
    { "id": "Gates", "name": "Gates" },
    { "id": "Glade", "name": "Glade" },
    { "id": "Glen", "name": "Glen" },
    { "id": "Grange", "name": "Grange" },
    { "id": "Green", "name": "Green" },
    { "id": "Ground", "name": "Ground" },
    { "id": "Grove", "name": "Grove" },
    { "id": "Gully", "name": "Gully" },
    { "id": "Heights", "name": "Heights" },
    { "id": "Highroad", "name": "Highroad" },
    { "id": "Highway", "name": "Highway" },
    { "id": "Hill", "name": "Hill" },
    { "id": "Interchange", "name": "Interchange" },
    { "id": "Intersection", "name": "Intersection" },
    { "id": "Junction", "name": "Junction" },
    { "id": "Key", "name": "Key" },
    { "id": "Landing", "name": "Landing" },
    { "id": "Lane", "name": "Lane" },
    { "id": "Laneway", "name": "Laneway" },
    { "id": "Lees", "name": "Lees" },
    { "id": "Line", "name": "Line" },
    { "id": "Link", "name": "Link" },
    { "id": "Little", "name": "Little" },
    { "id": "Lookout", "name": "Lookout" },
    { "id": "Loop", "name": "Loop" },
    { "id": "Lower", "name": "Lower" },
    { "id": "Mall", "name": "Mall" },
    { "id": "Meander", "name": "Meander" },
    { "id": "Mew", "name": "Mew" },
    { "id": "Mews", "name": "Mews" },
    { "id": "Motorway", "name": "Motorway" },
    { "id": "Mount", "name": "Mount" },
    { "id": "Nook", "name": "Nook" },
    { "id": "Outlook", "name": "Outlook" },
    { "id": "Parade", "name": "Parade" },
    { "id": "Park", "name": "Park" },
    { "id": "Parklands", "name": "Parklands" },
    { "id": "Parkway", "name": "Parkway" },
    { "id": "Part", "name": "Part" },
    { "id": "Pass", "name": "Pass" },
    { "id": "Path", "name": "Path" },
    { "id": "Pathway", "name": "Pathway" },
    { "id": "Piazza", "name": "Piazza" },
    { "id": "Place", "name": "Place" },
    { "id": "Plateau", "name": "Plateau" },
    { "id": "Plaza", "name": "Plaza" },
    { "id": "Pocket", "name": "Pocket" },
    { "id": "Point", "name": "Point" },
    { "id": "Port", "name": "Port" },
    { "id": "Promenade", "name": "Promenade" },
    { "id": "Quad", "name": "Quad" },
    { "id": "Quadrangle", "name": "Quadrangle" },
    { "id": "Quadrant", "name": "Quadrant" },
    { "id": "Quay", "name": "Quay" },
    { "id": "Quays", "name": "Quays" },
    { "id": "Ramble", "name": "Ramble" },
    { "id": "Ramp", "name": "Ramp" },
    { "id": "Range", "name": "Range" },
    { "id": "Reach", "name": "Reach" },
    { "id": "Reserve", "name": "Reserve" },
    { "id": "Rest", "name": "Rest" },
    { "id": "Retreat", "name": "Retreat" },
    { "id": "Ride", "name": "Ride" },
    { "id": "Ridge", "name": "Ridge" },
    { "id": "Ridgeway", "name": "Ridgeway" },
    { "id": "Right of way", "name": "Right of way" },
    { "id": "Ring", "name": "Ring" },
    { "id": "Rise", "name": "Rise" },
    { "id": "River", "name": "River" },
    { "id": "Riverway", "name": "Riverway" },
    { "id": "Riviera", "name": "Riviera" },
    { "id": "Road", "name": "Road" },
    { "id": "Roads", "name": "Roads" },
    { "id": "Roadside", "name": "Roadside" },
    { "id": "Roadway", "name": "Roadway" },
    { "id": "Ronde", "name": "Ronde" },
    { "id": "Rosebowl", "name": "Rosebowl" },
    { "id": "Rotary", "name": "Rotary" },
    { "id": "Round", "name": "Round" },
    { "id": "Route", "name": "Route" },
    { "id": "Row", "name": "Row" },
    { "id": "Rue", "name": "Rue" },
    { "id": "Run", "name": "Run" },
    { "id": "Service way", "name": "Service way" },
    { "id": "Siding", "name": "Siding" },
    { "id": "Slope", "name": "Slope" },
    { "id": "Sound", "name": "Sound" },
    { "id": "Spur", "name": "Spur" },
    { "id": "Square", "name": "Square" },
    { "id": "Stairs", "name": "Stairs" },
    { "id": "State highway", "name": "State highway" },
    { "id": "Steps", "name": "Steps" },
    { "id": "Strand", "name": "Strand" },
    { "id": "Strip", "name": "Strip" },
    { "id": "Subway", "name": "Subway" },
    { "id": "Tarn", "name": "Tarn" },
    { "id": "Terrace", "name": "Terrace" },
    { "id": "Thoroughfare", "name": "Thoroughfare" },
    { "id": "Tollway", "name": "Tollway" },
    { "id": "Top", "name": "Top" },
    { "id": "Tor", "name": "Tor" },
    { "id": "Towers", "name": "Towers" },
    { "id": "Track", "name": "Track" },
    { "id": "Trail", "name": "Trail" },
    { "id": "Trailer", "name": "Trailer" },
    { "id": "Triangle", "name": "Triangle" },
    { "id": "Trunkway", "name": "Trunkway" },
    { "id": "Turn", "name": "Turn" },
    { "id": "Underpass", "name": "Underpass" },
    { "id": "Upper", "name": "Upper" },
    { "id": "Vale", "name": "Vale" },
    { "id": "Viaduct", "name": "Viaduct" },
    { "id": "View", "name": "View" },
    { "id": "Villas", "name": "Villas" },
    { "id": "Vista", "name": "Vista" },
    { "id": "Wade", "name": "Wade" },
    { "id": "Walk", "name": "Walk" },
    { "id": "Walkway", "name": "Walkway" },
    { "id": "Way", "name": "Way" },
    { "id": "Wharf", "name": "Wharf" },
    { "id": "Wynd", "name": "Wynd" },
    { "id": "Yard", "name": "Yard" }
  ]
}