import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { Button, ButtonGroup } from '@ix/ix-ui'
import styled from 'styled-components'
import CreatableSelect from 'react-select/creatable'

export type TimePickerProps = {
  defaultValue?: {
    hour: string,
    minute: string,
    amPm: string,
  }
  onTimeSelect: (time: string) => void,
  clearTimePicker?: boolean,
  id?: string,
}

const StyledButton = styled(Button)`
  background-color: ${(props) => (props.selected ? '#3A8AE8' : 'white')};
  color: ${(props) => (props.selected ? 'white' : '#3A8AE8')};
  border: 1px solid #3A8AE8;
  margin-left: -1px;
  &:focus {
    box-shadow: none !important;
  }
`

const customStyle = {
  control: () => ({
    border: '1px solid #3a8ae8',
    background: '#fff',
    borderRadius: 3,
    display: 'flex',
    padding: 0,
    marginTop: 1,
    textAlign: 'left',
    fontSize: '12px',
  }),
  menuList: () => ({
    textAlign: 'left',
    fontSize: '12px',
  }),
}

const TimePicker: React.FC<TimePickerProps> = ({
  defaultValue = { hour: '09', minute: '00', amPm: 'AM' },
  onTimeSelect,
  clearTimePicker,
  id,
}) => {
  const [selectedHour, setSelectedHour] = useState<string>(defaultValue.hour)
  const [selectedMinute, setSelectedMinute] = useState<
    { value: string, label: string }>(
      {
        value: defaultValue.minute,
        label: defaultValue.minute,
      })
  const [selectedAmPm, setSelectedAmPm] = useState<string>(defaultValue.amPm)
  const [showError, setShowError] = useState(false)
  const [previouslyEnteredValue, setPreviouslyEnteredValue] = useState('')

  const customMinuteStyle = {
    control: () => ({
      border: `${!showError ? '1px #3a8ae8' : '3px red'} solid `,
      background: '#fff',
      borderRadius: 3,
      display: 'flex',
      padding: 0,
      marginTop: 1,
      textAlign: 'left',
      fontSize: '12px',
    }),
    menuList: () => ({
      textAlign: 'left',
      fontSize: '12px',
    }),
  }

  const hoursOptions = Array.from({ length: 12 }, (_, index) => ({
    value: (index + 1).toString().padStart(2, '0'),
    label: (index + 1).toString().padStart(2, '0'),
  }))

  const minutesOptions = [
    { value: '00', label: '00' },
    { value: '15', label: '15' },
    { value: '30', label: '30' },
    { value: '45', label: '45' },
  ]

  const toggleAmPm = (value: string) => {
    setSelectedAmPm(value)
  }

  const handleTimeSelect = () => {
    if (selectedHour !== null) {
      // Covert time to the expected format 'HH:mm:ss' in a 24-hour format
      const formattedHour =
        selectedAmPm === 'PM' && selectedHour !== '12'
          ? String(Number(selectedHour) + 12).padStart(2, '0')
          : selectedAmPm === 'AM' && selectedHour === '12'
            ? '00'
            : selectedHour.padStart(2, '0')

      const time = `${formattedHour}:${selectedMinute.value}:00`
      onTimeSelect(time)
    }
  }

  useEffect(() => {
    handleTimeSelect()
  }, [selectedHour, selectedMinute, selectedAmPm])

  useEffect(() => {
    // Check if the TimePicker should be cleared and reset value accordingly
    if (clearTimePicker) {
      setSelectedHour(defaultValue.hour)
      setSelectedMinute({
        value: defaultValue.minute,
        label: defaultValue.minute,
      })
      setSelectedAmPm(defaultValue.amPm)
    }
  }, [clearTimePicker, defaultValue])

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Select
          // Ignoring because it's complaining that MenuList
          // is not a valid style option which it is
          // https://react-select.com/styles#the-unstyled-prop
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //  @ts-ignore
          styles={customStyle}
          id={`field-hour-${id}`}
          key={`field-hour-${selectedHour}`}
          options={hoursOptions}
          maxLength={2}
          defaultValue={hoursOptions.find((option) => option.value === selectedHour)}
          onChange={(option) => setSelectedHour(option ? option.value : '09')}
        />
        :
        <CreatableSelect
          // Ignoring because it's complaining that MenuList
          // is not a valid style option which it is
          // https://react-select.com/styles#the-unstyled-prop
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //  @ts-ignore
          styles={customMinuteStyle}
          id={`field-minute-${id}`}
          key={`field-hour-${selectedMinute}`}
          isClearable={false}
          options={minutesOptions}
          defaultValue={minutesOptions.find((option) => option.value === selectedMinute.value)}
          onChange={(option) => {
            setShowError(false)
            setSelectedMinute(option as {
              value: string,
              label: string
            })
          }}
          value={selectedMinute}
          formatCreateLabel={(inputValue) => inputValue}
          onInputChange={(inputValue) => {
            if (!inputValue && previouslyEnteredValue) {
              if (parseInt(previouslyEnteredValue) < 0 || parseInt(previouslyEnteredValue) > 59) {
                setShowError(true)
              } else {
                setShowError(false)
              }
              setSelectedMinute({
                value: previouslyEnteredValue,
                label: previouslyEnteredValue,
              })
              setPreviouslyEnteredValue('')
            } else {
              setPreviouslyEnteredValue(inputValue)
            }
          }}
          onCreateOption={inputValue => {
            if (parseInt(inputValue) < 0 || parseInt(inputValue) > 59) {
              setShowError(true)
            } else {
              setShowError(false)
            }
            setSelectedMinute({
              value: inputValue,
              label: inputValue,
            })
          }}
        />
        <ButtonGroup>
          <StyledButton
            id={`button-AM-${id}`}
            selected={selectedAmPm === 'AM'}
            onClick={() => toggleAmPm('AM')}
            style={{ marginLeft: '10px' }}
          >
            AM
          </StyledButton>
          <StyledButton
            id={`button-PM-${id}`}
            selected={selectedAmPm === 'PM'}
            onClick={() => toggleAmPm('PM')}
          >
            PM
          </StyledButton>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default TimePicker
