import API, { getSPUDAPIUrl } from '../helpers/api'
import keycloak from '../../keycloak'
import { PlaceResult } from './googleAPI.service'
import _ from 'lodash'

const STREET_SUFFIX = ['Central', 'East', 'North', 'South', 'West']

export type searchResultsType = {
  id: string,
  name: string,
  postcode?: string,
  state: string,
  region: string,
  lga: string,
  location_type: string,
}

export const searchLocation = async (
  searchTerm: string,
  offset: number,
  state?: string,
  locationTypes?: Array<string>,
  accessId?: string,
): Promise<{
  next: string,
  count: number,
  results: Array<{
    id: string,
    name: string,
    postcode?: string,
    state: string,
    region: string,
    lga: string,
    // eslint-disable-next-line camelcase
    location_type: string,
  }>
}> => {
  let searchURL = `search/?search_type=suggest&search=${searchTerm}&offset=${offset}`
  if (state && state !== 'ANY') {
    searchURL += `&state=${state}&limit=10`
  }
  if (locationTypes) {
    for (const locationType of locationTypes) {
      searchURL += `&location_search_type=${locationType}`
    }
  } else {
    searchURL += '&location_search_type=all'
  }
  if (accessId) {
    searchURL = `${searchURL}&access_id=${accessId}`
  }
  const response = await API.get(`${getSPUDAPIUrl()}location/${searchURL}`, {
    headers: {
      Authorization: `Keycloak ${keycloak.token}`,
    },
  })
  return response.data
}

export const SearchLGAs = async (state: string, region: string, page: number): Promise<{
  count: number,
  results: Array<{
    id: string,
    name: string,
    postcode?: string
  }>
}> => {
  if (page === 1) {
    page = 0
  } else if (page > 1) {
    page -= 1
  }
  let url = ''
  if (state === 'TAS') {
    // eslint-disable-next-line max-len
    url = `search/?location_search_type=lgas&search_type=suggest&state=${state}&region__regexp=\\W*(${region})\\W*&limit=10&offset=${
      page * 10}&ordering=name`
  } else {
    // eslint-disable-next-line max-len
    url = `search/?location_search_type=lgas&search_type=suggest&state=${state}&region__wildcard=*${region}*&limit=10&offset=${page * 10}&ordering=name`
  }
  const response = await API.get(
    `${getSPUDAPIUrl()}location/${url}`,
    {
      headers: {
        Authorization: `Keycloak ${keycloak.token}`,
      },
    })
  return response.data
}

export const SearchRegion = async (state: string, page: number): Promise<{
  count: number,
  results: Array<{
    id: string,
    name: string,
    postcode?: string
  }>
}> => {
  if (page === 1) {
    page = 0
  } else if (page > 1) {
    page -= 1
  }
  const response = await API.get(
    // eslint-disable-next-line max-len
    `${getSPUDAPIUrl()}location/search/?location_search_type=regions&search_type=suggest&state=${state}&limit=10&offset=${page * 10}&ordering=name`,
    {
      headers: {
        Authorization: `Keycloak ${keycloak.token}`,
      },
    })
  return response.data
}

export const SearchSuburbs = async (state: string, lga: string, page: number): Promise<{
  count: number,
  results: Array<{
    id: string,
    name: string,
    postcode: string
  }>
}> => {
  if (page === 1) {
    page = 0
  } else if (page > 1) {
    page -= 1
  }
  const response = await API.get(
    // eslint-disable-next-line max-len
    `${getSPUDAPIUrl()}location/search/?location_search_type=suburbs&search_type=suggest&state=${state}&lga=${lga}&limit=10&offset=${page * 10}&ordering=name`,
    {
      headers: {
        Authorization: `Keycloak ${keycloak.token}`,
      },
    })
  return response.data
}

/**
 * converts the Address components that forms the Google Place details into the location structure
 *
 * An example of the address components
 * {
 *     address_components: [
 *       {
 *         long_name: '6',
 *         short_name: '6',
 *         types: [
 *           'street_number',
 *         ],
 *       },
 *       {
 *         long_name: 'Warrego Street',
 *         short_name: 'Warrego St',
 *         types: [
 *           'route',
 *         ],
 *       },
 *       {
 *         long_name: 'Parramatta Park',
 *         short_name: 'Parramatta Park',
 *         types: [
 *           'locality',
 *           'political',
 *         ],
 *       },
 *       {
 *         long_name: 'Cairns Regional',
 *         short_name: 'Cairns',
 *         types: [
 *           'administrative_area_level_2',
 *           'political',
 *         ],
 *       },
 *       {
 *         long_name: 'Queensland',
 *         short_name: 'QLD',
 *         types: [
 *           'administrative_area_level_1',
 *           'political',
 *         ],
 *       },
 *       {
 *         long_name: 'Australia',
 *         short_name: 'AU',
 *         types: [
 *           'country',
 *           'political',
 *         ],
 *       },
 *       {
 *         long_name: '4870',
 *         short_name: '4870',
 *         types: [
 *           'postal_code',
 *         ],
 *       },
 *     ],
 *   }
 * @param googleMapsAddress
 */
export const processGoogleAddressComponents = (googleMapsAddress: PlaceResult): {
  /* eslint-disable camelcase */
  flat_unit: string,
  floor_level: string,
  building_name: string,
  street_number: string,
  street_suffix: string,
  street_name: string,
  street_type: string,
  suburb: string,
  state: string,
  postcode: string,
  /* eslint-enable camelcase */
} => {
  const AddressComponentTypes = [
    'street_number', 'locality', 'administrative_area_level_1', 'postal_code', 'route',
    'subpremise', 'street_address', 'premise',
  ]
  const addressObj = {
    flat_unit: '',
    floor_level: '',
    building_name: '',
    street_number: '',
    street_suffix: '',
    street_name: '',
    street_type: '',
    suburb: '',
    state: '',
    postcode: '',
  }
  googleMapsAddress?.address_components?.forEach(addressComponent => {
    for (const type of addressComponent.types as typeof AddressComponentTypes) {
      switch (type) {
      case 'subpremise':
        addressObj.flat_unit = addressComponent.short_name
        break
      case 'street_number':
        addressObj.street_number = addressComponent.short_name
        break
      case 'locality':
        addressObj.suburb = addressComponent.short_name
        break
      case 'administrative_area_level_1':
        addressObj.state = addressComponent.short_name
        break
      case 'postal_code':
        addressObj.postcode = addressComponent.short_name
        break
      case 'route':
        // eslint-disable-next-line no-case-declarations
        const routeComp = addressComponent.long_name.split(' ')
        // eslint-disable-next-line no-case-declarations
        const streetType = routeComp[routeComp.length - 1]
        if (STREET_SUFFIX.includes(streetType)) {
          addressObj.street_suffix = streetType
          routeComp.pop()
        }
        addressObj.street_type = routeComp[routeComp.length - 1]
        routeComp.pop()
        addressObj.street_name = routeComp.join(' ')
        break
      }
    }
  })
  const isBuilding = googleMapsAddress?.types.some((type: string) => !AddressComponentTypes.includes(type))
  if (isBuilding) {
    addressObj.building_name = googleMapsAddress.name
  }
  if (!addressObj.state) {
    const islandState = googleMapsAddress?.address_components?.find(
      addressComp => addressComp.types.includes('country'))
    if (islandState?.short_name) {
      addressObj.state = islandState.short_name
    }
  }
  return addressObj
}

const getLgaResults = (
  results: Array<searchResultsType>,
  existingLgas: string[],
) => {
  const lgas = _.uniqBy(results, 'lga')
  return _.map(
    _.differenceBy(lgas, existingLgas, 'lga'),
    ({ lga, state, region }) => ({
      id: lga,
      name: lga,
      postcode: undefined,
      state,
      region,
      lga,
      location_type: 'lga',
    }),
  )
}

const getRegionResults = (
  results: Array<searchResultsType>,
  existingRegions: string[]) => {
  const regions = _.uniqBy(results, 'region')
  return _.map(
    _.differenceBy(regions, existingRegions, 'region'),
    ({ region, state }) => ({
      id: region,
      name: region,
      postcode: undefined,
      state,
      region,
      lga: '',
      location_type: 'region',
    }),
  )
}

export const appendLgaAndRegionToResultsIfSearchIsAPostcode = (
  results: Array<{
    id: string,
    name: string,
    postcode?: string,
    state: string,
    region: string,
    lga: string,
    location_type: string,
  }>,
): {
  count: number,
  results: Array<{
    id: string,
    name: string,
    postcode?: string,
    state: string,
    region: string,
    lga: string,
    location_type: string,
  }>
} => {
  const existingLgas = _.map(_.filter(results, { location_type: 'lga' }), 'lga')
  const existingRegions = _.map(_.filter(results, { location_type: 'region' }), 'region')

  const lgaResults = getLgaResults(results, existingLgas)
  const regionResults = getRegionResults(results, existingRegions)

  const newResults = [...results, ...lgaResults, ...regionResults]

  return {
    count: _.size(newResults),
    results: newResults,
  }
}

export const appendRegionToResults = (
  results: Array<{
    id: string,
    name: string,
    postcode?: string,
    state: string,
    region: string,
    lga: string,
    location_type: string,
  }>,
): {
  count: number,
  results: Array<{
    id: string,
    name: string,
    postcode?: string,
    state: string,
    region: string,
    lga: string,
    location_type: string,
  }>
} => {
  const existingRegions = _.map(_.filter(results, { location_type: 'region' }), 'region')

  const regionResults = getRegionResults(results, existingRegions)

  const newResults = [...results, ...regionResults]

  return {
    count: _.size(newResults),
    results: newResults,
  }
}
