import React, { useEffect } from 'react'
import { Title } from '@ix/ix-ui'
import styled from 'styled-components'
import { ReferenceType, useClick, useFloating, useInteractions, VirtualElement } from '@floating-ui/react'

const BulkUpdateContactInfoPopup = styled.div`
  z-index: 3;
  background-color: #dcdcdc;
  border: 1px solid #a6a6a6;
  border-radius: 3px;
  right: -7em;
  position: absolute;
  bottom: 1.75em;
  padding: 1em;
  width: max-content;
  box-shadow: grey 0 0 2px 0
`

function BulkUpdateRecordChangeContentPopup (
  {
    isOpen,
    setIsOpen,
    author,
    parentRefs,
  }: {
    isOpen: boolean,
    setIsOpen: () => void,
    author: {
      contact_name: string,
      contact_number: string,
      contact_email: string,
    },
    parentRefs: (
      refs: {
        setReference: ((node: (ReferenceType | null)) => void) & ((node: (Element | VirtualElement | null)) => void)
      },
      getReferenceProps: () => { [p: string]: unknown }) => void
  }) {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  })

  const click = useClick(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
  ])

  useEffect(() => {
    parentRefs(refs, getReferenceProps)
  })

  return (
    <>
      {isOpen
        ? (
          <div
            ref={refs.setFloating}
            {...getFloatingProps()}
          >
            <BulkUpdateContactInfoPopup>
              <div>
                <Title level={3} marginTop='0'>Service provider update details</Title>
              </div>
              <div>
                <strong>Name:</strong> {author.contact_name}
              </div>
              <div>
                <strong>Number:</strong> {author.contact_number}
              </div>
              <div>
                <strong>Email:</strong> {author.contact_email}
              </div>
            </BulkUpdateContactInfoPopup>
          </div>
        )
        : null
      }
    </>
  )
}

export default BulkUpdateRecordChangeContentPopup
