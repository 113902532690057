import React, { useEffect, useRef, useState, useMemo } from 'react'
import { spudAPI } from '../../services/spud.service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import {
  Title,
  Row,
  Col,
  Checkbox,
  Button,
} from '@ix/ix-ui'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type ServiceTypeType = {
  name: string,
  id: number,
  children: Array<ServiceTypeType>
}

export type SPUDServiceTypeClassificationProps = {
  title: string,
  formKey: string,
  value: Array<{id: number, name: string}>,
  callback: (name: string, params: Array<{id: number, name: string}>) => void
  onFormSubmitted: boolean,
  disabled: boolean,
}

const SPUDServiceTypeNodeContainer = styled.div`
  padding-left: 1em
`

const SPUDServiceTypeNode = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  :hover {
    h5 {
      font-weight: bold;
      transition: 200ms ease;
    }
  }
`

const SPUDServiceTypeNodeContent = styled.div`
  padding: 15px 0;
  font-size: 14px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  h5 {
    font-weight: normal;
  }
  button {
    margin: 0;
  }
`
const SPUDServiceTypeChip = styled.div`
  border-radius: 3px;
  border: 1px solid #3a8ae8;
  padding: 5px;
  color: #4c1811;
  font-size: 14px;
  margin: 0.3em;
  background-color: #fff;
  height: 2em;
`

const SPUDServiceTypeChipButton = styled.button`
  margin-left: 1em;
  border: none;
  background: none;
  cursor: pointer;
  :hover {
    background: #e3edfa;
  }
`

const SPUDServiceTypeChipContainer = styled.div<{ highlight: boolean }>`
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  background-color: #f7f4f4;
  border: ${props => props.highlight ? '3px solid #ff9595' : 'inherit'};
  padding: 5px;
  border-radius: 3px;
  min-height: 100px;
`

const ServiceTypeLinkButton = styled(Button)`
  margin: 7px 5px;
  border: none;
  background: none;
  text-decoration: underline;
  padding: 0;
  :hover {
    text-decoration: none;
    border: none;
    background: none;
  }
  :focus {
    box-shadow: none;
  }
`

const ServiceTypes = (
  {
    selectedServiceTypes,
    collapseAll,
    collapseAllCallback,
    checkServiceTypeCallback,
    serviceTypes = [],
    selectedNodes = [],
  }: {
    serviceTypes: Array<ServiceTypeType>,
    collapseAll: boolean,
    collapseAllCallback: () => void,
    checkServiceTypeCallback: (serviceType: {id: number, name: string}, checked: boolean) => void,
    selectedServiceTypes: Array<{id: number, name: string}>,
    selectedNodes: Array<string>,
  }) => {
  const selectedServiceTypeIds = useMemo(
    () => selectedServiceTypes.map(item => item.id),
    [selectedServiceTypes])

  return (
    <div>
      {serviceTypes.map((serviceType) => (
        // ignored because this component is used recursively
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <ServiceTypeNode
          key={serviceType.id}
          node={serviceType}
          checkServiceTypeCallback={checkServiceTypeCallback}
          selectedServiceTypes={selectedServiceTypes}
          selectedNodes={selectedNodes}
          collapseAll={collapseAll}
          collapseAllCallback={collapseAllCallback}
          checkedValue={selectedServiceTypeIds.includes(serviceType.id)}
        />
      ))}
    </div>
  )
}

const ServiceTypeNode = (
  {
    node,
    checkedValue,
    collapseAll,
    collapseAllCallback,
    checkServiceTypeCallback,
    selectedServiceTypes,
    selectedNodes = [],
  }: {
    node: ServiceTypeType,
    checkedValue: boolean,
    collapseAll: boolean,
    collapseAllCallback: () => void,
    selectedNodes: Array<string>,
    selectedServiceTypes: Array<{id: number, name: string}>,
    checkServiceTypeCallback: (serviceType: {id: number, name: string}, checked: boolean) => void
  }) => {
  const [childVisible, setChildVisibility] = useState(selectedNodes.includes(node.name))
  const [checked, setChecked] = useState(false)
  const hasChild = !!node.children.length

  useEffect(() => {
    setChecked(checkedValue)
  }, [checkedValue])

  const onChange = () => {
    checkServiceTypeCallback({ id: node.id, name: node.name }, !checked)
    setChecked(!checked)
  }

  /**
   * Expand the node that has a nested selection
   */
  useEffect(() => {
    if (selectedNodes.includes(node.name)) {
      if (!collapseAll) {
        setChildVisibility(true)
      }
    }
  }, [selectedNodes.length > 0])

  useEffect(() => {
    collapseAll && setChildVisibility(false)
  }, [collapseAll])

  return (
    <SPUDServiceTypeNodeContainer>
      <SPUDServiceTypeNode onClick={() => {
        setChildVisibility(!childVisible)
        // resets the collapse all flag when a node is opened
        collapseAll && collapseAllCallback()
      }}
      >
        <SPUDServiceTypeNodeContent>
          <Row>
            <Col direction='row'>
              <div style={{ marginRight: '1em' }}>
                {!hasChild
                  ? <Checkbox name={node.name} label={node.name} checked={checked} onChange={onChange}/>
                  : node.name}
              </div>
              {
                hasChild &&
                <FontAwesomeIcon icon={childVisible ? faChevronDown as IconProp : faChevronRight as IconProp} />
              }
            </Col>
          </Row>
        </SPUDServiceTypeNodeContent>
      </SPUDServiceTypeNode>

      {hasChild && childVisible && (
        <div style={{ borderLeft: '5px solid darkgrey' }}>
          <ServiceTypes
            serviceTypes={node.children}
            selectedNodes={selectedNodes}
            selectedServiceTypes={selectedServiceTypes}
            collapseAll={collapseAll}
            collapseAllCallback={collapseAllCallback}
            checkServiceTypeCallback={checkServiceTypeCallback}
          />
        </div>
      )}
    </SPUDServiceTypeNodeContainer>
  )
}

const CHIP_LIMIT = 15

function SPUDServiceTypeClassification (
  {
    title,
    value,
    callback,
    formKey,
    disabled,
    onFormSubmitted = false,
  }: SPUDServiceTypeClassificationProps) : React.ReactElement {
  const [serviceTypes, setServiceTypes] = useState<Array<ServiceTypeType>>([])
  const [selectedNodes, setSelectedNodes] = useState<Array<string>>([])
  const [selectedServiceTypes, setSelectedServiceTypes] = useState<Array<{id: number, name: string}>>([])
  const [tempSelectedServiceTypes, setTempSelectedServiceTypes] = useState<Array<{id: number, name: string}>>([])
  const [loading, setLoading] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [collapseAll, setCollapseAll] = useState(false)
  const [clearedAll, setClearedAll] = useState(false)
  const [showServiceTypes, setShowServiceTypes] = useState(false)

  const serviceTypeRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const getServiceTypes = async () => {
      setLoading(true)
      const response = await spudAPI.fetchServiceTypes()
      setLoading(false)
      setServiceTypes(response.results)
    }
    getServiceTypes()
  }, [])

  useEffect(() => {
    setClearedAll(false)
  }, [onFormSubmitted])

  function findNested (serviceTypeArr: Array<ServiceTypeType>, name: string): ServiceTypeType | undefined {
    const found = serviceTypeArr.find(node => node.name === name)
    return found || serviceTypeArr.find((c) => findNested(c.children, name))
  }

  useEffect(() => {
    if (value && value.length) {
      setSelectedServiceTypes(value)
      setTempSelectedServiceTypes(value)
    }
  }, [value])

  /**
   * Finds all the nodes that have a selection so that it can be expanded on load
   */
  useEffect(() => {
    const nodesWithSelection = []
    if (value) {
      for (const val of value) {
        // Find the root level node that has nested selections
        let foundNode = findNested(serviceTypes, val.name)
        foundNode && nodesWithSelection.push(foundNode.name)

        // fetch the deeper nodes that have a nested selection
        // so all the parent nodes will be open
        while (foundNode?.children.length) {
          foundNode = findNested(foundNode?.children, val.name)
          foundNode && nodesWithSelection.push(foundNode.name)
        }
      }
    }
    setSelectedNodes(nodesWithSelection)
  }, [value, serviceTypes])

  return (
    <div ref={serviceTypeRef}>
      <Row>
        <Col direction='row' justify='space-between' align='center'>
          <Title level={4}>{title}</Title>
          {(selectedServiceTypes.length > 0 || tempSelectedServiceTypes.length > 0) &&
            <ServiceTypeLinkButton onClick={() => {
              if (!clearedAll) {
                setTempSelectedServiceTypes(selectedServiceTypes)
                setSelectedServiceTypes([])
                setClearedAll(true)
                callback(formKey, [])
              } else {
                setSelectedServiceTypes(tempSelectedServiceTypes)
                setTempSelectedServiceTypes([])
                callback(formKey, tempSelectedServiceTypes)
                setClearedAll(false)
              }
            }}
            >
              { clearedAll ? 'Undo' : 'Clear all' }
            </ServiceTypeLinkButton>}
        </Col>
      </Row>
      <Row>
        <Col>
          <SPUDServiceTypeChipContainer highlight={!selectedServiceTypes.length} className='form-service-type'>
            {selectedServiceTypes.map((serviceType, index) => {
              if (showAll || index + 1 <= CHIP_LIMIT) {
                return (
                  <SPUDServiceTypeChip key={`${serviceType}_${index}`}>
                    <>
                      {serviceType?.name || serviceType}
                      <SPUDServiceTypeChipButton
                        onClick={() => {
                          const updatedSPUDServiceTypes = [...selectedServiceTypes]
                          updatedSPUDServiceTypes.splice(index, 1)
                          setSelectedServiceTypes(updatedSPUDServiceTypes)
                          setTempSelectedServiceTypes(updatedSPUDServiceTypes)
                          callback(formKey, updatedSPUDServiceTypes)
                        }}
                        disabled={disabled}
                      >
                        <FontAwesomeIcon icon={faTimes as IconProp}/>
                      </SPUDServiceTypeChipButton>
                    </>
                  </SPUDServiceTypeChip>
                )
              }
              return null
            })}
            {selectedServiceTypes.length >= CHIP_LIMIT && <div>
              <ServiceTypeLinkButton onClick={() => setShowAll(!showAll)}>
                {!showAll ? 'Show all' : 'Show less'}
              </ServiceTypeLinkButton>
            </div>}
          </SPUDServiceTypeChipContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            loading={loading}
            disabled={loading && !selectedServiceTypes.length}
            onClick={() => {
              setShowServiceTypes(!showServiceTypes)
              setCollapseAll(false)
            }}
            active={!!(selectedServiceTypes.length || !loading)}
          >
            {showServiceTypes ? 'Hide' : 'Show'} service types
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            {showServiceTypes && (
              <ServiceTypes
                serviceTypes={serviceTypes}
                selectedServiceTypes={selectedServiceTypes}
                selectedNodes={selectedNodes}
                collapseAll={collapseAll}
                collapseAllCallback={() => setCollapseAll(false)}
                checkServiceTypeCallback={((name, checked: boolean) => {
                  setClearedAll(false)
                  if (checked) {
                    const newSelectedServiceTypeArr = [...selectedServiceTypes, name]
                    setSelectedServiceTypes(newSelectedServiceTypeArr)
                    setTempSelectedServiceTypes(newSelectedServiceTypeArr)
                    callback(formKey, newSelectedServiceTypeArr)
                  } else {
                    const newSelectedServiceTypeArr = [...selectedServiceTypes]
                    newSelectedServiceTypeArr.splice(
                      selectedServiceTypes.findIndex(serviceTypeName => serviceTypeName === name),
                      1,
                    )
                    setSelectedServiceTypes(newSelectedServiceTypeArr)
                    setTempSelectedServiceTypes(newSelectedServiceTypeArr)
                    callback(formKey, newSelectedServiceTypeArr)
                  }
                })}
              />
            )}
          </div>
        </Col>
      </Row>
      {showServiceTypes && <Row>
        <Col>
          <ServiceTypeLinkButton onClick={() => {
            setCollapseAll(true)
            window.scrollTo(0, serviceTypeRef?.current?.offsetTop || 0)
          }}
          >
            Collapse all
          </ServiceTypeLinkButton>
        </Col>
      </Row>}
    </div>
  )
}

export default SPUDServiceTypeClassification
